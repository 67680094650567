import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, FlatList, Modal, ScrollView, ActivityIndicator } from 'react-native';
import { Video } from 'expo-av';

//Text :
import CustomText from '../src/components/Font/CustomText';
import Config from '../src/Api_Url';

// Manage :
import { getUser } from '../src/UserManagement';
import { getMediaPostsByUserToken, getMediaPostsByUserId } from '../src/PostManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function ProfileScreen({ navigation, route }) {
  const [user, setUser] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [modalVisiblepp, setModalVisiblepp] = useState(false);

  //Nombre Post, Suivi(e) , Followers : 
  const [numPosts, setNumPosts] = useState('');
  //Profile : 
  const profileImage = 'ex-profil.png';
  const [profile, setProfilUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setModalVisiblepp(true);
  };


  useEffect(() => {
    fetchData();
  }, []);

  const filterMediaByUniquePostId = (mediaData) => {
    const uniqueMediaDict = {};
    mediaData.forEach(item => {
      const postId = item.post_id;
      if (!uniqueMediaDict[postId]) {
        uniqueMediaDict[postId] = item;
      }
    });
    return Object.values(uniqueMediaDict);
  };

  const fetchData = () => {
    setLoading(true);
    getUser()
      .then((data) => {
        setLoading(false);
        setUser(data.user);
        setProfilUser(data.user.profile_image);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });

    getMediaPostsByUserToken()
      .then((data) => {
        //console.log(data)
        const filteredMedia = filterMediaByUniquePostId(data);
        setMediaData(filteredMedia);
        setNumPosts(filteredMedia.length);
      })
      .catch(error => {
        console.log("Error fetching media:", error);
      });
  };

  useEffect(() => {
    fetchData(getMediaPostsByUserToken());
    fetchData();
  }, []);

  function refresh() {
    fetchData();
  }

  function handleModify() {
    navigation.navigate('Parameters');
  }

  function handleFollowers(token) {
    navigation.navigate('Followers', { token });
  }

  function handleFollows(token) {
    navigation.navigate('Follows', { token });
  }

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#9002C1" />
        <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
      </View>
    );
  }

  // console.log(mediaData)

  return (
    <ScrollView contentContainerStyle={{
      flex: 1,
      backgroundColor: '#FEFEFE',
      alignItems: 'center',
    }}>
      <View style={styles.container}>
        <View style={styles.containerDescriptionOne}>
          <View>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <Modal visible={modalVisiblepp} transparent={true} animationType="fade"
                  onRequestClose={() => { setModalVisiblepp(false); }}>
                  <View style={styles.modalBackground}>
                    <View style={styles.modalContainerr}>
                      <TouchableOpacity onPress={() => setModalVisiblepp(false)}>
                        <Image style={{ width: 300, height: 300, borderRadius: 111 }}
                          source={profile === profileImage ? require('../assets/' + profileImage) : { uri: profile }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ padding: 1.8, width: 80, }}>
                    <TouchableOpacity onPress={openModal}>
                      <View style={{ backgroundColor: '#9002C1', padding: 2, borderRadius: 30, width: 61, height: 60 }}>
                        <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                          {profile === profileImage ? (
                            <Image style={{ width: 60, height: 56 }} source={require('../assets/' + profileImage)} />
                          ) : (
                            <Image style={{ width: 60, height: 56 }} source={{ uri: profile }} />)
                          }
                        </View>
                      </View>
                    </TouchableOpacity>
                    <View style={styles.Letters}>
                      <CustomText style={{ fontWeight: 'bold', color: '#9002C1' }}>{user.morphology_calculated}</CustomText>
                    </View>

                    <View style={{ marginLeft: 0, marginTop: 3 }}>
                      <CustomText style={{ fontWeight: 600 }}>{user.pseudo}</CustomText>
                    </View>
                  </View>
                  <View style={styles.infoProfilContainer}>
                    <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                      <View>
                        <CustomText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>{numPosts}</CustomText>
                      </View>
                      <TouchableOpacity onPress={refresh}>
                        <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('2%') }}>Posts</CustomText>
                      </TouchableOpacity>
                    </View>

                    <TouchableOpacity onPress={() => handleFollowers(user.token)} style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                      <CustomText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>{user.nbr_followers}</CustomText>
                      <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('2%') }}>Followers</CustomText>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => handleFollows(user.token)} style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                      <CustomText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>{user.nbr_follows}</CustomText>
                      <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('2%') }}>Suivi(e)s</CustomText>
                    </TouchableOpacity>
                  </View>
                </View>
                <View>
                  {/* <View style={styles.Letters}>
                    <CustomText style={{ fontWeight: 'bold', color: '#9002C1' }}>{user.morphology_calculated}</CustomText>
                  </View>
                  <View style={{ marginLeft: 0, marginTop: 3 }}>
                    <CustomText style={{ fontWeight: 600, width: 94 }}>{user.pseudo}</CustomText>
                  </View> */}
                  <View style={{ flexDirection: 'row', marginTop: heightPercentageToDP('10%') }}>
                    <View>
                      <TouchableOpacity onPress={handleModify} style={styles.button}>
                        <CustomText style={styles.buttonText}>Modifier profil</CustomText>
                      </TouchableOpacity>
                    </View>
                    <View>
                      <TouchableOpacity style={styles.buttonpro} onPress={() => setIsModalInfo(true)}>
                        <Image style={{ width: 40, height: 40 }} source={require('../assets/icon-buttonpro.svg')} />
                      </TouchableOpacity>
                    </View>
                  </View>

                </View>
              </View>
            </View>

            <Modal visible={isModalInfo} animationType="fade" style={{}} transparent={true} onRequestClose={() => setIsModalInfo(false)}>
              <View style={styles.MenuProfilPopup} >
                <TouchableOpacity style={{}} onPress={() => setIsModalInfo(false)}>
                  <Modal visible={isModalInfo} animationType="slide" transparent={true} onRequestClose={() => setIsModalInfo(false)}>
                    <View style={styles.MenuProfilPopup2}>
                      <View style={styles.modalContainer}>
                        <View style={{ marginBottom: 15 }}>
                          <TouchableOpacity style={{ flexDirection: 'row', marginLeft: 10 }} onPress={() => navigation.navigate('Favorites')}>
                            <View>
                              <Image style={{ width: 20, height: 20 }} source={require('../assets/icon-star-white.svg')} />
                            </View>
                            <View>
                              <CustomText style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}> Suit'Crush</CustomText>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginBottom: 15 }}>
                          <TouchableOpacity style={{ flexDirection: 'row', marginLeft: 10, fontSize: heightPercentageToDP('3%') }} onPress={() => navigation.navigate('FAQ')}>
                            <View>
                              <Image style={{ width: 20, height: 20 }} source={require('../assets/icon-faq.png')} />
                            </View>
                            <View>
                              <CustomText style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold', fontSize: heightPercentageToDP('3%'), }}>FAQ</CustomText>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginBottom: 15 }}>
                          <TouchableOpacity style={{ flexDirection: 'row', marginLeft: 10 }} onPress={() => navigation.navigate('CG')}>
                            <View>
                              <Image style={{ width: 20, height: 20 }} source={require('../assets/i-letter.svg')} />
                            </View>
                            <View>
                              <CustomText style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold', fontSize: heightPercentageToDP('3%'), }}>Conditions d'utilisation</CustomText>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginBottom: 15 }}>
                          <TouchableOpacity style={{ flexDirection: 'row', marginLeft: 10, }} onPress={() => { localStorage.removeItem('sessionToken'); window.location.reload() }}>
                            <View>
                              <CustomText style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold', fontSize: heightPercentageToDP('3%'), }}>Déconnexion</CustomText>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </Modal>
                </TouchableOpacity>
              </View>
            </Modal>

          </View>
        </View>

        <View style={{ width: widthPercentageToDP('90%') }}>
          <CustomText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold' }}>Bio</CustomText>
          <CustomText style={{ fontSize: heightPercentageToDP('3%'), marginBottom: '0.6pc', lineHeight: heightPercentageToDP('5%'), marginTop: heightPercentageToDP('2.5%'), textAlign: 'justify' }}>{user.biography}</CustomText>
          {/* <CustomText style={{fontSize: 15, fontWeight:600,marginBottom:5,marginTop:2}}>Posts</CustomText> */}
        </View>

        <View style={{ borderWidth: 1, borderLeftWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, borderTopColor: '#9002C1', marginBottom: 5 }}>
          <View style={{ width: widthPercentageToDP('90%') }}>
            <View style={{ flexGrow: 1, alignItems: 'flex-start', }}>
              <FlatList
                data={mediaData.sort((a, b) => b.post_id - a.post_id)}
                numColumns={3}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => (
                  <TouchableOpacity onPress={() => {
                    navigation.navigate('Post', { postId: item.post_id });
                  }}>
                    <View style={{ margin: 1, width: widthPercentageToDP('30%') }}>
                      {
                        item?.media_format?.includes("image") ?
                          <Image style={styles.crushImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                          : item?.media_format?.includes("video") ?
                            <Video
                            shouldPlay
                              source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                              style={styles.crushImage}
                              useNativeControls
                              resizeMode="cover"
                              /* onReadyForDisplay={videoData => {
                                videoData.srcElement.style.position = "initial"
                              }} */
                            />
                            : <Image style={styles.crushImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                      }
                    </View>
                  </TouchableOpacity>
                )}
              />
            </View>
          </View>
        </View>

        <StatusBar style="auto" />
      </View>
    </ScrollView >
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
  },
  container: {
    padding: 10,
    alignItems: 'center',
    width: widthPercentageToDP('90%')
  },
  Letters: {
    width: 18,
    height: 18,
    borderRadius: 15,
    marginTop: '-0.7pc',
    marginLeft: '2.4pc',
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainerr: {
    backgroundColor: 'rgba(179, 210, 204, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    width: 305,
    borderRadius: 10,
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width: 168,
    height: 40,
    marginLeft: '5pc',
    marginRight: '0.4pc',
    marginTop: '-3.6pc',
  },
  buttonpro: {
    padding: 10,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width: 40,
    height: 40,
    marginTop: '-4.21pc',
  },
  button2: {
    backgroundColor: '#9002C1',
    padding: 5,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width: '95px',
    height: '40px',
    marginLeft: '10px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText: {
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'center',
    color: '#ffff',
  },
  MenuProfilPopup: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  MenuProfilPopup2: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  modalContainer: {
    paddingTop: 20,
    backgroundColor: '#9002C1',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // borderRadius:10,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  containerDescriptionOne: {
    flexDirection: 'row',
    marginTop: 10,
  },
  infoProfilContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: heightPercentageToDP('3%'),
  },
  crushImage: {
    width: 113,
    height: 215,
    resizeMode: 'cover',
    aspectRatio: 0.51,
    borderRadius: '0.4pc',
  }
});
