import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Image, View, TouchableOpacity } from 'react-native';
import { getUser } from '../src/UserManagement';

import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function TrendsScreen({ navigation }) {

  const [user, setUser] = useState([]);

  // 1 :
  const [firstPopupVisible, setfirstPopupVisible] = useState(false);

  const next = () => {
    navigation.navigate('TutoScreen');
  }
  const fetchData = () => {
    getUser()
      .then((data) => {
        setUser(data.user);
        if (data.user.first_connection === 1) {
          setfirstPopupVisible(true);
        } else {
          //console.log('Pas 1ere connexion');
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (

    <View style={styles.container}>
      <View style={styles.succes}>
        <View style={{ marginTop: -16, marginBottom: 10 }}>
          <Image style={{ height: 350, width: 300 }} source={require('../assets/WelcomeSuituser.svg')} />
        </View>

        <View style={{ alignItems: 'center' }}>
          {/*           <CustomText style={{ fontSize: heightPercentageToDP('3%'), marginBottom: 5, color: '#9002C1', fontWeight: 600 }}>WELCOME</CustomText>
 */}          {user.age !== null ? (
            <CustomText style={styles.customTextStyle}>
              Félicitations !<br />
              Tu as rempli toutes les étapes.<br />
              Bienvenue dans la communauté<br />
              de Suituser modèle!
            </CustomText>
          ) : (
            <CustomText style={styles.customTextStyle}>
              Félicitations !<br />
              Tu as rempli toutes les étapes.<br />
              Bienvenue dans la communauté<br />
              de Suituser !
            </CustomText>
          )}
        </View>
      </View>
      <TouchableOpacity onPress={next} style={[styles.button, styles.buttonWithShadow]}>
        <CustomText style={styles.buttonText}>MERCI !</CustomText>
      </TouchableOpacity>

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  succes: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    padding: 10,
    borderColor: '#E4C0F0',
    borderRadius: 25
  },
  customTextStyle: {
    color: '#9002C1',
    marginBottom: 5,
    textAlign: 'center',
    fontSize: heightPercentageToDP('3%'),
    lineHeight: heightPercentageToDP('5%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 4,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  buttonWithShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // Élévation pour Android
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    padding: 5,
    color: '#FEFEFE'
  },

});