import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';

// Retour : 
import Head from '../src/components/Head';

import BoxFAQ from '../src/components/BoxFAQ';
import { getListFAQ } from '../src/FAQManagement';

// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function FAQScreen({ navigation }) {
  const [FAQ, setFAQ] = useState('');

  const fetchData = () => {
    getListFAQ()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setFAQ(data.faq);
        //console.log(data.faq)
      })
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <View style={styles.container}>

      <Head titre="Suitus FAQ" navigation={navigation} />

      {/* <Header moveLogin={() => navigation.navigate('Login')} 
        moveHome={() => navigation.navigate('Home')} 
        moveRecommendations={() => navigation.navigate('Recommendations')} 
        moveExplorer={() => navigation.navigate('Explorer')} 
        moveFavorites={() => navigation.navigate('Favorites')} 
        moveProfile={() => navigation.navigate('Profile')} 
        moveParameters={() => navigation.navigate('Parameters')}
        moveFAQ={() => navigation.navigate('FAQ')} /> */}

      <CustomText style={{ color: '#9002C1', marginBottom: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('3%'), fontSize: heightPercentageToDP('3%') }}>Questions courantes:</CustomText>
      <View style={{ marginBottom: heightPercentageToDP('10%')}}>
        <FlatList
          data={FAQ}
          renderItem={({ item }) =>
            <BoxFAQ question={item.question}
              answer={item.answer} />}
        />
      </View>

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
});