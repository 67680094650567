import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import { getListBraLetters } from '../src/dropdowns/BraLettersManagement';
import { getListNumbers } from '../src/dropdowns/NumbersManagement';
import { getUser } from '../src/UserManagement';
import { setYourInformationOne } from '../src/MorphologyManagement';
import Header from '../src/components/Header';
import Modal from "react-native-modal";
import DropDown from '../src/components/Dropdown'

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function YourInformationOneScreen({ route, navigation }) {
  // const { previousPage } = route.params;

  const [DDLWeightValue, setDDLWeightValue] = useState(50);
  const [DDLWeightItems, setDDLWeightItems] = useState([]);

  const [DDLHeightValue, setDDLHeightValue] = useState(150);
  const [DDLHeightItems, setDDLHeightItems] = useState([]);

  const [DDLChestValue, setDDLChestValue] = useState(80);
  const [DDLChestItems, setDDLChestItems] = useState([]);

  const [DDLBraNValue, setDDLBraNValue] = useState(90);
  const [DDLBraNItems, setDDLBraNItems] = useState([]);

  const [DDLBraLValue, setDDLBraLValue] = useState('D');
  const [DDLBraLItems, setDDLBraLItems] = useState([]);

  const [DDLAgeValue, setDDLAgeValue] = useState(30);
  const [DDLAgeItems, setDDLAgeItems] = useState([]);

  const fetchData = () => {
    getUser()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        //setUser(data.user);
      })

    getListBraLetters()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setDDLBraLItems(data.braletters);
      })

    getListNumbers()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setDDLWeightItems(data.weights);
        setDDLHeightItems(data.heights);
        setDDLChestItems(data.chests);
        setDDLBraNItems(data.branumbers);
        setDDLAgeItems(data.ages);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onSelectWeight = (item) => {
    setDDLWeightValue(item);
  }

  const onSelectHeight = (item) => {
    setDDLHeightValue(item);
  }

  const onSelectChest = (item) => {
    setDDLChestValue(item);
  }

  const onSelectBraN = (item) => {
    setDDLBraNValue(item);
  }

  const onSelectBraL = (item) => {
    setDDLBraLValue(item);
  }

  const onSelectAge = (item) => {
    setDDLAgeValue(item);
  }

  function handlePrevious() {
    navigation.goBack();
  }

  function handleNext() {
    const infoUser = { weight: DDLWeightValue, height: DDLHeightValue, chest: DDLChestValue, braN: DDLBraNValue, braL: DDLBraLValue, age: DDLAgeValue }
    setYourInformationOne(infoUser)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('YourInformationTwo')
        }
      })
  }

  //popup
  const [isModalwarning, setIsModalwarning] = useState(false);
  const handleModalwarning= () => {
    setIsModalwarning(() => !isModalwarning)
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <View style={styles.containerNumber}>
          <View style={{ marginRight: 60, marginLeft: 60 }}>
            <BoldText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>1</BoldText>
          </View>
          <View style={{ marginRight: 60, color: '#E4C0F0' }}>
            <BoldText style={{ color: '#E4C0F0', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>2</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#E4C0F0', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>3</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#E4C0F0', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>4</BoldText>
          </View>
        </View>
      </View>
      <View style={{ marginTop: heightPercentageToDP('5%'), alignItems: 'center' }}>
        <DropDown style={{ width: widthPercentageToDP('90%') }}
          data={DDLWeightItems}
          value={DDLWeightValue}
          onSelect={onSelectWeight}
          question="Ton poids (en kg) :" />

        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLHeightItems}
          value={DDLHeightValue}
          onSelect={onSelectHeight}
          question="Ta taille (en cm) :" />
        {/* Poitrine  */}
        <View style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <TouchableOpacity onPress={handleModalwarning}>
            <Text style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 500, color: '#FEFEFE' }} >{"?"}</Text>
          </TouchableOpacity>
        </View>

        <TouchableOpacity activeOpacity={0.5} onPress={handleModalwarning} style={{ alignItems: 'center' }}>
        <View style={{ marginTop: 170, marginLeft: 230, zIndex: 3, position: 'absolute' }}>
          <Modal isVisible={isModalwarning}
            onRequestClose={() => { setIsModalwarning(false); }}>
            <View style={styles.Modalwarning}>
              <Image style={styles.image} source={require('../assets/Tour-poitrine.png')} />
             
                <BoldText style={{
                  fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
                  textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
                }}>
                  Pour mesurer le tour de poirtine, prends ton mètre ruban horizontalement, du dos jusqu’à la pointe des seins.
                </BoldText>
             
            </View>
          </Modal>
        </View>
        </TouchableOpacity>
        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLChestItems}
          value={DDLChestValue}
          onSelect={onSelectChest}
          question="Ton tour de poitrine (en cm) :" />

        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLBraNItems}
          value={DDLBraNValue}
          onSelect={onSelectBraN}
          question="Ta taille de soutien gorge (en nombre) :" />

        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLBraLItems}
          value={DDLBraLValue}
          onSelect={onSelectBraL}
          question="Ta  taille de bonnet de soutien-gorge (en lettre) :" />

        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLAgeItems}
          value={DDLAgeValue}
          onSelect={onSelectAge}
          question="Ton âge :" />
        <View style={styles.containerButtons}>
          <View >
            <TouchableOpacity onPress={handlePrevious} style={[styles.button1, styles.buttonWithShadow]}>
              <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity onPress={handleNext} style={[styles.button, styles.buttonWithShadow]}>
              <CustomText style={styles.buttonText}>{"SUIVANT"}</CustomText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <StatusBar style="auto" />
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerNumber: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: heightPercentageToDP('2%'),
    paddingBottom: heightPercentageToDP('2%'),
    width: widthPercentageToDP('90%'),
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: 'white',
    borderColor: '#E4C0F0',
    borderRadius: 20,
  },
  containerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginBottom: heightPercentageToDP('10%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  button1: {
    backgroundColor: '#C7C7C6',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  buttonWithShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // Élévation pour Android
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    padding: 5,
    color: '#ffff'
  },
  dropDownStyle: {
    paddging: 8,
    borderRadius: 6,
    minHeight: 42,
    borderColor: '#9002C1',
    justifyContent: 'center',
  },
  Modalwarning: {
    backgroundColor: '#F8F6F6',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#9002C1',
    justifyContent: 'center',
    width: widthPercentageToDP('90%'),
    padding: 20
  },
  image: {
    width: 137,
    height: 287,
    marginLeft: 66,

  }
});