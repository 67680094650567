import Config from "./Api_Url";

export async function addFollow(idFlw, tokenFlr) {
    const users = { idFlw: idFlw, tokenFlr: tokenFlr};
    const response = await fetch(`${Config.serverUrl}/api/follow/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({users: users})
    });
    if (!response.ok) {
      alert("L'envoi du follow a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("AddFollow Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function deleteFollow(idFlw, tokenFlr) {
  const users = { idFlw: idFlw, tokenFlr: tokenFlr};
  const response = await fetch(`${Config.serverUrl}/api/follow/delete`, {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({users: users})
  });
  if (!response.ok) {
    alert("L'envoi du unfollow a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Unfollow Request Failed");
  }
  const data = await response.json();
  return data;
}

export async function deleteFollower(idFlr, tokenFlr) {
  const users = { idFlr: idFlr, tokenFlr: tokenFlr};
  const response = await fetch(`${Config.serverUrl}/api/follow/deletefollowers`, {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({users: users})
  });
  if (!response.ok) {
    alert("L'envoi du unfollow a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Unfollow Request Failed");
  }
  const data = await response.json();
  return data;
}

// demande au serveur si je suis l'id idflw : 
export async function isFollow(idFlw, tokenFlr) {
    const users = { idFlw: idFlw, tokenFlr: tokenFlr};
    const response = await fetch(`${Config.serverUrl}/api/follow/isfollow`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({users: users})
    });
    if (!response.ok) {
      alert("L'envoi du isFollow a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Isfollow Request Failed");
    }
    const data = await response.json();

    return data;
}

// PAGE FOLLOWERS
export async function followersList(token) {
    const response = await fetch(`${Config.serverUrl}/api/follower/user/list/${token}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      alert("La récupération de la liste des followers a échoué : " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Followers Request Failed");
    }
    const data = await response.json();
    return data;
  }

  //page FOLLOW
  export async function followsList(token) {
    const response = await fetch(`${Config.serverUrl}/api/follow/user/list/${token}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      alert("La récupération de la liste des follows a échoué : " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Followers Request Failed");
    }
    const data = await response.json();
    console.log(data, 'hehehe');
    return data;
  }
  
  export async function Ifollow(tokenFlr) {
    const response = await fetch(`${Config.serverUrl}/api/follow/user/list/${tokenFlr}`, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      alert("La récupération de la liste des follows a échoué : " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Followers Request Failed");
    }
    const data = await response.json();
    return data;
  }
