import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';

export default function BottomBar() {
  return (
    <View style={styles.container}>
        <TouchableOpacity style={styles.btnPlusCircle} activeOpacity={0.5}>
            <Image style={styles.plusCircle} source={require('../../assets/icon-plus.png')}/>
        </TouchableOpacity>
        <View style={styles.containerBottom}>
          <View style={styles.colOne}>
            <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu}source={require('../../assets/icon-home-heart2.svg')}/>
            </TouchableOpacity>
          </View>
          <View style={styles.colTwo}>
            <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../../assets/icon-glass-white1.png')}/> 
            </TouchableOpacity>
          </View>
          <View style={styles.colThree}>
            <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../../assets/icon-suit-cops.png')} //icon-globe-white.png
                />
            </TouchableOpacity>
          </View>
          <View style={styles.colFour}>
            <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../../assets/ex-profil.png')}/>
            </TouchableOpacity>
          </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   flexDirection: 'column',
  //   backgroundColor: 'transparency',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: 150,
  // },
  // containerBottom: {
  //   flex: 1,
  //   backgroundColor: '#9002C1', // changer de blanc à violet 
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: 375, //il était en 350
  //   height: 50,
  //   maxHeight: 50,
  //   flexDirection: 'row',
  // },
  container: {
    flexDirection: 'column',
    backgroundColor: 'transparency',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%', // Utilisation de pourcentage pour la largeur
  },
  containerBottom: {
    backgroundColor: '#9002C1',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 375, // Largeur maximale de 375
    width: '100%', // Utilisation de pourcentage pour la largeur
    height: 50,
    maxHeight: 50,
    flexDirection: 'row',
  },
  plusCircle: {
    height: 40, // il était en 50
    width: 40, // il était en 50
    top: 45, // il était en 23
  },
  btnPlusCircle:  {
      zIndex: 1,
  },
  iconMenu: {
    width: 30,
    height: 30,
    zIndex: 1,
  },
  colOne:  {
    flex:  1,
    flexDirection: 'row',
    marginLeft: 30,
  },
  colTwo:  {
    flex:  2,
    flexDirection: 'row',
    marginLeft: 20,
  },
  colThree:  {
    flex:  2,
    flexDirection: 'row',
    left: 50,
  },
  colFour:  {
    flex:  2,
    flexDirection: 'row',
    left: 25,
  },
});