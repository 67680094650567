import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Modal } from 'react-native';
import { getListNumbers } from '../src/dropdowns/NumbersManagement';
import { getListDressSizes } from '../src/dropdowns/DressSizesManagement';
import { getUser } from '../src/UserManagement';
import { setYourInformationThree } from '../src/MorphologyManagement';
import DropDown from '../src/components/Dropdown'

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function YourInformationThreeScreen({ navigation }) {
  const [DDLCrotchValue, setDDLCrotchValue] = useState(75);
  const [DDLCrotchItems, setDDLCrotchItems] = useState([]);

  const [DDLThighValue, setDDLThighValue] = useState(40);
  const [DDLThighItems, setDDLThighItems] = useState([]);

  const [DDLDressValue, setDDLDressValue] = useState(38);
  const [DDLDressItems, setDDLDressItems] = useState([]);

  const fetchData = () => {
    getUser()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        //etUser(data.user);
      })

    getListNumbers()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setDDLCrotchItems(data.crotches);
        setDDLThighItems(data.thighs);
      })

    getListDressSizes()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setDDLDressItems(data.dresssizes);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  function handleModalOpen(text) {
    if (text === "thigh") {
      setModalContent(
        <BoldText style={{
          fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
          textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
        }}> Prendre le tour de cuisse à l’endroit le plus large des cuisses (2cm en dessous de l’aine)</BoldText>
      );
    } else if (text === "waist") {
      setModalContent(
        <BoldText style={{
          fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
          textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
        }}> Prendre la longueur du haut de l’intérieur de la cuisse jusqu’à la cheville en se tenant bien droite</BoldText>
      );
    }
    setIsModalVisible(true);
  }

  function handleModalClose() {
    setIsModalVisible(false);
  }


  function handlePrevious() {
    navigation.navigate('YourInformationTwo')
  }

  const onSelectCrotch = (item) => {
    setDDLCrotchValue(item);
  }

  const onSelectThigh = (item) => {
    setDDLThighValue(item);
  }

  const onSelectDress = (item) => {
    setDDLDressValue(item);
  }

  function handleNext() {
    const infoUser = { crotch: DDLCrotchValue, thigh: DDLThighValue, dress: DDLDressValue }
    setYourInformationThree(infoUser)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('YourInformationFour')
        }
      })
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <View style={styles.containerNumber}>
          <View style={{ marginRight: 60, marginLeft: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>1</BoldText>
          </View>
          <View style={{ marginRight: 60, color: '#E4C0F0' }}>
            <BoldText style={{ color: '#E4C0F0' }}>2</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#9002C1' }}>3</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>4</BoldText>
          </View>
        </View>
      </View>
      {/* <CustomText style={{marginTop: '20px', marginBottom: '20px',textTransform:'uppercase'}}>Renseigne tes informations</CustomText> */}

      <View style={{ marginTop: heightPercentageToDP('5%') }}>
        {/* <View style={{marginTop:170 ,marginLeft:230 ,zIndex:3 , position:'absolute' }}> */}
        {isModalVisible && (
          <Modal isVisible={isModalVisible} animationType="fade" transparent={true}
            onRequestClose={() => { setIsModalVisible(false); }}>
            <TouchableOpacity onPress={handleModalClose} activeOpacity={0.5} style={{ alignItems: 'center' }}>
              <View style={styles.Modalwarning}>
                <View style={styles.modalContainer}>
                  <BoldText style={{ fontSize: 10, fontWeight: 400, color: '#9002C1', textAlign: 'center', marginTop: 10, }}>
                    {modalContent}
                  </BoldText>
                </View>
              </View>
            </TouchableOpacity>
          </Modal>
        )}
        {/* </View> */}
        <TouchableOpacity onPress={() => handleModalOpen("waist")} activeOpacity={0.5} style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, HeightfontWeight: 450, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"?"}</CustomText>
        </TouchableOpacity>
        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLCrotchItems}
          value={DDLCrotchValue}
          onSelect={onSelectCrotch}
          question="Ton entrejambe (en cm) :"
        />
        <TouchableOpacity onPress={() => handleModalOpen("thigh")} activeOpacity={0.5} style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, HeightfontWeight: 450, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"?"}</CustomText>
        </TouchableOpacity>

        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLThighItems}
          value={DDLThighValue}
          onSelect={onSelectThigh}
          question="Ton tour de cuisse (en cm) :"
        />

        <DropDown
          style={{ width: widthPercentageToDP('90%') }}
          data={DDLDressItems}
          value={DDLDressValue}
          onSelect={onSelectDress}
          question="Ta taille de robe habituelle :"
        />
      </View>
      <View style={styles.containerButtons}>
        <View >
          <TouchableOpacity onPress={handlePrevious} style={[styles.button1, styles.buttonWithShadow]}>
            <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
          </TouchableOpacity>
        </View>
        <View>
          <TouchableOpacity onPress={handleNext} style={[styles.button, styles.buttonWithShadow]}>
            <CustomText style={styles.buttonText}>{"SUIVANT"}</CustomText>
          </TouchableOpacity>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerNumber: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: heightPercentageToDP('2%'),
    paddingBottom: heightPercentageToDP('2%'),
    width: widthPercentageToDP('90%'),
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: 'white',
    borderColor: '#E4C0F0',
    borderRadius: 20,
  },
  containerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginBottom: heightPercentageToDP('10%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  button1: {
    backgroundColor: '#C7C7C6',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  buttonWithShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // Élévation pour Android
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),

    textAlign: 'center',
    padding: 5,
    color: '#ffff'
  },
  dropDownStyle: {
    paddging: 8,
    borderRadius: 6,
    minHeight: 42,
    borderColor: '#9002C1',
    justifyContent: 'center',
  },
  Modalwarning: {
    backgroundColor: '#F8F6F6',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#9002C1',
    justifyContent: 'center',
    width: widthPercentageToDP('90%'),
    padding: 20
  },
  image: {
    width: 137,
    height: 287,
    marginLeft: 66,

  }
});