import Config from "./Api_Url";

export async function getuserId() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/message/listUserId/`+token, {
      method: "GET",
      headers: { 'Accept': 'application/json',
                 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    console.log(response);
    // alert("La récupération des messages de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Message Failed");
  }
  const data = await response.json();
  return data;
}

export async function create(newMessage) {
  let token = localStorage.getItem("sessionToken");
  const response = await fetch(`${Config.serverUrl}/api/message/create`, {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ message: newMessage, token: token }) // Utilisez newMessage
  });
  if (!response.ok) {
      console.log(response);
      // alert("La création du message a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Message creation Request Failed");
  }
  const data = await response.json();
  // console.log(data);
  return data;
}

export async function remove(messageId) {
  const response = await fetch(`${Config.serverUrl}/api/message/delete`, {
      method: "POST",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: messageId }) // Utilisez newMessage
  });

  if (!response.ok) {
      console.log(response);
      // alert("La suppression du commentaire a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Comment deletion Request Failed");
  }

  const data = await response.json();
  return data;
}