import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Button, Image, TouchableOpacity } from 'react-native';
import { getListNumbers } from '../src/dropdowns/NumbersManagement';
import { getUser } from '../src/UserManagement';
import { setYourInformationTwo } from '../src/MorphologyManagement';
import DropDown from '../src/components/Dropdown'
import Modal from "react-native-modal";

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function YourInformationTwoScreen({ navigation }) {
  const [DDLShoulderValue, setDDLShoulderValue] = useState(90);
  const [DDLShoulderItems, setDDLShoulderItems] = useState([]);

  const [DDLWaistValue, setDDLWaistValue] = useState(90);
  const [DDLWaistItems, setDDLWaistItems] = useState([]);

  const [DDLHighHipValue, setDDLHighHipValue] = useState(90);
  const [DDLHighHipItems, setDDLHighHipItems] = useState([]);

  const [DDLHipValue, setDDLHipValue] = useState(90);
  const [DDLHipItems, setDDLHipItems] = useState([]);

  const fetchData = () => {
    getListNumbers()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setDDLShoulderItems(data.shoulders);
        setDDLWaistItems(data.waists);
        setDDLHighHipItems(data.highhips);
        setDDLHipItems(data.hips);
      })

    getUser()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        //setUser(data.user);
      })
  }

  //popup : 
  const [modalText, setModalText] = useState("");
  const [isModalwarning, setIsModalwarning] = useState(false);
  function handleModalwarning(text) {
    setModalText(text);
    setIsModalwarning(!isModalwarning);
  }


  const onSelectShoulder = (item) => {
    setDDLShoulderValue(item);
  }

  const onSelectWaist = (item) => {
    setDDLWaistValue(item);
  }

  const onSelectHighHip = (item) => {
    setDDLHighHipValue(item);
  }

  const onSelectHip = (item) => {
    setDDLHipValue(item);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handlePrevious() {
    navigation.navigate('YourInformationOne', {
      previousPage: '',
    })
  }

  function handleNext() {
    const infoUser = { shoulder: DDLShoulderValue, waist: DDLWaistValue, high_hip: DDLHighHipValue, hip: DDLHipValue }
    setYourInformationTwo(infoUser)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('YourInformationThree')
        }
      })
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <View style={styles.containerNumber}>
          <View style={{ marginRight: 60, marginLeft: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>1</BoldText>
          </View>
          <View style={{ marginRight: 60, color: '#E4C0F0' }}>
            <BoldText style={{ color: '#9002C1' }}>2</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>3</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>4</BoldText>
          </View>
        </View>
      </View>

      {/* <CustomText style={{marginTop: '20px', marginBottom: '20px',textTransform:'uppercase'}}>Renseigner tes informations</CustomText> */}

      <View style={{ marginTop: heightPercentageToDP('5%') }}>

        <View style={{ marginTop: 170, marginLeft: 230, zIndex: 3, position: 'absolute' }}>
          <Modal isVisible={isModalwarning}
            onRequestClose={() => { setIsModalwarning(false); }}>
            <TouchableOpacity onPress={handleModalwarning} activeOpacity={0.5} style={{ alignItems: 'center' }}>
              <View style={styles.Modalwarning}>
                {modalText === "shoulder" && (
                  <Image style={styles.image} source={require('../assets/Tour-epaule.png')} />
                )}
                {modalText === "waist" && (
                  <Image style={styles.image} source={require('../assets/Tour-taille.png')} />
                )}
                {modalText === "highhip" && (
                  <Image style={styles.image} source={require('../assets/Tour-hanchehaute.png')} />
                )}
                {modalText === "hip" && (
                  <Image style={styles.image} source={require('../assets/Tour-hanche.png')} />
                )}

                {modalText === "shoulder" && (
                  <BoldText style={{
                    fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
                    textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
                  }}>
                    Pour mesurer le tour d’épaules, prends ton mètre ruban, reste bien droite et place-le autour des épaules au plus large.
                  </BoldText>)}
                {modalText === "waist" && (
                  <BoldText style={{
                    fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
                    textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
                  }}>
                    Pour mesurer le tour de taille, prends ton mètre ruban horizontalement à l’endroit le plus creux de ton ventre.
                  </BoldText>)}
                {modalText === "highhip" && (
                  <BoldText style={{
                    fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
                    textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
                  }}>
                    Pour mesurer le tour de hanches haut, prends ton mètre ruban horizontalement au niveau de l’os de la hanche.
                  </BoldText>)}
                {modalText === "hip" && (
                  <BoldText style={{
                    fontSize: heightPercentageToDP('2.5'), fontWeight: 400, color: '#9002C1', marginTop: 10,
                    textAlign: 'justify', lineHeight: heightPercentageToDP('5%'),
                  }}>
                    Pour mesurer le tour de hanches, prends ton mètre ruban à l’endroit le plus large de tes hanches en restant bien droite.
                  </BoldText>)}
              </View>
            </TouchableOpacity>
          </Modal>
        </View>

        <TouchableOpacity onPress={() => handleModalwarning("shoulder")} activeOpacity={0.5} style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, HeightfontWeight: 450, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"?"}</CustomText>
        </TouchableOpacity>

        <DropDown
          data={DDLShoulderItems}
          value={DDLShoulderValue}
          onSelect={onSelectShoulder}
          style={{ width: widthPercentageToDP('90%') }}
          question="Ton tour d'épaules (en cm):" />

        <TouchableOpacity onPress={() => handleModalwarning("waist")} activeOpacity={0.5} style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, HeightfontWeight: 450, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"?"}</CustomText>
        </TouchableOpacity>

        <DropDown
          data={DDLWaistItems}
          value={DDLWaistValue}
          onSelect={onSelectWaist}
          style={{ width: widthPercentageToDP('90%') }}
          question="Ton tour de taille (en cm):" />

        <TouchableOpacity onPress={() => handleModalwarning("highhip")} activeOpacity={0.5} style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, HeightfontWeight: 450, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"?"}</CustomText>
        </TouchableOpacity>

        <DropDown
          data={DDLHighHipItems}
          value={DDLHighHipValue}
          onSelect={onSelectHighHip}
          style={{ width: widthPercentageToDP('90%') }}
          question="Ton tour de hanches haut (en cm):" />

        <TouchableOpacity onPress={() => handleModalwarning("hip")} activeOpacity={0.5} style={{ width: 20, height: 20, marginLeft: widthPercentageToDP('85%'), marginBottom: -10, HeightfontWeight: 450, borderRadius: 40, backgroundColor: '#9002C1', justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"?"}</CustomText>
        </TouchableOpacity>
        <DropDown
          data={DDLHipItems}
          value={DDLHipValue}
          onSelect={onSelectHip}
          style={{ width: widthPercentageToDP('90%') }}
          question="Ton tour de hanches (en cm):" />

      </View>
      <View style={styles.containerButtons}>
        <View >
          <TouchableOpacity onPress={handlePrevious} style={[styles.button1, styles.buttonWithShadow]}>
            <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
          </TouchableOpacity>
        </View>
        <View>
          <TouchableOpacity onPress={handleNext} style={[styles.button, styles.buttonWithShadow]}>
            <CustomText style={styles.buttonText}>{"SUIVANT"}</CustomText>
          </TouchableOpacity>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerNumber: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: heightPercentageToDP('2%'),
    paddingBottom: heightPercentageToDP('2%'),
    width: widthPercentageToDP('90%'),
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: 'white',
    borderColor: '#E4C0F0',
    borderRadius: 20,
  },
  containerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginBottom: heightPercentageToDP('10%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  button1: {
    backgroundColor: '#C7C7C6',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  buttonWithShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // Élévation pour Android
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    padding: 5,
    color: '#ffff'
  },
  dropDownStyle: {
    paddging: 8,
    borderRadius: 6,
    minHeight: 42,
    borderColor: '#9002C1',
    justifyContent: 'center',
  },
  Modalwarning: {
    backgroundColor: '#F8F6F6',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#9002C1',
    justifyContent: 'center',
    width: widthPercentageToDP('90%'),
    padding: 20
  },
  image: {
    width: 137,
    height: 287,
    marginLeft: 66,

  },
  blocHelp: {
    backgroundColor: '#EDEDED',
    height: 90,
    width: 120,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginBottom: '20px',
  },
});