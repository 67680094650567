import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

// Font :
import CustomText from './Font/CustomText';
import BoldText from './Font/BoldText';


const BarDisplay = ({ value, label }) => {
  // Assurez-vous que la valeur est dans la plage de 0 à 5
  const clampedValue = Math.min(Math.max(value, 0), 5);

  // Couleurs claire et foncée
  const lightColor = '#E4C0F0'; // Couleur claire
  const darkColor = '#9002C1'; // Couleur foncée

  return (
    <View style={styles.barContainer}>
      <BoldText style={styles.label}>{label}</BoldText>
      <View style={styles.barBackground}>
        <View
          style={[
            styles.bar,
            { width: `${(clampedValue / 5) * 100}%`, backgroundColor: darkColor },
          ]}
        />
      </View>
    </View>
  );
};

export default function Information({
  initiaTexture,
  initialThickness,
  initialFluidity,
  initialComfort,
  initialColor,
}) {
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.pair}>
            {/* <Text>Doux</Text> */}
            <BarDisplay value={initiaTexture} label="Texture"/>
            {/* <Text>Rèche</Text> */}
          </View>
          <View style={styles.pair}>
            {/* <Text>Différent</Text> */}
            <BarDisplay value={initialThickness} label="Epaisseur  "/>
            {/* <Text>Fidèle</Text> */}
          </View>
          <View style={styles.pair}>
            {/* <Text>Fin</Text> */}
            <BarDisplay value={initialFluidity} label="Fluidité" />
            {/* <Text>Epais</Text> */}
          </View>
          <View style={styles.pair}>
            {/* <Text>+</Text> */}
            <BarDisplay value={initialComfort} label="Confort" />
            {/* <Text>++</Text> */}
          </View>
          <View style={styles.pair}>
            {/* <Text></Text> */}
            <BarDisplay value={initialColor} label="Couleur    " />
            {/* <Text>++</Text> */}
          </View>
        </View> 
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pair: {
    flexDirection: 'row', // Met les éléments côte à côte
    alignItems: 'center', // Aligne les éléments verticalement
    marginVertical: 10,
    // gap:'1pc',
  },
  barContainer: {
    flexDirection: 'column',
    //alignItems: 'center',
  },
  label: {
    color: 'purple',
    fontWeight: 'bold',
  },
  barBackground: {
    width: '80px',
    height: 11,
    backgroundColor: '#E4C0F0', // Couleur claire de fond
    borderRadius: 5,
    marginTop: 5,
  },
  bar: {
    height: 11,
    width: '100%', // Ajoutez cette ligne pour forcer la largeur à 100%
    borderRadius: 5,
  },
});
