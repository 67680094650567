import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { useCustomFonts, customFontStyles } from './fontConfig';

export default function ItalicText({ children, style }) {
  const { fontsLoaded } = useCustomFonts();

  if (!fontsLoaded) {
    return null;
  }

  const styles = StyleSheet.create({
    text: {
        
    },
  });

  return (
    <Text style={[customFontStyles.myFontItalic, styles.text, style]}>{children}</Text>
  );
}