import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Image, FlatList, Modal, ActivityIndicator, Text } from 'react-native';

// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

// Header Bottom :
import Head from '../src/components/Head';

// Data :
import { getListUsers, getUser } from '../src/UserManagement';
import { deleteFollow, followsList, isFollow } from '../src/FollowManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function FollowsScreen({ navigation, route }) {
  const { token } = route.params;
  const profileImage = 'ex-profil.png';
  const [search, setSearch] = useState('');
  const [follows, setFollows] = useState([]);
  const [othertoken, setOtherToken] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => { setModalVisible(!isModalVisible); };

  const fetchData = () => {
    setLoading(true);
    getUser()
      .then((data) => {
        setLoading(false);
        setOtherToken(data.user.token);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
    getListUsers()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
      })
    followsList(token)
      .then((data) => {
        setFollows(data.follows);
      }).catch(error => {
        console.log(error);
      }).then(data => {
      })
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <View style={styles.container}>
      <Head titre={"Suivi(e)s"} navigation={navigation} />
      <BoldText style={{ fontSize: heightPercentageToDP('3%'), color: '#9002C1', marginTop: heightPercentageToDP('3%') }}>Suivi(e)s</BoldText>

      {/* <View style={styles.contain}>
            <TextInput style={styles.textinput}
              placeholderTextColor="gray"
              placeholder="Recherche..."
              onChangeText={setSearch}
            />
          </View> */}

      {
        loading && <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#9002C1" />
          <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
        </View>
      }
      <View style={{ flex: 1, flexDirection: 'column', marginTop: '0.5pc' }}>
        <FlatList
          data={follows}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <View style={{ flexDirection: 'row', borderRadius: 25, borderColor: '#E4C0F0', borderWidth: 2, width: widthPercentageToDP('90%'), marginBottom: '0.5pc' }}>
              <TouchableOpacity onPress={() => {
                navigation.navigate('UserProfile', { iduser: item.idFlw });
              }}>
                <View style={{ borderRadius: 20 }}>
                  {item.profile_image === profileImage ? (
                    <Image style={styles.ppfollow} source={require('../assets/' + profileImage)} />
                  ) : (
                    <Image style={styles.ppfollow} source={{ uri: item.profile_image }} />)
                  }
                </View>
              </TouchableOpacity>
              <View style={{ flexDirection: 'column', alignItems: 'flex-start', marginRight: 18 }}>
                <View style={{ backgroundColor: '#E2CBEA', borderRadius: 50, width: 21, height: 19, marginTop: '2pc', marginLeft: '-0.8pc' }}>
                  <CustomText style={{ fontWeight: 'bold', color: '#9002C1', textAlign: 'center' }}>{item.morphology_calculated}</CustomText>
                </View>
              </View>

              <View style={{ flexDirection: 'row', marginLeft: 7, marginTop: 19 }}>
                <CustomText style={{ fontWeight: 'bold', width: 128, fontSize: heightPercentageToDP('3%') }}>{item.pseudo}</CustomText>
                {token === othertoken ?
                  (<TouchableOpacity style={{ marginLeft: 13 }} onPress={toggleModal}>
                    <BoldText style={{ color: '#9002C1', fontSize: heightPercentageToDP('3%') }}>Suivi(e)</BoldText>
                  </TouchableOpacity>)
                  :
                  (
                    <View style={{ marginLeft: 13 }}>
                      <BoldText style={{ color: '#9002C1', fontSize: heightPercentageToDP('3%') }}>Suit</BoldText>
                    </View>
                  )
                }
              </View>

              <Modal visible={isModalVisible} animationType="fade" transparent={true} onRequestClose={() => setModalVisible(false)}>
                <View style={styles.modalBackground}>
                  <View style={styles.modalContainer}>
                    <CustomText style={{ textAlign: 'center', fontSize: heightPercentageToDP('3%') }}>Es-tu sûr(e) de ne plus suivre
                      <BoldText style={{ color: '#9002C1', marginTop: heightPercentageToDP('3%') }}> {item.pseudo}</BoldText> ?
                    </CustomText>
                    <View style={{ borderRadius: 20, marginTop: heightPercentageToDP('2%') }}>
                      {item.profile_image === profileImage ? (
                        <Image style={styles.ppfollow} source={require('../assets/' + profileImage)} />
                      ) : (
                        <Image style={styles.ppfollow} source={{ uri: item.profile_image }} />)
                      }
                    </View>
                    <TouchableOpacity onPress={() => { deleteFollow(item.id, token).then(() => { fetchData(); toggleModal(); }); }}>
                      <BoldText style={{ color: '#9002C1', fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Ne plus suivre</BoldText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={toggleModal}>
                      <BoldText style={{ color: 'red', fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Annuler</BoldText>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>

            </View>
          )}
        />
      </View>

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contain: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  ppfollow: {
    height: 55,
    width: 55,
    borderRadius: 60,
  },
  textinput: {
    width: 300,
    height: 40,
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    marginBottom: '10px',
    marginTop: '10px',
    backgroundColor: '#ECF4F2'
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
    flexDirection: 'column',
    borderColor: '#9002C1',
    justifyContent: 'space-around',
    borderRadius: 10,
    width: widthPercentageToDP('90%'),
    padding: 20
  },
});