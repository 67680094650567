import Config from "../Api_Url";

export async function getListBraLetters() {
    const response = await fetch(Config.serverUrl+'/api/dropdowns/braletters/list', {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': `${Config.serverUrl}`,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
      alert("L'obtention de la liste des tailles(lettres) de soutien-gorge a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
      throw new Error("ListBraLetters request Failed");
    }
    const data = await response.json();
    return data;
}