import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Modal, ScrollView, TextInput } from 'react-native';
import CustomText from '../src/components/Font/CustomText';

import BottomBar from '../src/components/BottomBar';
import Head from '../src/components/Head';

//Manage :
import { create, remove } from '../src/CommentManagement';
import { getUser } from '../src/UserManagement';
import { getMediaPostsByUserToken, getListComments } from '../src/PostManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function CommentHome({ route, navigation }) {

  // navigation :
  const postId = route.params?.postId;
  const id = route.params?.id;

  // utilisateur :
  const [user, setUser] = useState([]);
  const profileImage = 'ex-profil.png';
  const [profile, setProfilUser] = useState(null);
  const [currentUserId, setCurrentUserId] = useState([]);

  //commentaire : 
  const [comment, setComment] = useState([]);
  const [commentaire, SetAddCommentaire] = useState([]);
  const [isCommentListEmpty, setIsCommentListEmpty] = useState(false);
  const [commentsAndReplies, setCommentsAndReplies] = useState([]);
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  // data :
  const fetchData = (postId) => {
    getMediaPostsByUserToken()
      .then((data) => {
        // Formatez la date
        const formattedPosts = data.map((post) => {
          const createdAt = post.created_date;
          const dateObj = new Date(createdAt);
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          post.created_date = dateObj.toLocaleDateString('fr-FR', options);
          return post;
        });

        const filteredData = formattedPosts.filter(item => item.post_id === postId);
        setFilteredMediaData(filteredData);
        setStatusIcon(Array(filteredData.length).fill(false));
        setSlideImageIndices(Array(filteredData.length).fill(0));
        //console.log('lol', filteredData);
      })
      .catch(error => {
        console.log("Error fetching media:", error);
      });

    getUser()
      .then((data) => {
        setUser(data.user);
        setCurrentUserId(data.user.id);
        setProfilUser(data.user.profile_image);
      })
      .catch(error => {
        console.log(error);
      });
    getListComments(postId)
      .then((data) => {
        const formattedComments = data.comments.map((comment) => {
          const createdAt = comment.created_date;
          const dateObj = new Date(createdAt);
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          comment.created_date = dateObj.toLocaleDateString('fr-FR', options);
          return comment;
        });

        // Stockez tous les commentaires dans le state "comment"
        setComment(formattedComments);

        // Vous pouvez également mettre à jour setIsCommentListEmpty ici
        setIsCommentListEmpty(formattedComments.length === 0);
      })
      .catch((error) => {
        console.log("Erreur lors de la récupération des commentaires:", error);
      });
  };
  useEffect(() => {
    fetchData(postId);
  }, []);

  useEffect(() => {
    const combinedCommentsAndReplies = combineCommentsAndReplies(comment);
    setCommentsAndReplies(combinedCommentsAndReplies);
  }, [comment]);


  // Fonction pour combiner les commentaires et les réponses
  function combineCommentsAndReplies(comments) {
    const commentsAndReplies = {};
    comments.forEach((commentItem) => {
      const parentId = commentItem.answer_id || commentItem.id;
      const existingComment = commentsAndReplies[parentId];
      if (!existingComment) {
        commentsAndReplies[parentId] = {
          ...commentItem,
          replies: [],
        };
      } else {
        // Si le commentaire parent existe, ajoutez la réponse au parent ou créez un tableau de réponses s'il n'existe pas
        if (!existingComment.replies) {
          existingComment.replies = [];
        }
        existingComment.replies.push(commentItem);
      }
    });
    // Convertir l'objet en un tableau pour le rendre compatible avec votre affichage
    const result = Object.values(commentsAndReplies);
    //console.log('Comments and Replies :', result);
    return result;
  }

  function handleDeleteComment(commentId) {
    try {
      // Supprimez le commentaire
      remove(commentId); // Supposons que cette fonction ne soit pas asynchrone
      // Après avoir supprimé le commentaire, mettez à jour la liste des commentaires
      const updatedComments = comment.filter(commentItem => commentItem.id !== commentId);
      setComment(updatedComments);
    } catch (error) {
      // Gérer les erreurs si la suppression côté serveur a échoué
      console.error("Erreur lors de la suppression du commentaire :", error);
    }
  }

  // ajouter un  commentaire
  function addComment() {
    const newComment = {
      body: commentaire,
      user_id: user.id,
      id_post: postId,
    };

    if (replyToCommentId !== null) {
      newComment.answer_id = replyToCommentId; // Ajoutez l'ID du commentaire auquel vous répondez
    }

    create(newComment, postId, replyToCommentId)
      .then((data) => {
        if (data.error) {
          console.error("Erreur lors de la création du commentaire :", data.message);
        } else {
          console.log("Commentaire ajouté avec succès !");
          SetAddCommentaire(''); // Réinitialisez le commentaire principal
          setReplyToCommentId(null); // Réinitialisez l'ID du commentaire auquel vous répondez
          fetchData(postId); // Rechargez les commentaires pour afficher le nouveau commentaire
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête d'ajout de commentaire :", error);
      });
  }

  function handleReplyToComment(commentId) {
    setReplyToCommentId(commentId);

    // find commentaire = à son ID
    const commentToReply = commentsAndReplies.find(comment => comment.id === commentId);
    // Màj le champ de texte pour le commentaire principal
    if (commentToReply) {
      SetAddCommentaire(`@${commentToReply.pseudo}`);
    }
  }

  return (
    <View style={styles.container}>

      <Head titre="Commentaires" navigation={navigation} />

      <View style={styles.commentPopupContainer}>

        <ScrollView style={styles.commentList}>

          {isCommentListEmpty && <CustomText style={styles.emptyCommentText}>Aucun commentaire pour le moment.</CustomText>}
          {commentsAndReplies.map((commentItem) => (
            <View key={commentItem.id} style={styles.commentItem}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                  <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                    {commentItem.profile_image === profileImage ? (
                      <Image style={{ width: 40, height: 40 }} source={require('../assets/' + profileImage)} />
                    ) : (
                      <Image style={{ width: 40, height: 40 }} source={{ uri: commentItem.profile_image }} />)
                    }
                  </View>
                </View>
                {commentItem.user_id === currentUserId && (
                  <TouchableOpacity onPress={() => handleDeleteComment(commentItem.id)}>
                    <CustomText style={{ color: '#C881E0' }}>Supprimer</CustomText>
                  </TouchableOpacity>
                )}
              </View>

              <CustomText style={styles.commentUsername}>{commentItem.pseudo}</CustomText>
              <CustomText style={styles.commentText}>{commentItem.body}</CustomText>
              <CustomText style={styles.commentDate}>{commentItem.created_date}</CustomText>

              <TouchableOpacity onPress={() => handleReplyToComment(commentItem.id)}>
                <CustomText>Répondre</CustomText>
              </TouchableOpacity>

              {commentItem.replies.map((reply) => (
                <View key={reply.id} style={styles.replyItem}>
                  <View style={{ width: 30, height: 30 }}>
                    <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                      {reply.profile_image === profileImage ? (
                        <Image style={{ width: 30, height: 30 }} source={require('../assets/' + profileImage)} />
                      ) : (
                        <Image style={{ width: 30, height: 30 }} source={{ uri: reply.profile_image }} />)
                      }
                    </View>
                  </View>
                  {reply.user_id === currentUserId && (
                    <TouchableOpacity onPress={() => handleDeleteComment(reply.id)}>
                      <CustomText style={{ color: '#C881E0' }}>Supprimer</CustomText>
                    </TouchableOpacity>
                  )}
                  <CustomText style={styles.replyUsername}>{reply.pseudo}</CustomText>
                  <CustomText style={styles.replyText}>{reply.body}</CustomText>
                  <CustomText style={styles.replyDate}>{reply.created_date}</CustomText>
                  <TouchableOpacity onPress={() => handleReplyToComment(reply.id)}>
                    <CustomText>Répondre</CustomText>
                  </TouchableOpacity>

                </View>
              ))}
            </View>
          ))}
        </ScrollView>

        <View style={styles.commentInputContainer}>
          <View style={{ width: 40, height: 40 }}>
            <View style={{ borderRadius: 30, overflow: 'hidden' }}>
              {profile === profileImage ? (
                <Image style={{ width: 40, height: 40 }} source={require('../assets/' + profileImage)} />
              ) : (
                <Image source={{ uri: profile }} style={{ width: 40, height: 40 }} />)
              }
            </View>
          </View>

          <TextInput
            style={styles.commentInput}
            placeholder="Ajouter un commentaire..."
            value={commentaire}
            onChangeText={SetAddCommentaire}
          />
          {/* Bouton de publication pour le commentaire principal */}
          <TouchableOpacity style={styles.commentButton} onPress={addComment}>
            <CustomText style={styles.commentButtonText}>Publier</CustomText>
          </TouchableOpacity>

        </View>
      </View>

      {/* <BottomBar navigation={navigation} /> */}
      <StatusBar style="auto" />

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentList: {
    marginTop: 10,
    flexDirection: 'row',
    flex: 1,
  },
  commentPopupContainer: {
    flex: 1,
    backgroundColor: 'white',
  },
  commentInput: {
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: 5,
    fontSize: heightPercentageToDP('2.5%'),
    marginBottom: heightPercentageToDP('1%'),
    marginTop: heightPercentageToDP('1%'),
    backgroundColor: '#ECF4F2',
    border: "1px solid black",
    color: 'black'
  },
  emptyCommentText: {
    textAlign: 'center',
    marginLeft: '2pc',
    color: '#9002C1',
    marginTop: 10,
    fontSize: 16,
  },
  commentItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 10,
    width: widthPercentageToDP('94%'),
    marginLeft: widthPercentageToDP('3%')
  },
  commentUsername: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  commentText: {
    marginBottom: 5,
  },
  commentDate: {
    color: '#aaa',
  },

  replyItem: {
    marginLeft: 20,
    marginTop: 10,
    borderLeftWidth: 1,
    borderLeftColor: '#ccc',
    paddingLeft: 10,
  },
  replyUsername: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  replyText: {
    marginBottom: 5,
  },
  replyDate: {
    color: '#aaa',
  },
  commentInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    width: widthPercentageToDP('90%'),
    justifyContent: 'space-between',
    padding: 10,
  },
  commentButtonText: {
    fontSize: heightPercentageToDP('2.5%'),
  },

});
