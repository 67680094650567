import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import { isliking } from '../../src/LikeManagement';
import { useFocusEffect } from '@react-navigation/native';

export default function TestScreen({ likePost, nolikePost, postId, userId }) {
  const [isSelected, setSelection] = useState(false);

  const handleLike = () => {
    const newSelection = !isSelected;
    setSelection(newSelection);
    if (newSelection) {
      likePost(postId);
    } else {
      nolikePost(postId);
    }
  };

  useFocusEffect( 
    useCallback(() => {
      // Reload data or perform any action
      isliking(postId, userId)
        .then((data) => {
          const likes = data.like;
          if (likes.some((like) => like.post_id === postId && like.user_id === userId)) {
            setSelection(true);
          } else {
            setSelection(false);
          }
        })
        .catch((error) => {
          console.log('Erreur lors de la récupération des likes :', error);
        });

      return () => {
        // Cleanup if needed
        console.log('Unfocused');
      };
    }, [postId, userId])
  );

  useEffect(() => {
    isliking(postId, userId)
      .then((data) => {
        const likes = data.like;
        if (likes.some((like) => like.post_id === postId && like.user_id === userId)) {
          setSelection(true);
        } else {
          setSelection(false);
        }
      })
      .catch((error) => {
        console.log('Erreur lors de la récupération des likes :', error);
      });
  }, [postId, userId]);

  const likeIcon = isSelected ? require('../../assets/icon-like-pushed.png') : require('../../assets/icon-like.png');

  return (
    <View style={styles.container}>
      <TouchableOpacity activeOpacity={0.5} onPress={handleLike}>
        <Image style={{ width: 20, height: 20 }} source={likeIcon} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
