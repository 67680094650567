import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, Text, TextInput, View, Image } from 'react-native';
import { register } from '../src/UserManagement';
import { TouchableOpacity } from 'react-native-gesture-handler';
import CheckBox from 'expo-checkbox';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function RegisterScreen({ navigation }) {

  const [colorBtn, setColorBtn] = useState('#9002C1');
  const [isSelected, setSelection] = useState(false);
  const [isSelectedActuality, setSelectionActuality] = useState(false);

  const [firstname, setFirstname] = useState('');
  const maxCharactersFirstname = 25;
  const [lastname, setLastname] = useState('');
  const maxCharactersLastname = 25;
  const [pseudo, setPseudo] = useState('');
  const maxCharactersPseudo = 17;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to validate the password
  const validatePassword = (text) => {
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(text);
    const hasNumber = /\d/.test(text);
    const hasUpperCase = /[A-Z]/.test(text);

    const isValid = hasSpecialChar && hasNumber && hasUpperCase && text.length >= 8;
    setPasswordIsValid(isValid);
  };

  function handleCheckbox() {
    setSelection(!isSelected);
    if (isSelected === false) {
      setColorBtn('#9002C1');
    } else {
      setColorBtn('#9002C1');
    }
  }

  function handleCheckNewsLetters() {
    setSelectionActuality(!isSelectedActuality);
    if (isSelected === false) {
      setColorBtn('#9002C1');
    } else {
      setColorBtn('#9002C1');
    }
  }

  function handleSubmit() {
    setPasswordMatch(true);

    if (!passwordIsValid || password !== confirmPassword) {
      // Display a text message for password requirements and mismatch
      setPasswordMatch(false);
      return;
    }

    // Your registration logic here
    let user = {
      email: email,
      firstname: firstname,
      lastname: lastname,
      pseudo: pseudo,
      password: password,
      confirmPassword: confirmPassword,
    };

    if (isSelected === false) {
      return;
    }
    register(user)
      .then((data) => {
        if (data.error === false) {
          navigation.navigate('VerifiedMail');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (

    <View style={styles.container}>
      <Image style={{ width: 118, height: 90, marginTop: '3vh' }} source={require('../assets/logoSUITUSviolet.png')} />
      <CustomText style={{ color: '#9002C1', marginTop: 10, fontSize: heightPercentageToDP('3%'), marginBottom: 15 }}>Rejoins la communauté !</CustomText>

      <View style={styles.formContainer}>
        <View style={styles.contain}>
          <TextInput style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Nom"
            onChangeText={(text) => {
              if (text.length <= maxCharactersLastname) {
                setLastname(text);
              }
            }}
            value={lastname} maxLength={maxCharactersLastname}
          />
        </View>
        <CustomText style={{ alignSelf: 'flex-end', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold', marginTop: '-2%', marginRight: 25, color: '#C881E0' }}>{lastname.length}/{maxCharactersLastname}</CustomText>
        <View style={styles.contain}>
          <TextInput style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Prénom"
            onChangeText={(text) => {
              if (text.length <= maxCharactersFirstname) {
                setFirstname(text);
              }
            }}
            value={firstname} maxLength={maxCharactersFirstname}
          />
        </View>
        <CustomText style={{ alignSelf: 'flex-end', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold', marginTop: '-2%', marginRight: 25, color: '#C881E0' }}>{firstname.length}/{maxCharactersFirstname}</CustomText>
        <View style={styles.contain}>
          <TextInput style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Pseudo"
            onChangeText={(text) => {
              if (text.length <= maxCharactersPseudo) {
                setPseudo(text);
              }
            }}
            value={pseudo} maxLength={maxCharactersPseudo}
          />
        </View>
        <CustomText style={{ alignSelf: 'flex-end', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold', marginTop: '-2%', marginRight: 25, color: '#C881E0' }}>{pseudo.length}/{maxCharactersPseudo}</CustomText>
        <View style={styles.contain}>
          <TextInput
            style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Email"
            onChangeText={setEmail}
          />
        </View>
        <View style={styles.contain}>
          <TextInput style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Mot de passe"
            secureTextEntry={!showPassword}
            onChangeText={(text) => {
              setPassword(text);
              validatePassword(text);
            }}
          />
          <View style={styles.toggleButton}>
            <TouchableOpacity onPress={togglePasswordVisibility}>
              {showPassword ? (
                <Image style={{ height: 10, width: 17, }} source={require('../assets/See.svg')} />
              ) : (
                <Image style={{ height: heightPercentageToDP('2%'), fontWeight: 'bold', width: 16, }} source={require('../assets/Hide.svg')} />
              )}
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', }}>
          {password.length > 0 && !passwordIsValid && (
            <CustomText style={{ color: 'red', fontSize: heightPercentageToDP('2.5%'), width: widthPercentageToDP('80%') }}>
              Le mot de passe doit contenir au moins 8 caractères, incluant un caractère spécial, un chiffre et une lettre majuscule.
            </CustomText>
          )}
        </View>
        <View style={styles.contain}>
          <TextInput style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Confirmer mot de passe"
            secureTextEntry={true}
            onChangeText={(text) => {
              setConfirmPassword(text);
              setPasswordMatch(true); // Reset password match state on change
            }}
          />
        </View>
        {!passwordMatch && (
          <CustomText style={{ color: 'red', fontSize: heightPercentageToDP('2.5%'), width: widthPercentageToDP('80%') }}>
            Les mots de passe ne correspondent pas.
          </CustomText>
        )}

        <View style={{ width: widthPercentageToDP('80%'), justifyContent: 'center', alignItems: 'center', }}>
          <View style={{ marginTop: 10, marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
            <CheckBox style={{ marginLeft: 0 }}
              value={isSelected}
              onValueChange={handleCheckbox}
            />
            <View style={{ alignItems: 'center', marginLeft: 15 }}>
              <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), fontWeight: 500, width: widthPercentageToDP('80%') }}> J’accepte les CGU et les conditions générales de SuitUs</CustomText>
            </View>
          </View>
          <View style={{ marginTop: 10, marginBottom: 5, display: 'flex', flexDirection: 'row', }}>
            <CheckBox style={{ marginLeft: 5 }}
              value={isSelectedActuality}
              onValueChange={handleCheckNewsLetters}
            />
            <View style={{ alignItems: 'center', marginLeft: 15 }}>
              <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), fontWeight: 500, width: widthPercentageToDP('80%'), }}>J’accepte de recevoir des actualité de la part de SuitUs</CustomText>
            </View>
          </View>
        </View>
        <TouchableOpacity onPress={handleSubmit} style={[styles.button, { backgroundColor: colorBtn }]}>
          <CustomText style={styles.buttonText}>{"INSCRIPTION"}</CustomText>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('Login')} style={[styles.button, { backgroundColor: colorBtn }]}>
          <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
        </TouchableOpacity>
      </View>
      <TouchableOpacity onPress={() => navigation.navigate('CG')}>
        <CustomText style={{ color: '#9002C1', marginBottom: heightPercentageToDP('10%'), fontSize: heightPercentageToDP('3%'), textAlign: 'center' }} >Lire nos conditions générales d'utilisation</CustomText>
      </TouchableOpacity>
      {/* <CustomText style={{textAlign: 'center',}}>En cochant cette case, vous </CustomText> */}
      <StatusBar style="auto" />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  contain: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: widthPercentageToDP('90%')
  },
  textinput: {
    width: '85%',
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: widthPercentageToDP('4%'),
    fontSize: heightPercentageToDP('3%'),
    marginBottom: heightPercentageToDP('1.5%'),
    marginTop: heightPercentageToDP('2%'),
    backgroundColor: '#ECF4F2'
  },
  toggleButton: {
    marginLeft: widthPercentageToDP('-6%')
  },
  formContainer: {
    width: widthPercentageToDP('90%'),
    backgroundColor: '#FEFEFE',
    marginBottom: heightPercentageToDP('5%'),
    marginTop: heightPercentageToDP('3%'),
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: widthPercentageToDP('4%'),
  },
  button: {
    padding: heightPercentageToDP('2%'),
    borderRadius: 8,
    marginBottom: heightPercentageToDP('1%'),
    marginTop: heightPercentageToDP('2%'),
    width: widthPercentageToDP('50%'),
    height: heightPercentageToDP('7%'),

  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff'
  },
});