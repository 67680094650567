import React, { useState, useEffect, useCallback } from 'react';
import { Image, StyleSheet, View, TouchableOpacity } from 'react-native';
import { getUser } from './src/UserManagement';
import { getNotifToken, modifyNotif } from './src/NotificationsManagement';

//Page : 
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import VerifiedMailScreen from './screens/VerifiedMailScreen';
import HomeScreen from './screens/HomeScreen';
import CGScreen from './screens/CGScreen';
import RecommendationsScreen from './screens/RecommendationsScreen';
import FavoritesScreen from './screens/FavoritesScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import MorphologiesScreen from './screens/MorphologiesScreen';
import ParametersScreen from './screens/ParametersScreen';
import PostScreen from './screens/PostScreen';
import PostUserScreen from './screens/PostUserScreen';
// import SearchScreen from './screens/SearchScreen';
import SearchScreenPost from './screens/SearchScreenOriginalPost';
import SearchScreen from './screens/SearchScreenOriginal';
import TwinScreen from './screens/MytwinsPage.js';
import ProfileScreen from './screens/ProfileScreen';
import PublishScreen from './screens/PublishScreen';
import WelcomeScreen from './screens/WelcomeScreen';
import BeginConfigurationScreen from './screens/BeginConfigurationScreen';
import UserTypeScreen from './screens/UserTypeScreen';
import InfluencerTypeScreen from './screens/InfluencerTypeScreen';
import SuitUserTypeScreen from './screens/SuitUserTypeScreen';
import YourInformationOneScreen from './screens/YourInformationOneScreen';
import YourInformationTwoScreen from './screens/YourInformationTwoScreen';
import YourInformationThreeScreen from './screens/YourInformationThreeScreen';
import YourInformationFourScreen from './screens/YourInformationFourScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import RequestEmailScreen from './screens/RequestEmailScreen';
import ResultMorphologyScreen from './screens/ResultMorphologyScreen';
import TutoScreen from './screens/TutoScreen';
import FAQScreen from './screens/FAQScreen';
import FollowsScreen from './screens/FollowsScreen';
import FollowersScreen from './screens/FollowersScreen';
import NotificationsScreen from './screens/NotificationsScreen';
import PrivateMessagesScreen from './screens/PrivateMessagesScreen';
import CrushScreen from './screens/CrushScreen';
import CommentHome from './screens/CommentHome.js';
import MessageScreen from './screens/MessageScreen';
import ProfileUserScreen from './screens/ProfileUserScreen.js';
import DropdownpbScreen from './screens/DropdownpbScreen';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';

const Tab = createMaterialBottomTabNavigator();
const Stack = createStackNavigator();

function MainScreen({ navigation }) {
  const [user, setUser] = useState(null);
  const [notif, setUserNotif] = useState([]);
  const [lu, setLu] = useState(0);

  const fetchData = () => {
    getUser()
      .then((data) => {
        setUser(data.user);
      })
      .catch(error => {
        console.log(error);
      });
    getNotifToken()
      .then((data) => {
        setUserNotif(data.notifications);
      })
      .catch(error => {
        //  console.log(error);
      });
  }
  useEffect(() => {
    fetchData();
  }, [lu]);

 
  return (
    <View style={{ flex: 1 }}>
      <View style={{ backgroundColor: '#9002C1', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10 }}>
        <Image source={require('./assets/logoSUITUSblanc.png')} style={{ width: 51, height: 39 }} />
        <View style={{ flexDirection: 'row', marginRight: 9 }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => { modifyNotif(1); setLu(1); navigation.navigate('Notifications'); }}>
              <Image source={require('./assets/icon-cloche.svg')} style={{ width: 19, height: 25, marginLeft: 10 }} />
            </TouchableOpacity>
            {notif.map((notif) => (notif.lu === 0 ? (
              <Image source={require('./assets/notif-point.svg')} style={{ width: 11, height: 10, marginLeft: -6, marginRight: 3, marginTop: 10 }} />
            ) : null
            ))}
          </View>
          <TouchableOpacity onPress={() => navigation.navigate('PrivateMessages')}>
            <Image source={require('./assets/icon-message-horizontale.svg')} style={{ width: 24, height: 24, marginLeft: 17 }} />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => { localStorage.removeItem('sessionToken'); window.location.reload() }} style={{ width: 24, height: 24, marginLeft: 17 }}>
            <Image source={require('./assets/icon-logout.svg')} style={{ width: 24, height: 24, marginLeft: 0 }} />
          </TouchableOpacity>
        </View>
      </View>
      <TabNavigator />
    </View>
  );
}

function TabNavigator() {
  // profile user : 
  const profileImage = 'ex-profil.png';
  const [profile, setProfilUser] = useState(null);
  const [morpho, setMophologycalculated] = useState([]);

  const fetchData = () => {
    getUser()
      .then((data) => {
        setProfilUser(data.user.profile_image);
        setMophologycalculated(data.user.suituser);
      })
      .catch(error => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Tab.Navigator activeColor="#9002C1" inactiveColor="#9002C1" barStyle={{ backgroundColor: '#9002C1', height: 60 }} >
      <Tab.Screen name="Accueil" component={HomeScreen} options={{ tabBarIcon: () => (<Image source={require('./assets/icon-home-heart2.svg')} style={{ width: 26, height: 26, }} />), }} />
      <Tab.Screen name="Recherche" component={SearchScreen} options={{ tabBarIcon: () => (<Image source={require('./assets/icon-glass-white1.png')} style={{ width: 26, height: 26 }} />), }} />
      {morpho !== 0 && (
        <Tab.Screen name="Publication" component={PublishScreen} options={{ tabBarIcon: () => (<Image source={require('./assets/icon-plus.png')} style={{ width: 26, height: 26 }} />), }} />
      )}
      <Tab.Screen name="Suit'Cops" component={RecommendationsScreen} options={{ tabBarIcon: () => (<Image source={require('./assets/icon-suit-cops.png')} style={{ width: 27, height: 25 }} />), }} />
      <Tab.Screen name="Profil" component={ProfileScreen} options={{
        tabBarIcon: () => {
          if (profile === profileImage) {
            return (<Image style={{ width: 26, height: 26, borderRadius: 30 }} source={require('./assets/' + profileImage)} />);
          }
          else {
            return (<Image source={{ uri: profile }} style={{ width: 26, height: 26, borderRadius: 30 }} />);
          }
        },
      }} />
    </Tab.Navigator>
  );
}

export default function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkSession = async () => {
    const session = await localStorage.getItem('sessionToken');
    setIsLoggedIn(session !== null);
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {isLoggedIn ? (
          <>
            <Stack.Screen name="Main" component={MainScreen} />
            <Stack.Screen name='Twin' component={TwinScreen} />
            <Stack.Screen name='SearchPost' component={SearchScreenPost} />
            <Stack.Screen name="Favorites" component={FavoritesScreen} />
            
            <Stack.Screen name="Parameters" component={ParametersScreen} />
            <Stack.Screen name="Recherche" component={SearchScreen} />
            <Stack.Screen name="Post" component={PostScreen} />
            <Stack.Screen name="PostUserScreen" component={PostUserScreen} />
            <Stack.Screen name="UserProfile" component={ProfileUserScreen} />
            <Stack.Screen name="PrivateMessages" component={PrivateMessagesScreen} />
            <Stack.Screen name="Notifications" component={NotificationsScreen} />
            <Stack.Screen name="SuitUserType" component={SuitUserTypeScreen} />
            <Stack.Screen name="UserType" component={UserTypeScreen} />
            
            <Stack.Screen name="FAQ" component={FAQScreen} />
            <Stack.Screen name="Follows" component={FollowsScreen} />
            <Stack.Screen name="Followers" component={FollowersScreen} />
            <Stack.Screen name="CommentHome" component={CommentHome} />
            <Stack.Screen name="Messages" component={MessageScreen} />
            <Stack.Screen name="Dropdownpb" component={DropdownpbScreen} />
            <Stack.Screen name="Morphologies" component={MorphologiesScreen} />
           
            <Stack.Screen name="NotFound" component={NotFoundScreen} />
            <Stack.Screen name="CG" component={CGScreen} />



            
            <Stack.Screen name="InfluencerType" component={InfluencerTypeScreen} />
            <Stack.Screen name="ResultMorphology" component={ResultMorphologyScreen} />
            <Stack.Screen name='TutoScreen' component={TutoScreen} />
            <Stack.Screen name="YourInformationOne" component={YourInformationOneScreen} />
            <Stack.Screen name="YourInformationTwo" component={YourInformationTwoScreen} />
            <Stack.Screen name="YourInformationThree" component={YourInformationThreeScreen} />
            <Stack.Screen name="YourInformationFour" component={YourInformationFourScreen} />
            <Stack.Screen name="Welcome" component={WelcomeScreen} />
          </>
        ) : (
          <>
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Register" component={RegisterScreen} />
            <Stack.Screen name="VerifiedMail" component={VerifiedMailScreen} />
            <Stack.Screen name="SuitUserType" component={SuitUserTypeScreen} />
            <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
            <Stack.Screen name="RequestEmail" component={RequestEmailScreen} />
            <Stack.Screen name="NotFound" component={NotFoundScreen} />
            <Stack.Screen name="CG" component={CGScreen} />
            <Stack.Screen name="InfluencerType" component={InfluencerTypeScreen} />
            <Stack.Screen name="ResultMorphology" component={ResultMorphologyScreen} />
            <Stack.Screen name='TutoScreen' component={TutoScreen} />
            <Stack.Screen name="YourInformationOne" component={YourInformationOneScreen} />
            <Stack.Screen name="YourInformationTwo" component={YourInformationTwoScreen} />
            <Stack.Screen name="YourInformationThree" component={YourInformationThreeScreen} />
            <Stack.Screen name="YourInformationFour" component={YourInformationFourScreen} />
            <Stack.Screen name="Welcome" component={WelcomeScreen} />
            <Stack.Screen name="Parameters" component={ParametersScreen} />
            <Stack.Screen name="Recherche" component={SearchScreen} />
            <Stack.Screen name="UserType" component={UserTypeScreen} />
            <Stack.Screen name="Morphologies" component={MorphologiesScreen} />
            <Stack.Screen name="UserProfile" component={ProfileUserScreen} />
          </>
        )}






        {/* <Stack.Screen name="Home" component={HomeScreen} /> */}
        {/* <Stack.Screen name="Profile" component={ProfileScreen} /> */}
        {/* <Stack.Screen name="Search" component={SearchScreen} /> */}
        {/* <Stack.Screen name="Publish" component={PublishScreen} /> */}
        {/* <Stack.Screen name="BeginConfiguration" component={BeginConfigurationScreen} /> */}
        {/* <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} /> */}
        {/* <Stack.Screen name="CrushScreen"component={CrushScreen} /> */}

      </Stack.Navigator>
    </NavigationContainer>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
