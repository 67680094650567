import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Image, Text } from 'react-native';

export default function Parameters({ navigation }) {

  return (
    <View style={styles.container}>
      <Image
        style={{ width: 7, height: 29 }}
        source={require('../../../assets/' + 'parameters_post.svg')}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});