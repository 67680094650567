import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Button, Image } from 'react-native';
import { getListNumbers } from '../src/dropdowns/NumbersManagement';
import { getUser } from '../src/UserManagement';
import { setYourInformationTwo } from '../src/MorphologyManagement';
import DropDownPicker from "react-native-dropdown-picker";

export default function DropdownpbScreen({ navigation }) {
    const [DDLShoulderOpen, setDDLShoulderOpen] = useState(false);
    const [DDLShoulderValue, setDDLShoulderValue] = useState(90);
    const [DDLShoulderItems, setDDLShoulderItems] = useState([]);

    const [DDLWaistOpen, setDDLWaistOpen] = useState(false);
    const [DDLWaistValue, setDDLWaistValue] = useState(90);
    const [DDLWaistItems, setDDLWaistItems] = useState([]);

    const [DDLHighHipOpen, setDDLHighHipOpen] = useState(false);
    const [DDLHighHipValue, setDDLHighHipValue] = useState(90);
    const [DDLHighHipItems, setDDLHighHipItems] = useState([]);

    const [DDLHipOpen, setDDLHipOpen] = useState(false);
    const [DDLHipValue, setDDLHipValue] = useState(90);
    const [DDLHipItems, setDDLHipItems] = useState([]);

    const fetchData = () => {
      getListNumbers()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setDDLShoulderItems(data.shoulders);
        setDDLWaistItems(data.waists);
        setDDLHighHipItems(data.highhips);
        setDDLHipItems(data.hips);
      })

      getUser()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        //setUser(data.user);
      })
    }

    const onSelectShoulder = (item) => {
      setDDLShoulderValue(item);
    }
  
    const onSelectWaist = (item) => {
      setDDLWaistValue(item);
    }
  
    const onSelectHighHip = (item) => {
      setDDLHighHipValue(item);
    }
  
    const onSelectHip = (item) => {
      setDDLHipValue(item);
    }

    useEffect(() => {
        fetchData();
    },[]);

    function handlePrevious() {
      navigation.navigate('YourInformationOne', {
        previousPage: '',
      })
    }

    function handleNext() {
      const infoUser = {shoulder: DDLShoulderValue, waist: DDLWaistValue, high_hip: DDLHighHipValue, hip: DDLHipValue} 
      setYourInformationTwo(infoUser)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('YourInformationThree')
        }
      })
    }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <View style={styles.containerNumber}>
          <View style={{marginLeft: '60px', marginRight: '60px'}}>
            <Text>1</Text>
          </View>
          <View style={{marginRight: '60px' , backgroundColor: 'purple', width: '20px', alignItems: 'center', borderRadius: '5px'}}>
            <Text style={{color: 'white', fontWeight: 'bold'}}>2</Text>
          </View>
          <View style={{marginRight: '60px'}}>
            <Text>3</Text>
          </View>
          <View style={{marginRight: '60px'}}>
            <Text>4</Text>
          </View>
        </View>
      </View>
      <Text style={{marginTop: '20px', marginBottom: '20px'}}>Renseigner vos informations:</Text>
      <View>
        <DropDownPicker
            open={DDLShoulderOpen}
            value={DDLShoulderValue}
            items={DDLShoulderItems}
            setOpen={setDDLShoulderOpen}
            setValue={setDDLShoulderValue}
            setItems={setDDLShoulderItems}
            zIndex={3000}
            zIndexInverse={1000}
        />
        <DropDownPicker
            open={DDLWaistOpen}
            value={DDLWaistValue}
            items={DDLWaistItems}
            setOpen={setDDLWaistOpen}
            setValue={setDDLWaistValue}
            setItems={setDDLWaistItems}
            zIndex={3000}
            zIndexInverse={1000}
        />
        <DropDownPicker
            open={DDLHighHipOpen}
            value={DDLHighHipValue}
            items={DDLHighHipItems}
            setOpen={setDDLHighHipOpen}
            setValue={setDDLHighHipValue}
            setItems={setDDLHighHipItems}
            zIndex={3000}
            zIndexInverse={1000}
        />
        <DropDownPicker
            open={DDLHipOpen}
            value={DDLHipValue}
            items={DDLHipItems}
            setOpen={setDDLHipOpen}
            setValue={setDDLHipValue}
            setItems={setDDLHipItems}
            zIndex={3000}
            zIndexInverse={1000}
        />
      </View>
      <View style={styles.containerButtons}>
          <View style={{marginRight: '40px'}}>
            <Button
                title="RETOUR"
                color="grey"
                onPress={handlePrevious}
            />
          </View>
          <View>
            <Button
                title="SUIVANT"
                color="purple"
                onPress={handleNext}
            />
          </View>
      </View>
      <Text>TEST</Text>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerSuitUserType: {
    flex: 1,
    backgroundColor: '#B3D2CC',
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
    height: 50,
    maxHeight: 50,
  },
  containerNumber: {
    flex: 4,
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 40,
    width: 300,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'green',
    borderRadius: '20px'
  },
  containerBoxInput: {
    flex: 3,
    flexDirection: 'row',
    backgroundColor: '#E4C0F0',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    width: 330,
    maxHeight: 80,
    padding: "10px",
    marginBottom: '10px',
  },
  containerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blocHelp: {
    backgroundColor: '#EDEDED',
    height: 90,
    width: 120,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginBottom: '20px',
  }
});