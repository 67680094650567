import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Image, FlatList, Modal, ActivityIndicator } from 'react-native';
import Head from '../src/components/Head';
//Data:
import { getListUsers, getUser } from '../src/UserManagement';
import { followersList, deleteFollower } from '../src/FollowManagement';
//Text :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function FollowersScreen({ navigation, route }) {

  const { token, pseudo } = route.params;
  const profileImage = 'ex-profil.png';

  const [othertoken, setOtherToken] = useState([]);
  const [search, setSearch] = useState('');
  const [followers, setFollowers] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => { setModalVisible(!isModalVisible); };

  const fetchData = () => {
    setLoading(true);
    getUser()
      .then((data) => {
        setLoading(false);
        setOtherToken(data.user.token);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
    getListUsers()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
      })
    followersList(token)
      .then((data) => {
        setFollowers(data.followers);
      }).catch(error => {
        console.log(error);
      }).then(data => {
      })
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <View style={styles.container}>

      <Head titre={"Followers"} navigation={navigation} />

      {/* <View style={styles.contain}>
        <TextInput style={styles.textinput}
          placeholderTextColor="gray"
          placeholder="Recherche..."
          onChangeText={setSearch}
        />
      </View> */}

      {
        loading && <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#9002C1" />
          <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
        </View>
      }

      <View style={{ flex: 1, flexDirection: 'column', marginTop: '0.5pc' }}>
        <FlatList
          data={followers}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <View style={{ flexDirection: 'row', borderWidth: 2, borderColor: '#E4C0F0', borderRadius: 25, width: 302, marginBottom: '0.5pc' }}>
              <View style={{ borderRadius: 20 }}>
                {item.profile_image === profileImage ? (
                  <Image style={styles.ppfollow} source={require('../assets/' + profileImage)} />
                ) : (
                  <Image style={styles.ppfollow} source={{ uri: item.profile_image }} />)
                }
              </View>
              <View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <CustomText style={{ fontWeight: 'bold' }}>{item.pseudo}</CustomText>
                <View style={{ backgroundColor: '#E2CBEA', borderRadius: 50, width: 21, height: 19, marginTop: '1pc', marginLeft: '-0.8pc' }}>
                  <CustomText style={{ fontWeight: 'bold', color: '#9002C1', textAlign: 'center' }}>{item.morphology_calculated}</CustomText>
                </View>

                {token === othertoken ?
                  (<TouchableOpacity style={{ marginTop: '-2.2pc', marginLeft: '10pc' }} onPress={toggleModal}>
                    <CustomText style={{ color: '#9002C1' }}>Vous suit</CustomText>
                  </TouchableOpacity>
                  ) : (<TouchableOpacity style={{ marginTop: '-2.2pc', marginLeft: '10pc' }}>
                    <CustomText style={{ color: '#9002C1' }}>Suit</CustomText>
                  </TouchableOpacity>)
                }
              </View>

              <Modal visible={isModalVisible} animationType="fade" transparent={true} onRequestClose={() => setModalVisible(false)}>
                <View style={styles.modalBackground}>
                  <View style={styles.modalContainer}>
                    <CustomText style={{ width: 298, textAlign: 'center' }}>Es-tu sûr(e) de ne plus suivre
                      <BoldText style={{ color: '#9002C1' }}> {item.pseudo}</BoldText> ?</CustomText>
                    <View style={{ borderRadius: 20 }}>
                      {item.profile_image === profileImage ? (
                        <Image style={styles.ppfollow} source={require('../assets/' + profileImage)} />
                      ) : (
                        <Image style={styles.ppfollow} source={{ uri: item.profile_image }} />)
                      }
                    </View>
                    <TouchableOpacity onPress={() => { deleteFollower(item.id, token).then(() => { fetchData(); toggleModal(); }); }}>
                      <BoldText style={{ color: '#9002C1' }}>Ne plus suivre</BoldText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={toggleModal}>
                      <BoldText>Annuler</BoldText>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
            </View>

          )}
        />

      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contain: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  ppfollow: {
    height: 55,
    width: 55,
    borderRadius: 60,
  },
  textinput: {
    width: 300,
    height: 40,
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: '#ECF4F2'
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
    flexDirection: 'column',
    borderColor: '#9002C1',
    justifyContent: 'space-around',
    borderRadius: 10,
    width: 305,
    height: 175,
  },
});