import Config from "./Api_Url";

export async function setMorphologyOpinion(morphology) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, morphology: morphology};
    const response = await fetch(`${Config.serverUrl}/api/user/set/morphologyopinion`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (!response.ok) {
      alert("L'envoie de la morphology a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("SetMorphology Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function setYourInformationOne(info) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, info: info};
    const response = await fetch(`${Config.serverUrl}/api/user/set/yourinformationone`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (!response.ok) {
      alert("L'envoie de vos informations a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("SetYourInformationOne Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function setYourInformationTwo(info) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, info: info};
    const response = await fetch(`${Config.serverUrl}/api/user/set/yourinformationtwo`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (!response.ok) {
      alert("L'envoie de vos informations a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("SetYourInformationTwo Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function setYourInformationThree(info) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, info: info};
    const response = await fetch(`${Config.serverUrl}/api/user/set/yourinformationthree`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (!response.ok) {
      alert("L'envoie de vos informations a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("SetYourInformationThree Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function setYourInformationFour(hairColor) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, hairColor: hairColor};
    const response = await fetch(`${Config.serverUrl}/api/user/set/yourinformationfour`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (!response.ok) {
      alert("L'envoie de vos informations a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("SetYourInformationFour Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getMorphology(men) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, men};
    const response = await fetch(`${Config.serverUrl}/api/morphology/set`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (!response.ok) {
      alert("L'obtention de la morphologie a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("GetMorphology Request Failed");
    }
    const data = await response.json();
    return data;
}