import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Button, Image, TouchableOpacity } from 'react-native';
import { getUser } from '../src/UserManagement';
import { setYourInformationFour, getMorphology } from '../src/MorphologyManagement';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function YourInformationFourScreen({ navigation }) {
  const [user, setUser] = useState([]);
  const [hairNumber, setHairNumber] = useState(0);
  const hairColorArray = ["Blond-Clair", "Blond-Foncé", "Roux", "Châtain-Clair", "Châtain-Foncé", "Noir"]

  const fetchData = () => {
    getUser()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setUser(data.user);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  function selectHairColor(val) {
    setHairNumber(val);
  }

  function handlePrevious() {
    navigation.navigate('YourInformationThree')
  }

  function handleSubmit() {
    let hairColor = "";

    if (hairNumber === -1) {
      hairColor = "Autres"
    } else {
      hairColor = hairColorArray[hairNumber];
    }

    setYourInformationFour(hairColor)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error != false) {
          return;
        }
      })
    let men = { chest: user.chest, waist: user.waist, hip: user.hip, high_hip: user.high_hip, shoulder: user.shoulder }
    getMorphology(men)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.morphology != "Forme Inconnue") {
          navigation.navigate('ResultMorphology', {
            morphology: data.morphology,
          })
        } else {
          alert("Mensurations incorrectes, veuillez les modifier pour continuer.")
        }
      })
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <View style={styles.containerNumber}>
          <View style={{ marginRight: 60, marginLeft: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>1</BoldText>
          </View>
          <View style={{ marginRight: 60, color: '#E4C0F0' }}>
            <BoldText style={{ color: '#E4C0F0' }}>2</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#E4C0F0' }}>3</BoldText>
          </View>
          <View style={{ marginRight: 60 }}>
            <BoldText style={{ color: '#9002C1' }}>4</BoldText>
          </View>
        </View>
      </View>
      <CustomText style={{ marginTop: heightPercentageToDP('3%'), marginBottom: heightPercentageToDP('3%'), textTransform: 'uppercase', fontSize: heightPercentageToDP('3%'), }}>La couleur de tes cheveux</CustomText>
      <View>
        <View style={styles.containerHairs}>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == 0 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(0)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Blond-Clair.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Blond-Clair</BoldText>
            </TouchableOpacity>
          </View>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == 1 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(1)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Blond-Foncé.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Blond-Foncé</BoldText>
            </TouchableOpacity>
          </View>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == 2 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(2)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Roux.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Roux</BoldText>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.containerHairs}>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == 3 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(3)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Châtain-Clair.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Châtain-Clair</BoldText>
            </TouchableOpacity>
          </View>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == 4 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(4)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Châtain-Foncé.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Châtain-Foncé</BoldText>
            </TouchableOpacity>
          </View>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == 5 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(5)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Noir.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Noir</BoldText>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.containerHairs2}>
          <View style={{ marginRight: 10 }}>
            <TouchableOpacity style={{ border: "solid 2px", borderRadius: 5, padding: 3, alignItems: 'center', color: hairNumber == -1 ? "#9002C1" : 'white' }} activeOpacity={0.5} onPress={() => selectHairColor(-1)}>
              <Image
                style={{ width: 80, height: 100 }}
                source={require('../assets/Autres.png')}
              />
              <BoldText style={{ marginTop: 10, textAlign: 'center', fontSize: heightPercentageToDP('2.5%') }}>Autres</BoldText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <View style={styles.containerButtons}>
        <View>
          <TouchableOpacity onPress={handlePrevious} style={[styles.button1, styles.buttonWithShadow]}>
            <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
          </TouchableOpacity>
        </View>
        <View>
          <TouchableOpacity onPress={handleSubmit} style={[styles.button, styles.buttonWithShadow]}>
            <CustomText style={styles.buttonText}>{"SUIVANT"}</CustomText>
          </TouchableOpacity>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerNumber: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: heightPercentageToDP('2%'),
    paddingBottom: heightPercentageToDP('2%'),
    width: widthPercentageToDP('90%'),
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: 'white',
    borderColor: '#E4C0F0',
    borderRadius: 20,
  },
  containerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginBottom: heightPercentageToDP('10%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  button1: {
    backgroundColor: '#C7C7C6',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  buttonWithShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // Élévation pour Android
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    padding: 5,
    color: '#ffff'
  },
  buttonWithShadow: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    elevation: 5, // Élévation pour Android
  },
  containerHairs: {
    flex: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  containerHairs2: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: heightPercentageToDP('5%'),
    marginBottom: heightPercentageToDP('5%')
  },
});