import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Modal, Image, FlatList, TouchableOpacity, ScrollView } from 'react-native';
import { getListUsers, getUser } from '../src/UserManagement';
import CustomText from '../src/components/Font/CustomText';

export default function TwinScreen({ navigation, route }) {
  const second = route.params?.second;
  const profileImage = 'ex-profil.png';

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [matchedProfiles, setMatchedProfiles] = useState([]);
  const [message, setMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);


  const fetchData = () => {
    getListUsers()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      })
      .then((data) => {
        setUsers(data.users);
      });
    getUser()
      .then((data) => {
        setUser(data.user);
      })
      .catch(error => {
        console.error('Erreur des données utilisateur :', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Matching Algo
  const performMatching = () => {
    const maxWeightDifference = 5; // maximum poids de différence
    const maxHeightDifference = 15; // maximum taille de différence

    const matchedProfiles = users.filter((otherUser) => {
      const weightDifference = Math.abs(user.weight - otherUser.weight);
      const isWeightMatch = weightDifference <= maxWeightDifference;
      const heightDifference = Math.abs(user.height - otherUser.height);
      const isHeightMatch = heightDifference <= maxHeightDifference;
      return (
        user.morphology_calculated === otherUser.morphology_calculated && user.dress === otherUser.dress && user.braL === otherUser.braL && isWeightMatch &&
        isHeightMatch
      );
    });

    setMatchedProfiles(matchedProfiles);
    return matchedProfiles;
  };

  const handleMatchingPress = () => {
    const matchedProfiles = performMatching();

    if (matchedProfiles.length > 0) {
      setMessage(`${matchedProfiles.length} profiles vous ressemblent !`);
    } else {
      setMessage('Aucun profil trouvé.');
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
        <View style={styles.contain}>
          <TouchableOpacity onPress={() => setModalVisible(true)} style={{ backgroundColor: '#9002C1', padding: 7, borderRadius: 10 }}>
            <CustomText style={{ color: 'white' }}>Chercher sa jumelle</CustomText>
          </TouchableOpacity>
        </View>
      </View>

      <TouchableOpacity onPress={() => setModalVisible(false)} style={{ alignSelf: 'flex-end' }}>
        <Modal animationType="fade" transparent={true} visible={modalVisible}
          onRequestClose={() => { setModalVisible(false); }}
        >
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              {!message && (
                <CustomText style={{ padding: 7, fontSize: 16, color: '#9002C1', textAlign: 'center' }}>
                  Appuyez ci-dessous pour découvrir qui est votre jumelle
                </CustomText>
              )}
              {message && (
                <CustomText style={{ color: '#9002C1', fontSize: 16, marginTop: 10, marginBottom: 10 }}>
                  {message}
                </CustomText>
              )}
              <TouchableOpacity onPress={handleMatchingPress} style={{ backgroundColor: '#9002C1', padding: 7, borderRadius: 10, marginBottom: 15 }}>
                <CustomText style={{ color: 'white' }}>Ma jumelle</CustomText>
              </TouchableOpacity>

              <View style={{ width: 200 }}>
                <FlatList data={matchedProfiles} pagingEnabled={true} horizontal={true}
                  renderItem={({ item }) => (
                    <View style={{ marginBottom: 9, marginRight: 9 }}>
                      <View style={{ width: 100 }}>
                        <TouchableOpacity onPress={() => { navigation.navigate('UserProfile', { iduser: item.id }); }}>
                          <View style={{ borderRadius: 30, width: 56, height: 56 }}>
                            <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                              {item.profile_image === profileImage ? (
                                <Image style={{ width: 56, height: 53 }} source={require(`../assets/${profileImage}`)} />
                              ) : (
                                <Image source={{ uri: item.profile_image }} style={{ width: 56, height: 53 }} />)
                              }
                            </View>
                          </View>
                          <CustomText>@{item.firstname}</CustomText>
                          <CustomText style={{ opacity: 0.4 }}>{item.lastname}</CustomText>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                  keyExtractor={(item) => item.id}
                />
              </View>
            </View>
          </View>
        </Modal>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  contain: {
    alignItems: 'center',
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    width: 320,
    borderRadius: 10,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    opacity: 0.2,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 15,
    marginTop: 10,
    height: 33,
    width: 255,
    marginLeft: 35,
  },
  searchIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  textinput: {
    width: 300,
    height: 40,
    borderRadius: 10,
    padding: 10,
    fontSize: 16,
    marginBottom: 10,
    marginTop: 10,
    height: 33,
    color: 'white',
  },
  iconSearchBar: {
    padding: 5,
    left: -40,
  },
});
