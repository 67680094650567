import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Button, TextInput, Image, TouchableOpacity, FlatList, ScrollView, ActivityIndicator } from 'react-native';
import CheckBox from 'expo-checkbox';

//Data : 
import { getListDressSizes } from '../src/dropdowns/DressSizesManagement';
import { getListTypes } from '../src/dropdowns/TypesManagement';
import { getListBrands } from '../src/dropdowns/BrandsManagement';
import { create, getFavoritePosts } from '../src/PostManagement';
// Dépliant :
import DropDown from '../src/components/Dropdown';

import { Slider } from 'react-native-range-slider-expo';

//Font : 
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function PublishScreen({ navigation }) {

    const [DDLDressValue, setDDLDressValue] = useState(38);
    const [DDLDressItems, setDDLDressItems] = useState([]);

    const [DDLTypesValue, setDDLTypesValue] = useState('robe');
    const [DDLTypesItems, setDDLTypesItems] = useState([]);

    const [DDLBrandsValue, setDDLBrandsValue] = useState('Habillez-moi');
    const [DDLBrandsItems, setDDLBrandsItems] = useState([]);

    const [title, setTitle] = useState('');
    const maxCharactersTitle = 30;

    const [ref, setRef] = useState('');
    const maxCharactersRef = 40;

    const [comment, setComment] = useState('');
    const maxCharactersComments = 195;

    const [checkTab, setCheckTab] = useState([false, true, false]);
    const enumAjt = { Ample: 0, Ajuste: 1, Serre: 2 }
    const [ajustment, setAjustment] = useState('Ajusté');
    const [iAjt, setIajt] = useState(1);

    const [texture, setTexture] = useState(0);
    const [color, setColor] = useState(0)
    const [thickness, setThickness] = useState(0)
    const [fluidity, setFluidity] = useState(0)
    const [comfort, setComfort] = useState(0)

    const [imagePick, setImagePick] = useState("")
    const hiddenFileInput = useRef(null);
    const [files, setFiles] = useState([]);
    const [binaryFiles, setBinaryFiles] = useState([])
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        getListDressSizes()
            .then((data) => {
                return data;
            }).catch(error => {
                console.log(error);
            }).then(data => {
                setDDLDressItems(data.dresssizes);
            })

        getListTypes()
            .then((data) => {
                return data;
            }).catch(error => {
                console.log(error);
            }).then(data => {
                setDDLTypesItems(data.types);
            })

        getListBrands()
            .then((data) => {
                return data;
            }).catch(error => {
                console.log(error);
            }).then(data => {
                setDDLBrandsItems(data.brands);
            })

        getFavoritePosts()
            .then((data) => {
                return data;
            }).catch(error => {
                console.log(error);
            }).then(data => {
                //console.log(data.favorites);
            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onSelectDress = (item) => {
        setDDLDressValue(item);
    }

    const onSelectTypes = (item) => {
        setDDLTypesValue(item);
    }

    const onSelectBrands = (item) => {
        setDDLBrandsValue(item);
    }

    function handleAjustment(previous, next, name) {
        if (previous === next) {
            return;
        }
        checkTab[next] = true;
        checkTab[previous] = false;
        setCheckTab(checkTab);
        setAjustment(name);
        setIajt(next);
    }

    function handleSubmit() {
        if (title === 'None') {
            return;
        }

        if (images.length === 0) {
            alert('Please select image files');
            return;
        }

        var bar_code = 'None';
        var bar_code_text = "None";
        var caption = "This is caption";

        let post = {
            title: title, adjustment: ajustment,
            caption: caption, bar_code: bar_code,
            bar_code_text: bar_code_text,
            comfort: comfort, color: color,
            description: comment, thickness: thickness,
            fluidity: fluidity, brand: DDLBrandsValue,
            reference: ref, size: DDLDressValue,
            texture: texture, type: DDLTypesValue
        };
        setLoading(true)
        create(post, images)
            .then((data) => {
                setLoading(false)
                if (data.error === false) {
                    //navigation.navigate('Profile');
                    window.location.reload()
                } else {
                    //navigation.navigate('Profile');
                    window.location.reload()
                }

            }).catch(error => {
                setLoading(false)
                console.log(error);
            })
    }

    const handleClick = (event) => {
        if (files.length === 5) {
            return;
        }
        hiddenFileInput.current.click();
    };

    const handleFileChange = (e) => {
        //setImages(e.target.files);
        if (images.length < 5) {
            for (var i = 0; i < images.length; i++) {
                if (images[i].name === e.target.files[0].name) {
                    return;
                }
            }
            var newFiles = [...images];
            newFiles.push(e.target.files)
            setImages(newFiles);
        }
    };

    const handleDeletion = (name) => {
        console.log(name);
        let newFiles = [...images]
        newFiles = newFiles.filter(item => item[0].name !== name)
        setImages(newFiles);
    };

    return (
        <ScrollView>
            <View style={styles.container}>
                <View style={styles.titleContainer}>
                    <View>
                        <CustomText style={{ fontSize: heightPercentageToDP('3%'), color: '#9002C1', marginBottom: heightPercentageToDP('3%'), textTransform: 'uppercase' }}>Nouvelle publication</CustomText>
                    </View>
                </View>
                {/* <Image style={{width:100, height:100}} source={imagePick} /> */}
                <TouchableOpacity onPress={handleClick}>
                    <View style={styles.blocMedia}>
                        {/*  <input style={{ display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleChange} accept="image/*, video/*" /> */}
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                            accept="image/*, video/*"
                            onChange={handleFileChange}
                        />
                        <CustomText style={{
                            color: "#E4C0F0", padding: 10, fontSize: heightPercentageToDP('2%'),
                            lineHeight: heightPercentageToDP('5%')
                        }}>Charges tes médias ici (5 média max)</CustomText>
                        <CustomText style={{ color: "#E4C0F0", paddingLeft: 10 }}>Formats acceptés: .jpeg/.png/.jpg/.mp4</CustomText>
                        <FlatList
                            data={images}
                            renderItem={({ item }) =>
                                <View style={styles.boxFile}>
                                    <CustomText style={{ width: '95%' }}>{item[0].name}</CustomText>
                                    <TouchableOpacity activeOpacity={0.5} onPress={() => handleDeletion(item[0].name)}>
                                        <CustomText style={{ color: 'red' }}>X</CustomText>
                                    </TouchableOpacity>
                                </View>
                            }
                        />
                    </View>
                </TouchableOpacity>
                <View style={styles.containInput}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: 6.2 }}>
                            <DropDown
                                data={DDLDressItems}
                                value={DDLDressValue}
                                onSelect={onSelectDress}
                                question="Taille :"
                                style={{width: widthPercentageToDP('30%')}}
                            />
                        </View>
                        <View style={{ marginRight: 6.2 }}>
                            <DropDown
                                data={DDLTypesItems}
                                value={DDLTypesValue}
                                onSelect={onSelectTypes}
                                question="Type :"
                                style={{width: widthPercentageToDP('30%')}}
                            />
                        </View>
                        <View style={{ marginRight: 6.2 }}>
                            <DropDown
                                data={DDLBrandsItems}
                                value={DDLBrandsValue}
                                onSelect={onSelectBrands}
                                question="Marque :"
                                style={{width: widthPercentageToDP('30%')}}
                            />
                        </View>
                    </View>
                </View>
                <View style={{ border: "solid 1px", color: '#9002C1', 'padding': 10, 'borderRadius': 5, alignItems: "center", flexDirection: 'column', width: widthPercentageToDP('90%') }}>
                    <CustomText style={{ marginBottom: heightPercentageToDP('3%'), fontSize: heightPercentageToDP('3%'), textAlign: 'center', lineHeight: heightPercentageToDP('5%') }}>Quel est l'ajustement du vêtement ?</CustomText>
                    <View style={styles.checkBoxContainer}>
                        <CheckBox style={{ marginRight: 10, color: '#9002C1' }}
                            value={checkTab[enumAjt.Ample]}
                            onValueChange={() => handleAjustment(iAjt, enumAjt.Ample, 'Ample')}
                        />
                        <CustomText>Ample</CustomText>
                    </View>
                    <View style={styles.checkBoxContainer}>
                        <CheckBox style={{ marginRight: 10, color: '#9002C1' }}
                            value={checkTab[enumAjt.Ajuste]}
                            onValueChange={() => handleAjustment(iAjt, enumAjt.Ajuste, 'Ajusté')}
                        />
                        <CustomText>Ajusté</CustomText>
                    </View>
                    <View style={styles.checkBoxContainerAdjust}>
                        <CheckBox style={{ marginRight: 10, color: '#9002C1' }}
                            value={checkTab[enumAjt.Serre]}
                            onValueChange={() => handleAjustment(iAjt, enumAjt.Serre, 'Serré')}
                        />
                        <CustomText>Serré</CustomText>
                    </View>
                </View>
                <View style={{ marginTop: 20, width: widthPercentageToDP('90%') }}>
                    <View style={styles.descriptionContainer}>
                        <View style={{ width: widthPercentageToDP('25%') }}>
                            <CustomText style={{ color: '#9002C1', fontWeight: 'bold' }}>Texture</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText style={{ marginLeft: 0 }}>Doux</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('35%'), marginTop: -35 }}>
                            <Slider min={0} max={5} step={1}
                                valueOnChange={value => setTexture(value)}
                                initialValue={12}
                                knobColor='#9002C1'
                                valueLabelsBackgroundColor='black'
                                inRangeBarColor='#E4C0F0'
                                outOfRangeBarColor='#9002C1'
                                styleSize='small'
                                showRangeLabels={false}
                            />
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Rèche</CustomText>
                        </View>
                    </View>
                    <View style={styles.descriptionContainer}>
                        <View style={{ width: widthPercentageToDP('25%') }}>
                            <CustomText style={{ color: '#9002C1', fontWeight: 'bold' }}>Couleur</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Différent</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('35%'), 'marginTop': -35 }}>
                            <Slider min={0} max={5} step={1}
                                valueOnChange={value => setColor(value)}
                                initialValue={12}
                                knobColor='#9002C1'
                                valueLabelsBackgroundColor='black'
                                inRangeBarColor='#E4C0F0'
                                outOfRangeBarColor='#9002C1'
                                styleSize='small'
                                showRangeLabels={false}
                            />
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Fidèle</CustomText>
                        </View>
                    </View>
                    <View style={styles.descriptionContainer}>
                        <View style={{ width: widthPercentageToDP('25%') }}>
                            <CustomText style={{ color: '#9002C1', fontWeight: 'bold' }}>Epaisseur</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Fin</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('35%'), 'marginTop': -35 }}>
                            <Slider min={0} max={5} step={1}
                                valueOnChange={value => setThickness(value)}
                                initialValue={12}
                                knobColor='#9002C1'
                                valueLabelsBackgroundColor='black'
                                inRangeBarColor='#E4C0F0'
                                outOfRangeBarColor='#9002C1'
                                styleSize='small'
                                showRangeLabels={false}
                            />
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Epais</CustomText>
                        </View>
                    </View>
                    <View style={styles.descriptionContainer}>
                        <View style={{ width: widthPercentageToDP('25%') }}>
                            <CustomText style={{ color: '#9002C1', fontWeight: 'bold' }}>Fluidité</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Souple</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('35%'), 'marginTop': -35 }}>
                            <Slider min={0} max={5} step={1}
                                valueOnChange={value => setFluidity(value)}
                                initialValue={12}
                                knobColor='#9002C1'
                                valueLabelsBackgroundColor='black'
                                inRangeBarColor='#E4C0F0'
                                outOfRangeBarColor='#9002C1'
                                styleSize='small'
                                showRangeLabels={false}
                            />
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>Droit</CustomText>
                        </View>
                    </View>
                    <View style={styles.descriptionContainer}>
                        <View style={{ width: widthPercentageToDP('25%') }}>
                            <CustomText style={{ color: '#9002C1', fontWeight: 'bold' }}>Confort</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>+</CustomText>
                        </View>
                        <View style={{ width: widthPercentageToDP('35%'), 'marginTop': '-35px' }}>
                            <Slider min={0} max={5} step={1}
                                valueOnChange={value => setComfort(value)}
                                initialValue={12}
                                knobColor='#9002C1'
                                valueLabelsBackgroundColor='black'
                                inRangeBarColor='#E4C0F0'
                                outOfRangeBarColor='#9002C1'
                                styleSize='small'
                                showRangeLabels={false}
                            />
                        </View>
                        <View style={{ width: widthPercentageToDP('20%') }}>
                            <CustomText>++</CustomText>
                        </View>
                    </View>
                </View>
                <View style={{ width: widthPercentageToDP('90%') }}>
                    <CustomText style={{ fontSize: heightPercentageToDP('3%') }}> Nom du vêtement </CustomText>
                    <TextInput style={styles.textinput}
                        placeholderTextColor="gray"
                        placeholder="Ecris le nom du vêtement "
                        onChangeText={(text) => {
                            if (text.length <= maxCharactersTitle) {
                                setTitle(text);
                            }
                        }}
                        value={title} maxLength={maxCharactersTitle}
                    />
                    <CustomText style={{ alignSelf: 'flex-end', marginRight: 5, color: 'red', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold' }}>{title.length}/{maxCharactersTitle}</CustomText>
                </View>

                <View style={{ width: widthPercentageToDP('90%') }}>
                    <CustomText style={{ fontSize: heightPercentageToDP('3%') }}>Référence</CustomText>
                    <TextInput style={styles.textinput}
                        placeholderTextColor="gray"
                        placeholder="Note la référence"
                        onChangeText={(text) => {
                            if (text.length <= maxCharactersRef) {
                                setRef(text);
                            }
                        }}
                        value={ref} maxLength={maxCharactersRef}
                    />
                    <CustomText style={{ alignSelf: 'flex-end', marginRight: 5, color: 'red', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold' }}>{ref.length}/{maxCharactersRef}</CustomText>
                </View>

                <View style={{ width: widthPercentageToDP('90%') }}>
                    <CustomText style={{ fontSize: heightPercentageToDP('3%') }}>Description</CustomText>
                    <TextInput style={styles.textArea}
                        multiline={true}
                        numberOfLines={4}
                        placeholder="Ecris tes commentaires pour aider ta communauté"
                        placeholderTextColor="gray"
                        onChangeText={(text) => {
                            if (text.length <= maxCharactersComments) {
                                setComment(text);
                            }
                        }}
                        value={comment} maxLength={maxCharactersComments}
                    />
                    <CustomText style={{ alignSelf: 'flex-end', marginRight: 5, color: 'red', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold' }}>{comment.length}/{maxCharactersComments}</CustomText>

                </View>
                <View style={{ marginBottom: heightPercentageToDP('10%') }}>
                    {/* <Button
                        title="PUBLIER"
                        color="#9002C1"
                        onPress={handleSubmit}
                    /> */}
                    <TouchableOpacity onPress={handleSubmit} style={[styles.button, { backgroundColor: '#9002C1' }]}>
                        {loading ? (
                            <ActivityIndicator size="small" color="#ffffff" />
                        ) : (
                            <CustomText style={styles.buttonText}>{"PUBLIER"}</CustomText>
                        )}
                    </TouchableOpacity>
                </View>

                <StatusBar style="auto" />
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FEFEFE',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sortContainer: {
        flex: 4,
        flexDirection: 'row',
        maxHeight: 60,
        justifyContent: 'space-between',
    },
    titleContainer: {
        flex: 2,
        flexDirection: 'row',
        maxHeight: 60,
        justifyContent: 'space-between',
        marginTop: 20,
    },
    blocMedia: {
        height: 150,
        width: widthPercentageToDP('90%'),
        padding: 5,
        borderStyle: 'dashed',
        borderWidth: 3,
        marginBottom: 20,
        borderRadius: 20,
        borderColor: '#9002C1',
    },
    descriptionContainer: {
        flex: 4,
        flexDirection: 'row',
        maxHeight: 60,
        justifyContent: 'space-between',
        marginBottom: -10,
    },
    titlePostContainer: {
        flex: 2,
        flexDirection: 'row',
        maxHeight: 60,
        justifyContent: 'space-between'
    },
    descriptionPostContainer: {
        flex: 2,
        flexDirection: 'row',
        height: 200,
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    textinput: {
        //width: '85%',
        height: heightPercentageToDP('7%'),
        borderRadius: 5,
        padding: widthPercentageToDP('4%'),
        fontSize: heightPercentageToDP('3%'),
        marginBottom: heightPercentageToDP('1.5%'),
        marginTop: heightPercentageToDP('2%'),
        backgroundColor: '#ECF4F2',
    },
    textArea: {
        width: '100%',
        height: 100,
        borderRadius: 5,
        padding: 10,
        fontSize: heightPercentageToDP('3%'),
        marginBottom: 10,
        marginTop: heightPercentageToDP('2%'),
        backgroundColor: '#ECF4F2'
    },
    checkBoxContainer: {
        flex: 2,
        flexDirection: 'row',
        height: 100,
        justifyContent: 'center',
        marginBottom: 10,
    },
    checkBoxContainerAdjust: {
        flex: 2,
        marginLeft: -6,
        flexDirection: 'row',
        height: 100,
        justifyContent: 'center',
        marginBottom: 10,
    },
    boxFile: {
        height: 20,
        backgroundColor: '#efe2f3',
        borderRadius: 5,
        padding: 3,
        flexDirection: 'row'
    },
    containInput: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: heightPercentageToDP('2%'),
    },
    button: {
        padding: heightPercentageToDP('2%'),
        borderRadius: 8,
        marginBottom: heightPercentageToDP('1%'),
        marginTop: heightPercentageToDP('2%'),
        width: widthPercentageToDP('50%'),
        height: heightPercentageToDP('7%'),

    },
    buttonText: {
        fontWeight: 600,
        fontSize: heightPercentageToDP('3%'),
        textAlign: 'center',
        color: '#ffff'
    },
});