import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import Modal from "react-native-modal";
import { StyleSheet, View, TextInput, Image, TouchableOpacity, Text, ActivityIndicator } from 'react-native';

// Retour :
import Head from '../src/components/Head';

import { getUser, setParameters } from '../src/UserManagement';

// Font :
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function ParametersScreen({ navigation, route }) {

  const first = route.params?.first;
  const profileImage = 'ex-profil.png';
  const maxCharactersPseudo = 17;
  const maxCharactersBio = 97;
  const maxCharactersLocation = 27;

  const [second, SetsecondFirstConnexion] = useState([]);
  const [profile, setProfilUser] = useState(null);
  const [pseudo, setPseudo] = useState('');
  const [bio, setBio] = useState('');
  const [location, setLocation] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [morphology, setMorphology] = useState('A');
  const [showModalMensuration, setShowModalMensuration] = useState(false);
  const [user, setUser] = useState([]);
  const [isModalMensuration, setIsModalMensuration] = useState(false);

  // 1 :
  const [ModalFirstConnection, SetModalFirstConnection] = useState(true);
  //2 :
  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  //3 : 
  const [thirdPopupVisible, setthirdPopupVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const closePopup = () => {
    SetModalFirstConnection(false);
    setSecondPopupVisible(true);
  };
  const closePopup2 = () => {
    setSecondPopupVisible(false);
    setthirdPopupVisible(true);
  };
  const closePopup3 = () => {
    setthirdPopupVisible(false);
    //navigation.navigate('Recherche', { second: second });
    navigation.navigate('Main', { second: second });
  };
  const fetchData = () => {
    setLoading(true);
    getUser()
      .then((data) => {
        setLoading(false);
        return data;
      }).catch(error => {
        setLoading(false);
        console.log(error);
      }).then(data => {
        setLoading(false);
        setUser(data.user);
        setProfilUser(data.user.profile_image);
        SetsecondFirstConnexion(data.user.first_connection);
        setPseudo(data.user.pseudo);
        setBio(data.user.biography);
        setLocation(data.user.locate);
        setMorphology(data.user.morphology_calculated);
        if (data.user.suituser === 0) {
          setUserStatus("Suituser")
        } else {
          setUserStatus("Modèle")
        }
        if (data.user.morphology_opinion === null) {
          setShowModalMensuration(true);
        }
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleProfileImageUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.style.display = 'none';

    fileInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          setProfilUser(base64Data);
        };
        reader.readAsDataURL(file);
      }
    });

    fileInput.click();
  };

  function fileToBinary(e) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var binaryString = e.target.result;
      var binaryData = atob(binaryString);
      var newBinaryFiles = [...binaryFiles];
      newBinaryFiles.push(binaryData); // Push binaryData, not binaryString
      setBinaryFiles(newBinaryFiles);
    };
    var res = reader.readAsDataURL(e.target.files[0]); // Use readAsDataURL to read base64 data
  }

  function handleSubmit() {
    let token = localStorage.getItem("sessionToken");

    const newUser = { pseudo: pseudo, biography: bio, token: token, locate: location, profile_image: profile }
    setParameters(newUser)
      .then((data) => {
        return data;
      }).catch(error => {
      }).then(data => {
        //navigation.navigate('Profile')
        window.location.reload()
      })
  }

  function changeStatus() {
    navigation.navigate('SuitUserType')
  }

  function changeMensurations() {
    navigation.navigate('YourInformationOne', {
      previousPage: 'Parameters',
    })
  }

  function handleModalMensurations() {
    setIsModalMensuration(() => !isModalMensuration)
  }

  const morphologyImages = {
    A: require('../assets/iconMorphoA.png').default,
    H: require('../assets/iconMorphoH.png').default,
    X: require('../assets/iconMorphoX.png').default,
    V: require('../assets/iconMorphoV.png').default,
    // Ajoutez d'autres images ici selon les besoins
  };

  const imageSource = morphologyImages[morphology];

  return (
    <View style={styles.container}>

      <Head titre="Paramètres" navigation={navigation} />
      {
        loading ? <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#9002C1" />
          <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
        </View>
          :
          <View>
            <View style={styles.blocParameters}>
              <View style={{ width: '100%', flexDirection: "row", gap: 5, marginTop: 25 }}>

                <View style={{ flexDirection: 'column' }}>
                  <View style={{ borderRadius: 60, overflow: 'hidden', marginLeft: 0 }}>
                    {profile === profileImage ? (
                      <Image style={styles.iconProfile1} source={require('../assets/' + profileImage)} />
                    ) : (
                      <Image source={{ uri: profile }} style={styles.iconProfile1} />)
                    }
                  </View>
                  <TouchableOpacity onPress={handleProfileImageUpload} style={{ flexDirection: 'row', marginTop: -18, marginLeft: 50 }}>
                    <Image style={{ height: 20, width: 20 }} source={require('../assets/modifier.svg')} />
                  </TouchableOpacity>
                </View>

                <View style={{ width: 65, height: 65, marginTop: 10, marginLeft: 20 }}>
                  <Image style={styles.iconProfile2} source={
                    morphology === 'A' ? require('../assets/iconMorphoA.png') :
                      morphology === 'H' ? require('../assets/iconMorphoH.png') :
                        morphology === 'X' ? require('../assets/iconMorphoX.png') :
                          morphology === 'V' ? require('../assets/iconMorphoV.png') :
                            null} />
                </View>

                {first === 1 && (
                  <View style={{ display: 'flex' }}>
                    <TouchableOpacity onPress={closePopup}>
                      <Modal isVisible={ModalFirstConnection} transparent={true} style={{ alignItems: 'flex-end', justifyContent: 'flex-start' }}
                        onRequestClose={() => { SetModalFirstConnection(false); }}>
                        <Image style={{ height: 120, width: 198, marginRight: '1pc', marginTop: 77 }} source={require('../assets/pop-up-tuto-setting1.svg')} />
                      </Modal>
                    </TouchableOpacity>
                  </View>
                )}
                {thirdPopupVisible && (
                  <View style={{ display: 'flex' }}>
                    <TouchableOpacity onPress={closePopup3}>
                      <Modal isVisible={thirdPopupVisible} transparent={true} style={{ alignItems: 'center', justifyContent: 'flex-start' }}
                        onRequestClose={() => { setthirdPopupVisible(false); }}>
                        <Image style={{ height: 178, width: 211, marginTop: '6pc' }} source={require('../assets/pop-up-tuto-setting2.svg')} />
                      </Modal>
                    </TouchableOpacity>
                  </View>
                )}
                {secondPopupVisible && (
                  <View style={{ display: 'flex' }}>
                    <TouchableOpacity onPress={closePopup2}>
                      <Modal isVisible={secondPopupVisible} transparent={true} style={{ alignItems: 'flex-end', justifyContent: 'flex-start' }}
                        onRequestClose={() => { setSecondPopupVisible(false); }}>
                        <Image style={{ height: 142, width: 174, marginRight: '6pc' }} source={require('../assets/pop-up-tuto-setting3.svg')} />
                      </Modal>
                    </TouchableOpacity>
                  </View>
                )}

                <View style={{ display: showModalMensuration }}>
                  <TouchableOpacity onPress={handleModalMensurations} activeOpacity={0.5}
                    style={{ width: 20, HeightfontWeight: 450, height: 20, borderRadius: 40, backgroundColor: '#E4C0F0', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomText style={{ fontSize: 16, fontWeight: 450, color: '#9002C1' }}>{"i"}</CustomText>
                  </TouchableOpacity>
                  <Modal isVisible={isModalMensuration} style={{ alignItems: 'center' }}
                    onRequestClose={() => { setIsModalMensuration(false); }}>
                    <View style={styles.modalMensuration}>
                      <TouchableOpacity activeOpacity={0.5} onPress={handleModalMensurations}>
                        <Image style={{ width: 23.33, height: 27.77, position: "absolute", left: widthPercentageToDP('50%') }}
                          source={require('../assets/icon-close11.svg')} />
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Poids: {user.weight}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Taille: {user.height}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Âge: {user.age}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Poitrine: {user.chest}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Soutien gorge: {user.braN}{user.braL}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Epaules: {user.shoulder}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Tour de taille: {user.waist}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Hanches hautes: {user.high_hip}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Hanches: {user.hip}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Entrejambes: {user.crotch}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Cuisses: {user.thigh}</CustomText>
                        <CustomText style={{ color: "#9002C1", fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Taille de robe: {user.dress}</CustomText>

                      </TouchableOpacity>
                    </View>
                  </Modal>
                </View>

                <View style={{ marginLeft: 10 }}>
                  <Image style={styles.iconProfile} source={require('../assets/iconMorphoA.png')} />
                </View>

              </View>

              <CustomText style={{ marginTop: 10, fontSize: heightPercentageToDP('3%'), fontWeight: 500 }}>Pseudo</CustomText>

              <TextInput style={styles.input}
                placeholderTextColor="gray" placeholder={user.pseudo}
                onChangeText={(text) => {
                  if (text.length <= maxCharactersPseudo) {
                    setPseudo(text);
                  }
                }}
                value={pseudo} maxLength={maxCharactersPseudo}
              />
              <CustomText style={{ alignSelf: 'flex-end', marginRight: 5, color: 'red', fontSize: heightPercentageToDP('2.5%') }}>{pseudo.length}/{maxCharactersPseudo}</CustomText>

              <CustomText style={{ marginTop: 11, fontSize: heightPercentageToDP('3%'), fontWeight: 500 }}>Statut</CustomText>
              <View style={{ flexDirection: 'row', display: 'flex', gap: 30 }}>
                <TouchableOpacity style={styles.button}>
                  <CustomText style={styles.buttonText}>{userStatus}</CustomText>
                </TouchableOpacity>
                <TouchableOpacity onPress={changeStatus}
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <CustomText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 500, color: '#9002C1', marginRight: '0.4pc' }}>Modifier</CustomText>
                  <Image
                    style={styles.iconProfile3}
                    source={require('../assets/modifier.svg')}
                  />
                </TouchableOpacity>
              </View>

              <CustomText style={{ marginTop: 11, fontSize: heightPercentageToDP('3%'), fontWeight: 500 }}>Bio</CustomText>
              <TextInput style={styles.input}
                placeholderTextColor="gray" placeholder={user.biography} numberOfLines={4}
                multiline={true}
                onChangeText={(text) => {
                  if (text.length <= maxCharactersBio) {
                    setBio(text);
                  }
                }}
                value={bio} maxLength={maxCharactersBio}
              />
              <CustomText style={{ alignSelf: 'flex-end', marginRight: 5, color: 'red', fontSize: heightPercentageToDP('2.5%') }}>{bio.length}/{maxCharactersBio}</CustomText>

              <CustomText style={{ marginTop: 11, fontSize: heightPercentageToDP('3%'), fontWeight: 500 }}>Localisation</CustomText>
              <TextInput style={styles.input}
                placeholderTextColor="gray" placeholder={user.locate}
                onChangeText={(text) => {
                  if (text.length <= maxCharactersLocation) {
                    setLocation(text);
                  }
                }}
                value={location} maxLength={maxCharactersLocation}
              />

              <CustomText style={{ alignSelf: 'flex-end', marginRight: 5, color: 'red', fontSize: heightPercentageToDP('2.5%') }}>{location.length}/{maxCharactersLocation}</CustomText>

            </View>

            <TouchableOpacity style={{
              backgroundColor: '#9002C1', padding: 10, borderRadius: 8,
              marginBottom: heightPercentageToDP('10%'), marginTop: 10, width: 160, height: heightPercentageToDP('7%'),
            }}
              onPress={handleSubmit}>
              <CustomText style={styles.buttonText}>{"Mettre à jour"}</CustomText>
            </TouchableOpacity>

            <StatusBar style="auto" />

          </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: widthPercentageToDP('4%'),
    fontSize: heightPercentageToDP('3%'),
    marginBottom: heightPercentageToDP('1.5%'),
    marginTop: heightPercentageToDP('2%'),
    backgroundColor: '#ECF4F2',
  },
  blocParameters: {
    width: widthPercentageToDP('90%'),
    alignContent: 'center',
    //maxHeight: 900,
    backgroundColor: '#FFFFFF',
  },
  iconProfile1: {
    width: 70,
    height: 70,
  },
  iconProfile2: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover'
  },
  iconProfile3: {
    width: 25,
    height: 25
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width: 140,
    height: heightPercentageToDP('7%'),
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff',
  },
  textinput: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  modalMensuration: {
    backgroundColor: '#fff',
    alignItems: 'center',
    width: widthPercentageToDP('90%'),
    borderRadius: 10,
    padding: 10
  },
});