// ProfileUserScreen.js
import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, FlatList, Modal, ScrollView, ActivityIndicator } from 'react-native';
import Config from '../src/Api_Url';
import { Video } from 'expo-av';

// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

// 
import Head from '../src/components/Head';

// Manage :
import { addFollow, deleteFollow, isFollow } from '../src/FollowManagement';
import { getUser, getUserById } from '../src/UserManagement';
import { getMediaPostsByUserId } from '../src/PostManagement';

//partager son profil : 
import { generateShareableURL } from '../src/components/ShareUtils';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function ProfileUserScreen({ route, navigation }) {

  // Navigation :
  const iduser = route.params?.iduser;
  const { pseudo } = route.params;

  //Nombre Post  : 
  const [numPosts, setNumPosts] = useState('');
  const [mediaData, setMediaData] = useState([]);
  const [currentUserId, setCurrentUserId] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);


  // Follow :
  const [textBtnFollow, setTextBtnFollow] = useState("");
  // Utilisateur :
  const [user, setUser] = useState([]);

  const profileImage = 'ex-profil.png';
  const [profile, setProfilUser] = useState(null);
  const [modalVisiblepp, setModalVisiblepp] = useState(false);

  const [loading, setLoading] = useState(false);

  const openModal = () => {
    setModalVisiblepp(true);
  };

  const filterMediaByUniquePostId = (mediaData) => {
    const uniqueMediaDict = {};
    mediaData.forEach(item => {
      const postId = item.post_id;
      if (!uniqueMediaDict[postId]) {
        uniqueMediaDict[postId] = item;
      }
    });
    //console.log(Object.values(uniqueMediaDict))
    return Object.values(uniqueMediaDict);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getUser()
      .then((data) => {
        setCurrentUserId(data.user.id);
        setCurrentUser(data.user);
      })
      .catch(error => {
        console.log(error);
      });
    getUserById(iduser)
      .then((data) => {
        // console.log("Données des user obtenues avec succès :", data);
        setUser(data.user);
        setProfilUser(data.user.profile_image);
      })
      .catch(error => {
        console.log(error);
      });
    getMediaPostsByUserId(iduser)
      .then((data) => {
        const filteredMedia = filterMediaByUniquePostId(data.mediaPosts);
        setMediaData(filteredMedia);
        setNumPosts(filteredMedia.length);
      })
      .catch(error => {
        console.log("Error fetching media:", error);
      });
    let idFlw = iduser;
    let tokenFlr = localStorage.getItem("sessionToken");
    isFollow(idFlw, tokenFlr)
      .then((data) => {
        if (data.error === false) {
          setTextBtnFollow(data.follow ? "Suivi(e)" : "Suivre");
        }
      }).catch(error => {
        console.log(error);
      });
  };

  // //Partager profil : 
  // const handlePublish = () => {
  //   const postId = 'ID_DU_CONTENU_PUBLIE'; // Remplacez par l'ID du contenu réel
  //   const shareableURL = generateShareableURL(postId);
  //   // Enregistrez l'URL générée dans l'état ou dans une variable, puis proposez le partage.
  // };

  // function handleMessage() {
  //   navigation.navigate('Message',{user.id})
  // }

  function handleFollow(idFlw) {
    let tokenFlr = localStorage.getItem("sessionToken");

    if (textBtnFollow === "Suivre") {
      addFollow(idFlw, tokenFlr)
        .then((data) => {
          return data;
        }).catch(error => {
          console.log(error);
        }).then(data => {
          setTextBtnFollow("Suivie");
        })
    } else {
      deleteFollow(idFlw, tokenFlr)
        .then((data) => {
          return data;
        }).catch(error => {
          console.log(error);
        }).then(data => {
          setTextBtnFollow("Suivre");
        })
    }
  }

  const handleReport = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("sessionToken");
      const response = await fetch(`${Config.serverUrl}/api/user/report/` + token, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          userId: user.id,
          userPseudo: user.pseudo,
          userName: user.firstname + ' ' + user.lastname,
          currentUserEmail: currentUser.email,
          currentUserName: currentUser.firstname + ' ' + currentUser.lastname,
          currentUserPseudo: currentUser.pseudo
        })
      });
      setLoading(false);
      const responseData = await response;
      if (responseData.status == 200) alert("Merci d'avoir signaler ce compte")
      else alert(responseData.statusText)
    } catch (error) {
      setLoading(false);
      console.error('Erreur lors de l\'envoi de l\'email:', error);
    }

  };

  function handleFollowers(token, pseudo) {
    navigation.navigate('Followers', { token, pseudo });
  }

  function handleFollows(token) {
    navigation.navigate('Follows', { token });
  }

  //console.log(mediaData)

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Head titre={`SuitUser ${user.pseudo}`} navigation={navigation} />
      <View style={styles.container}>
        <View style={styles.containerDescriptionOne}>
          <View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View>
                <Modal visible={modalVisiblepp} transparent={true} animationType="fade"
                  onRequestClose={() => { setModalVisiblepp(false); }}>
                  <View style={styles.modalBackground}>
                    <View style={styles.modalContainer}>
                      <TouchableOpacity onPress={() => setModalVisiblepp(false)}>
                        <Image style={{ width: 300, height: 300, borderRadius: 111 }}
                          source={profile === profileImage ? require('../assets/' + profileImage) : { uri: profile }}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>

                <View style={{ flexDirection: 'row' }}>
                  <View style={{ padding: 1.8, width: 80, }}>
                    <TouchableOpacity onPress={openModal}>
                      <View style={{ backgroundColor: '#9002C1', padding: 2, borderRadius: 30, width: 61, height: 60 }}>
                        <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                          {profile === profileImage ? (
                            <Image style={{ width: 60, height: 56 }} source={require('../assets/' + profileImage)} />
                          ) : (
                            <Image style={{ width: 60, height: 56 }} source={{ uri: profile }} />)
                          }
                        </View>
                      </View>
                    </TouchableOpacity>
                    <View style={styles.Letters}>
                      <CustomText style={{ fontWeight: 'bold', color: '#9002C1' }}>{user.morphology_calculated}</CustomText>
                    </View>

                    <View style={{ marginLeft: 0, marginTop: 3 }}>
                      <CustomText style={{ fontWeight: 600 }}>{user.pseudo}</CustomText>
                    </View>
                  </View>

                  <View style={styles.infoProfilContainer}>
                    <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                      <View>
                        <CustomText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>{numPosts}</CustomText>
                      </View>
                      <TouchableOpacity>
                        <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('2%') }}>Posts</CustomText>
                      </TouchableOpacity>
                    </View>

                    <TouchableOpacity onPress={() => handleFollowers(user.token)} style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                      <CustomText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>{user.nbr_followers}</CustomText>
                      <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('2%') }}>Followers</CustomText>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => handleFollows(user.token)} style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                      <CustomText style={{ color: '#9002C1', fontWeight: 'bold', fontSize: heightPercentageToDP('3%') }}>{user.nbr_follows}</CustomText>
                      <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('2%') }}>Suivi(e)s</CustomText>
                    </TouchableOpacity>
                  </View>
                </View>
                <View>
                  {/* <View style={styles.Letters}>
                    <Text style={{ color: '#9002C1' }}>{user.morphology_calculated}</Text>
                  </View>
                  <View style={{ marginLeft: 0, marginTop: 3 }}>
                    <CustomText style={{ fontWeight: 600 }}>{user.pseudo}</CustomText>
                  </View> */}
                  <View style={{ display: 'row', flexDirection: 'row', marginTop: 10 }}>
                    <TouchableOpacity onPress={() => {
                      navigation.navigate('Messages',
                        { user1: currentUserId, user2: iduser });
                    }} style={styles.button2}>
                      <CustomText style={styles.buttonText}>Ecrire</CustomText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => handleFollow(iduser)} style={styles.button2}>
                      <CustomText style={styles.buttonText}>{textBtnFollow}</CustomText>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleReport} style={styles.button2}>
                      {loading ? (
                        <ActivityIndicator size="small" color="#ffffff" />
                      ) : (
                        <CustomText style={styles.buttonText}>Signaler</CustomText>
                      )}
                    </TouchableOpacity>
                  </View>
                </View>

              </View>
            </View>
          </View>
        </View>

        <View style={{ width: widthPercentageToDP('90%') }}>
          <CustomText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold', marginTop: heightPercentageToDP('2.5%') }}>Bio</CustomText>
          <CustomText style={{ fontSize: heightPercentageToDP('3%'), marginBottom: '0.6pc', lineHeight: heightPercentageToDP('4%'), marginTop: heightPercentageToDP('2%'), textAlign: 'justify' }}>{user.biography}</CustomText>
          {/* <CustomText style={{fontSize: 15, fontWeight:600,marginBottom:5,marginTop:2}}>Posts</CustomText> */}
        </View>

        <View style={{ borderWidth: 1, borderLeftWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, borderTopColor: '#9002C1', marginBottom: 5 }}>
          <View style={{ width: widthPercentageToDP('90%') }}>
            <View style={{ flexGrow: 1, alignItems: 'flex-start', }}>
              <FlatList data={mediaData.sort((a, b) => b.post_id - a.post_id)}
                numColumns={3} keyExtractor={(item, index) => index.toString()}
                renderItem={({ item, index }) => (
                  <TouchableOpacity onPress={() => {
                    navigation.navigate('PostUserScreen',
                      { postId: item.post_id, iduser: item.user_id });
                  }}>
                    <View style={{ margin: 1, width: widthPercentageToDP('30%') }}>
                      {
                        item?.format?.includes("image") ?
                          <Image style={styles.crushImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                          : item?.format?.includes("video") ?
                            <Video
                            shouldPlay
                              source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                              style={styles.crushImage}
                              useNativeControls
                              resizeMode="cover"
                              /* onReadyForDisplay={videoData => {
                                videoData.srcElement.style.position = "initial"
                              }} */
                            />
                            : <Image style={styles.crushImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                      }
                    </View>
                  </TouchableOpacity>
                )}
              />
            </View>
          </View>
        </View>
        <StatusBar style="auto" />
      </View>
    </ScrollView >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  Letters: {
    width: 18,
    height: 18,
    borderRadius: 15,
    marginTop: '-0.7pc',
    marginLeft: '2.4pc',
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
    //marginRight: 5,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    width: 305,
    borderRadius: 10,
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width: 200,
    height: 40,
    marginLeft: 25,
  },
  button2: {
    backgroundColor: '#FEFEFE',
    borderColor: '#9002C1',
    borderWidth: 2,
    padding: 5,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width: 95,
    height: 40,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText: {
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'center',
    color: '#9002C1',
  },
  containerDescriptionOne: {
    flexDirection: 'row',
    marginTop: heightPercentageToDP('3%')
  },
  infoProfilContainer: {
    flex: 3,
    flexDirection: 'row',
    maxHeight: 60,
    justifyContent: 'space-between',
    marginTop: 10,
  },
  crushImage: {
    width: 113,
    height: 215,
    resizeMode: 'cover',
    aspectRatio: 0.51,
    borderRadius: '0.4pc',
  }
});
