import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Image, Modal, FlatList, ScrollView, Platform, Text, ActivityIndicator } from 'react-native';
import { Video } from 'expo-av';
import SwiperFlatList from 'react-native-swiper-flatlist';

//Info
import Information from '../src/components/i';

// Font : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

//Retour : 
import Head from '../src/components/Head';

// Like
import { like, dislike, getAllLikes } from '../src/LikeManagement';

//Favoris :
import { favorite, disfavored } from '../src/FavoriteManagement';

//Commentaire
import ModalCommentother from '../src/components/Commentother';

//bouton composant :
import Parameters from '../src/components/Parameters/ParametersProfil';
import Like from '../src/components/Like'
import Message from '../src/components/Message';
import Star from '../src/components/Star.js';

//Manage :
import { create, remove } from '../src/CommentManagement';
import { getUserById, getUser } from '../src/UserManagement';
import { addFollow, deleteFollow, isFollow } from '../src/FollowManagement';
import { getMediaPostsByUserId, getListComments } from '../src/PostManagement';

import Config from '../src/Api_Url.js';
import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen';

export default function PostUserScreen({ route, navigation }) {

  // Utilisateur :
  const [user, setUser] = useState([]);

  const [profileuser, setProfilUserVisitor] = useState(null);
  const [profile, setProfilUser] = useState(null);
  const profileImageuser = 'ex-profil.png';

  const [currentUserId, setCurrentUserId] = useState([]);

  //Description d'un poste : 
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Commentaire : 
  const [comment, setComment] = useState([]);
  const [commentaire, SetAddCommentaire] = useState([]);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [isCommentListEmpty, setIsCommentListEmpty] = useState(false);
  const [commentsAndReplies, setCommentsAndReplies] = useState([]);
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  //Info description : 
  const [isModalInfo, setIsModalInfo] = useState(false);
  const valueOnChange = (newValue) => {
    ///console.log("Nouvelle valeur du curseur :", newValue);
  };

  // Slide :
  const [statusIcon, setStatusIcon] = useState([]);
  const [slideImageIndices, setSlideImageIndices] = useState([]);
  const [filteredMediaData, setFilteredMediaData] = useState([]);
  const [cursor, setCursor] = useState(0); // Add cursor state

  const [loading, setLoading] = useState(false);

  // Navigation :
  const postId = route.params?.postId;
  const iduser = route.params?.iduser;

  // Follow :
  const [textBtnFollow, setTextBtnFollow] = useState("");

  // Total likes : 
  const [lik, SetAllLikes] = useState([]);

  // Data :
  const fetchData = (postId) => {
    setLoading(true);
    getMediaPostsByUserId(iduser)
      .then((data) => {
        // Formatez la date
        const formattedPosts = data.mediaPosts.map((post) => {
          const createdAt = post.created_date;
          const dateObj = new Date(createdAt);
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          post.created_date = dateObj.toLocaleDateString('fr-FR', options);
          return post;
        });
        const filteredData = formattedPosts.filter(item => item.post_id === postId);
        setFilteredMediaData(filteredData);
        setStatusIcon(Array(filteredData.length).fill(false));
        setSlideImageIndices(Array(filteredData.length).fill(0));

        filteredData.forEach((post) => {
          let tokenFlr = localStorage.getItem("sessionToken");
          const idFlw = post.user_id;
          isFollow(idFlw, tokenFlr)
            .then((followData) => {
              if (followData.error === false) {
                setTextBtnFollow(followData.follow ? "Se désabonner" : "S'abonner");
                //console.log(`L'user ${idFlw} est suivie ? ${followData.follow}`);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log("Error fetching media:", error);
      });


    getUserById(iduser)
      .then((data) => {
        setUser(data.user);
        setProfilUser(data.user.profile_image);
      })
      .catch(error => {
        console.log(error);
      });
    getUser()
      .then((data) => {
        setCurrentUserId(data.user.id);
        setProfilUserVisitor(data.user.profile_image);
      })
      .catch(error => {
        console.log(error);
      });
    getAllLikes()
      .then((data) => {
        //console.log('likeposte =', data.Likesbyposts);
        SetAllLikes(data.Likesbyposts);
      })
      .catch(error => {
        console.log(error);
      });
    getListComments(postId)
      .then((data) => {
        const formattedComments = data.comments.map((comment) => {
          const createdAt = comment.created_date;
          const dateObj = new Date(createdAt);
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          comment.created_date = dateObj.toLocaleDateString('fr-FR', options);
          return comment;
        });

        // Stockez tous les commentaires dans le state "comment"
        setComment(formattedComments);

        // Vous pouvez également mettre à jour setIsCommentListEmpty ici
        setIsCommentListEmpty(formattedComments.length === 0);
      })
      .catch((error) => {
        console.log("Erreur lors de la récupération des commentaires:", error);
      });
  };
  useEffect(() => {
    fetchData(postId);
  }, []);

  useEffect(() => {
    const combinedCommentsAndReplies = combineCommentsAndReplies(comment);
    setCommentsAndReplies(combinedCommentsAndReplies);
  }, [comment]);

  const Dolike = (postId) => {
    const userId = currentUserId;

    const postData = {
      user_id: userId,
      post_id: postId,
    };

    like(postData)
      .then((data) => {
        if (data.success) {
          //console.log("Crush créé avec succès");
        } else {
          //console.log("Crush créé avec succès");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Nolike = (postId) => {
    const userId = currentUserId;

    const postData = {
      user_id: userId,
      post_id: postId,
    };

    dislike(postData)
      .then((data) => {
        if (data.success) {
          //console.log("Crush créé avec succès");
        } else {
          //console.log("Crush créé avec succès");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Crush postes : 
  const Docrush = (postId) => {

    const userId = currentUserId;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    // console.log("User ID:", userId);
    // console.log("Bouton 'crush' appuyé pour le post avec l'ID:", postId);

    favorite(postData)
      .then((data) => {
        if (data.success) {
          // console.log("Crush créé avec succès");
        } else {
          //console.error("Échec de la création du crush");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Nocrush = (postId) => {

    const userId = currentUserId;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    // console.log("User ID:", userId);
    // console.log("Bouton 'uncrush' appuyé pour le post avec l'ID:", postId);

    disfavored(postData)
      .then((data) => {
        if (data.success) {
          // console.log("Unlike effectué avec succès");
        } else {
          console.error("Échec de uncrush");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [ModalFirstConnection, SetModalFirstConnection] = useState(true);
  const closePopup = () => {
    SetModalFirstConnection(false);
  };

  // Fonction pour combiner les commentaires et les réponses
  function combineCommentsAndReplies(comments) {
    const commentsAndReplies = {};
    comments.forEach((commentItem) => {
      const parentId = commentItem.answer_id || commentItem.id;
      const existingComment = commentsAndReplies[parentId];
      if (!existingComment) {
        commentsAndReplies[parentId] = {
          ...commentItem,
          replies: [],
        };
      } else {
        // Si le commentaire parent existe, ajoutez la réponse au parent ou créez un tableau de réponses s'il n'existe pas
        if (!existingComment.replies) {
          existingComment.replies = [];
        }
        existingComment.replies.push(commentItem);
      }
    });
    // Convertir l'objet en un tableau pour le rendre compatible avec votre affichage
    const result = Object.values(commentsAndReplies);
    // console.log('Comments and Replies :', result);
    return result;
  }

  function handleDeleteComment(commentId) {
    try {
      // Supprimez le commentaire
      remove(commentId); // Supposons que cette fonction ne soit pas asynchrone
      // Après avoir supprimé le commentaire, mettez à jour la liste des commentaires
      const updatedComments = comment.filter(commentItem => commentItem.id !== commentId);
      setComment(updatedComments);
    } catch (error) {
      // Gérer les erreurs si la suppression côté serveur a échoué
      console.error("Erreur lors de la suppression du commentaire :", error);
    }
  }

  // Ajouter un  commentaire
  function addComment() {
    const newComment = {
      body: commentaire,
      user_id: currentUserId,
      id_post: postId,
    };

    if (replyToCommentId !== null) {
      newComment.answer_id = replyToCommentId; // Ajoutez l'ID du commentaire auquel vous répondez
    }

    create(newComment, postId, replyToCommentId)
      .then((data) => {
        if (data.error) {
          console.error("Erreur lors de la création du commentaire :", data.message);
        } else {
          // console.log("Commentaire ajouté avec succès !");
          SetAddCommentaire(''); // Réinitialisez le commentaire principal
          setReplyToCommentId(null); // Réinitialisez l'ID du commentaire auquel vous répondez
          fetchData(postId); // Rechargez les commentaires pour afficher le nouveau commentaire
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête d'ajout de commentaire :", error);
      });
  }

  // Follow statut : 
  function handleFollow(idFlw) {
    let tokenFlr = localStorage.getItem("sessionToken");

    if (textBtnFollow === "S'abonner") {
      addFollow(idFlw, tokenFlr)
        .then((data) => {
          return data;
        }).catch(error => {
          console.log(error);
        }).then(data => {
          setTextBtnFollow("Se désabonner");
        })
    } else {
      deleteFollow(idFlw, tokenFlr)
        .then((data) => {
          return data;
        }).catch(error => {
          console.log(error);
        }).then(data => {
          setTextBtnFollow("S'abonner");
        })
    }
  }

  // Slide :
  const selectPrevious = (index) => {
    if (cursor > 0 && slideImageIndices[cursor] > 0) {
      const newIndices = [...slideImageIndices];
      newIndices[cursor] -= 1;
      setSlideImageIndices(newIndices);
    } else if (cursor > 0) {
      setCursor(cursor - 1);
      const newIndices = [...slideImageIndices];
      newIndices[cursor - 1] = 0;
      setSlideImageIndices(newIndices);
    }
  };
  const selectNext = (index) => {
    if (cursor < filteredMediaData.length - 1) {
      setCursor(cursor + 1);
      const newIndices = [...slideImageIndices];
      newIndices[cursor + 1] = 0;
      setSlideImageIndices(newIndices);
    }
  };
  function handleReplyToComment(commentId) {
    setReplyToCommentId(commentId);

    // find commentaire = à son ID
    const commentToReply = commentsAndReplies.find(comment => comment.id === commentId);
    // Màj le champ de texte pour le commentaire principal
    if (commentToReply) {
      SetAddCommentaire(`@${commentToReply.pseudo}`);
    }
  }
  //ouverture info slide
  function handleModalInfo() {
    setIsModalInfo(() => !isModalInfo)
  }

  function getLikeCountByPostId(postId) {
    const likeData = lik.find((like) => like.post_id === postId);
    return likeData ? likeData.like_count : 0;
  }

  const handleShareProfileLink = async ({ userId }) => {
    /* try {
      await Share.share({
        message: window.location.href + '/' + userId
      });
    } catch (error) {
      console.error('Error sharing:', error);
    } */
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Lien du profil',
          text: 'Bien vouloir consulter ce profil :',
          url: window.location.href + userId,
        });
      } catch (error) {
        alert('Error sharing:', error);
      }
    } else {
      alert(
        "Partage non pris en charge",
        "L'API Web Share n'est pas prise en charge dans votre navigateur. Veuillez copier et partager le lien manuellement.",
        [{ text: 'Copy Link', onPress: () => copyToClipboard(window.location.href + userId) }]
      );
    }
  };

  const copyToClipboard = (text) => {
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(text).then(() => {
        Alert.alert('Link copied to clipboard');
      });
    } else {
      // Pour les environnements natifs, utiliser un module de copie comme expo-clipboard
    }
  };

  return (

    <View style={styles.container}>
      <Head titre={`Posts ${user.pseudo}`} navigation={navigation} />
      {/* <Modal isVisible={ModalFirstConnection} transparent={true} style={{alignItems:'center',justifyContent: 'center'}}>
        <TouchableOpacity onPress={closePopup}>
          <View style={{ display:'flex',alignItems:'center',justifyContent: 'center',marginTop:'19pc',marginLeft:'4pc',width:257 ,borderRadius:20}}>
            <View style={{backgroundColor:'#D9E9E6',padding:6}}>
              <CustomText style={{color:'#9002C1',textAlign:'center'}}>
                Connectez-vous ou créez un compte pour accéder à l'application.
                Saisissez vos identifiants ou inscrivez-vous.
              </CustomText>
              <View style={{flexDirection:'row',justifyContent:'space-evenly',marginTop:20}}>
                <TouchableOpacity onPress={()=> navigation.navigate('Register')}>
                  <CustomText style={{color:'#9002C1'}}>S'inscricre</CustomText>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=> navigation.navigate('Login')}>
                  <CustomText style={{color:'#9002C1'}}>Se connecter</CustomText>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          </TouchableOpacity>
        </Modal> */}
      {loading && <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#9002C1" />
        <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
      </View>
      }

      <FlatList
        data={filteredMediaData}
        renderItem={({ item, index }) => (
          <View key={index}>
            {index === cursor && (
              <View key={index}>
                <View style={styles.containerMorphologies}>
                  <View style={{ borderWidth: 1, borderColor: '#C881E0', borderRadius: 25, padding: 5, width: '100%' }}>
                    <View style={{ width: widthPercentageToDP('90%'), height: '100%' }}>
                      <View style={styles.containerProfile}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: '0.33pc', marginLeft: 5 }}>
                          <View style={{ backgroundColor: '#9002C1', padding: 2, borderRadius: 30, width: 61, height: 60 }}>
                            <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                              {profile === profileImageuser ? (
                                <Image style={{ width: 60, height: 56, }} source={require('../assets/' + profileImageuser)} />
                              ) : (
                                <Image source={{ uri: profile }} style={{ width: 60, height: 56, }} />)
                              }
                            </View>
                          </View>
                          <View style={styles.Letters}>
                            <CustomText style={{ fontWeight: 'bold', color: '#9002C1' }}>{user.morphology_calculated}</CustomText>
                          </View>
                          <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={{ marginLeft: 10 }}>
                              <CustomText style={{ fontWeight: 600, width: 86 }}>{user.pseudo}</CustomText>
                            </View>
                            <View style={{ marginLeft: 10 }}>
                              <TouchableOpacity style={{ height: 26, alignItems: "center", justifyContent: "center", backgroundColor: "#9002C1", borderRadius: 4 }} activeOpacity={0.5}
                                onPress={() => handleFollow(item.user_id)}>
                                <CustomText style={{ color: "white", fontWeight: "bold", padding: 2 }}>{textBtnFollow}</CustomText>
                              </TouchableOpacity>
                            </View>
                            <View style={{ marginLeft: 10 }}>
                              <TouchableOpacity onPress={() => handleShareProfileLink({ userId: user.id })}>
                                <Parameters />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                      {/* date publication */}
                      <View style={{ marginTop: '2pc' }}>
                        <CustomText style={{ fontWeight: 200, opacity: '0.8', fontSize: heightPercentageToDP('2%'), marginTop: heightPercentageToDP('2%'), marginBottom: heightPercentageToDP('2%'), marginLeft: '0.8pc' }}>{item.created_date}</CustomText>
                      </View>

                      {/* <View style={{ width: widthPercentageToDP('100%') }}>
                        {
                          item?.media_format?.includes("image") ?
                            <Image style={styles.postImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                            : item?.media_format?.includes("video") ?
                              <Video
                                source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                                style={styles.postImage}
                                useNativeControls
                                resizeMode="contain"
                              />
                              : <Image style={styles.postImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                        }
                      </View> */}
                      <View>
                        <ScrollView
                          horizontal
                          pagingEnabled
                          showsHorizontalScrollIndicator={true}
                          style={{ width: widthPercentageToDP('100%') }}
                        >
                          {filteredMediaData.map((item, index) => (
                            <View key={index} style={{ border: "1px solid black", width: widthPercentageToDP('100%') }}>
                              {
                                item?.format?.includes("image") ?
                                  <Image style={styles.postImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                                  : item?.format?.includes("video") ?
                                    <Video
                                    shouldPlay
                                      source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                                      style={styles.postImage}
                                      useNativeControls
                                      resizeMode="cover"
                                    /* onReadyForDisplay={videoData => {
                                      videoData.srcElement.style.position = "initial"
                                    }} */
                                    />
                                    : <Image style={styles.postImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                              }
                            </View>
                          ))}
                        </ScrollView>
                      </View>
                      <View style={styles.dotsContainer}>
                        {filteredMediaData.map((_, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => setCursor(index)}
                            style={[
                              styles.dot,
                              index === cursor ? styles.activeDot : styles.inactiveDot,
                            ]}
                          />
                        ))}
                      </View>
                      <TouchableOpacity activeOpacity={0.5} onPress={() => handleModalInfo(index)}>
                        <Image style={styles.iconLogo} source={require('../assets/i-letter.svg')} />
                      </TouchableOpacity>

                      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 2, position: 'absolute', top: '50%', }}>
                        <TouchableOpacity activeOpacity={0.5} onPress={() => selectPrevious(index)} style={{ marginLeft: 17 }}>
                          <Image style={{ width: 30, height: 25 }} />
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.5} onPress={() => selectNext(index)} style={{ marginRight: 0, marginLeft: 220 }}>
                          <Image style={{ width: 30, height: 25 }} />
                        </TouchableOpacity>
                      </View>
                      <View style={{ flexDirection: 'column', marginTop: '0.6pc', marginLeft: '0.6pc' }}>

                        <View style={{ flexDirection: 'row', marginLeft: 4, marginTop: heightPercentageToDP('2%') }}>
                          <BoldText style={{ marginRight: 4, fontSize: heightPercentageToDP('2%') }}>{getLikeCountByPostId(item.id)}</BoldText>
                          <BoldText style={{ fontSize: heightPercentageToDP('2%') }}>Suit'Likes</BoldText>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                          <View style={{ flexDirection: 'row' }}>
                            <CustomText >
                              <CustomText style={{ fontWeight: 'bold', marginRight: '0.8pc' }}> {user.pseudo}</CustomText>
                              {showFullDescription ? item.title : (item.description.length > 15 ? item.title.substring(0, 20) : item.title)}
                            </CustomText>
                            {!showFullDescription && item.title.length > 20 && (
                              <TouchableOpacity onPress={() => setShowFullDescription(true)}>
                                <CustomText style={{ color: '#C881E0' }}> ...Plus</CustomText>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      </View>

                      {/* Description bloc */}
                      <View style={styles.descriptionContainer}>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.type}</CustomText>
                        </View>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.brand}</CustomText>
                        </View>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.size}</CustomText>
                        </View>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.adjustment}</CustomText>
                        </View>
                      </View>
                    </View>
                    {/* Information */}
                    <View>
                      <TouchableOpacity activeOpacity={0.5} onPress={() => setIsModalInfo(false)}>
                        <Modal visible={isModalInfo} animationType="fade" transparent={true} onRequestClose={() => setIsModalInfo(false)}>
                          <View style={styles.modalBackground}>
                            <View style={styles.modalContainer}>

                              <Information
                                initiaTexture={item.texture}
                                initialThickness={item.thickness}
                                initialFluidity={item.fluidity}
                                initialComfort={item.comfort}
                                initialColor={item.color}
                                valueOnChange={valueOnChange}
                              />
                            </View>
                          </View>
                        </Modal>
                      </TouchableOpacity>
                    </View>
                    {/* Box interaction Utilisateur */}
                    <View style={styles.iconsContainer}>
                      <View style={styles.iconsback}>
                        <View >
                          <Like likePost={Dolike} nolikePost={Nolike} postId={item.id} userId={currentUserId} />
                        </View>
                        <View >
                          <TouchableOpacity onPress={() => setShowCommentPopup(true)}>
                            <Message />
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginBottom: 2 }}>
                          <Star CrushPost={Docrush} NoCrushPost={Nocrush} postId={item.id} userId={currentUserId} />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
        pagingEnabled
      />

      <ModalCommentother
        showCommentPopup={showCommentPopup}
        setShowCommentPopup={setShowCommentPopup}
        isCommentListEmpty={isCommentListEmpty}
        commentsAndReplies={commentsAndReplies}
        handleDeleteComment={handleDeleteComment}
        addComment={addComment}
        profileuser={profileuser}
        profile={profile}
        profileImageuser={profileImageuser}
        commentaire={commentaire}
        SetAddCommentaire={SetAddCommentaire}
        handleReplyToComment={handleReplyToComment}
        currentUserId={currentUserId}
        postId={postId}
      />

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  containerProfile: {
    flex: 2,
    flexDirection: 'column',
    maxHeight: 40,
    padding: 2,
    //width: widthPercentageToDP('90%'),
  },
  Letters: {
    width: 18,
    height: 18,
    marginLeft: '-1pc',
    marginTop: '2pc',
    borderRadius: 15,
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProfile: {
    width: 50,
    height: 50,
  },
  postconteneur: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  containerMorphologies: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 6,
  },
  iconLogo: {
    width: 17,
    height: 17,
    marginTop: -61,
    marginLeft: 30,
  },
  postImage: {
    height: 360,
    width: '100%',
    resizeMode: 'cover',
    borderRadius: 2,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    justifyContent: 'center',
    alignItems: 'center',
    width: 305,
    borderRadius: 10,
  },
  dotsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
  },
  activeDot: {
    backgroundColor: '#9002C1',
  },
  inactiveDot: {
    backgroundColor: '#E4C0F0',
  },
  descriptionContainer: {
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    //justifyContent: 'center',
    width: widthPercentageToDP('90%'),
    marginBottom: 11,
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: 10,
  },
  descriptionBloc: {
    height: 19,
    flexWrap: 'wrap',
    backgroundColor: '#ECF4F2',
    minWidth: 50,
    margin: 2,
    borderRadius: 10,
    marginLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#D9E9E6',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  //Box intéraction :
  iconsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderRadius: 5,
    marginLeft: 150,
    marginBottom: -7,
    marginTop: -68,
  },
  iconsback: {
    backgroundColor: '#debee5',
    borderWidth: 2,
    borderColor: '#debee5',
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 22,
    padding: 5,
    marginRight: -5,
    width: 30,
    justifyContent: 'space-between',
    height: '117%',
    borderRadius: 5,
    flexDirection: 'column',
  },

});
