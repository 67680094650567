import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, Text, TextInput, View, Button, Image, TouchableOpacity } from 'react-native';
import { resetPassword } from '../src/UserManagement';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function ResetPasswordScreen({ route, navigation }) {

  // const { email } = route.params;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  //Afficher message correspond pas :
  const [message, setMessage] = useState(''); // État pour le message


  function checkPassword() {
    if (password === confirmPassword) {
      return (true);
    } else {
      return (false);
    }
  }

  function handleSubmit() {
    if (checkPassword() === false) {
      setMessage("Les mots de passe ne sont pas les mêmes");
      return;
    }

    let user = { email: email, password: password };

    resetPassword(user)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('Login');
        }
      })
  }

  return (
    <View style={styles.container}>
      <Image style={{ width: 118, height: 90, marginTop: '3vh' }} source={require('../assets/logoSUITUSviolet.png')} />
      <CustomText style={{ marginTop: heightPercentageToDP('5%'), marginBottom: 20, textTransform: 'uppercase', fontSize: heightPercentageToDP('3%'), textAlign: 'center' }}>Je réinitialise mon mot de passe</CustomText>
      <TextInput style={styles.textinput}
        placeholder="Mot de passe"
        placeholderTextColor="gray"
        secureTextEntry={true}
        onChangeText={setPassword}
      />
      <TextInput style={styles.textinput}
        placeholder="Confirmer mot de passe"
        placeholderTextColor="gray"
        secureTextEntry={true}
        onChangeText={setConfirmPassword}
      />

      <BoldText style={{ textTransform: 'uppercase', color: "#9002C1", padding: 8, fontSize: 10 }}>{message}</BoldText>

      <TouchableOpacity style={styles.button} onPress={handleSubmit}>
        <Text style={styles.buttonText}>{"VALIDATION"}</Text>
      </TouchableOpacity>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    padding: widthPercentageToDP('4%'),
  },
  textinput: {
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: 10,
    fontSize: heightPercentageToDP('3%'),
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: '#ECF4F2',
    width: widthPercentageToDP('90%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: heightPercentageToDP('2%'),
    borderRadius: 8,
    marginBottom: heightPercentageToDP('1%'),
    width: widthPercentageToDP('50%'),
    height: heightPercentageToDP('7%'),
  },
  buttonText: {
    fontWeight: '600',
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff',
  },
});