import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Image, Text } from 'react-native';
import Like from '../src/components/Like'
import Message from '../src/components/Message';
import Star from '../src/components/Star.js';
import Parameters from '../src/components/Parameters/Parameters';
import Modal from "react-native-modal";
import { addFollow, deleteFollow, isFollow } from '../src/FollowManagement';
import BottomBar from '../src/components/BottomBar';
import HeaderRouter from '../src/components/HeaderRouter';
import DropDown from '../src/components/Dropdown';
import { getListDressSizes } from '../src/dropdowns/DressSizesManagement';
import { getListTypes } from '../src/dropdowns/TypesManagement';
import { getListBrands } from '../src/dropdowns/BrandsManagement';
import { getFavoritePosts } from '../src/PostManagement';
import { widthPercentageToDP } from 'react-native-responsive-screen';

//test visu fléche slide
// import leftArrowImage from '../assets/🦆 icon _arrow down_left.png'; source={leftArrowImage} 
// import rightArrowImage from '../assets/🦆 icon _arrow down_.png'; source={rightArrowImage}

export default function CrushScreen({ route, navigation }) {

  const { imageUrl } = route.params;
  const [textBtnFollow, setTextBtnFollow] = useState(""); // Déclaration d'état
  const [isModalInfo, setIsModalInfo] = useState(false); // Déclaration d'état
  const [favorite, setFavoritePosts] = useState([]);
  //slide
  const [statusIcon, setStatusIcon] = useState([true, false, false]);
  const [cursor, setCursor] = useState(0);

  const [DDLDressValue, setDDLDressValue] = useState(38);
  const [DDLDressItems, setDDLDressItems] = useState([]);
  const [DDLTypesValue, setDDLTypesValue] = useState('robe');
  const [DDLTypesItems, setDDLTypesItems] = useState([]);
  const [DDLBrandsValue, setDDLBrandsValue] = useState('Habillez-moi');
  const [DDLBrandsItems, setDDLBrandsItems] = useState([]);

  //commentaire : 
  const [expanded, setExpanded] = useState(false);
  const commentText = "Nouveau Habits testez le il est juste wow !";
  const maxLength = 20;

  const fetchData = () => {
    // Fetching Dress Sizes, Types, and Brands
    getListDressSizes()
      .then((data) => {
        setDDLDressItems(data.dresssizes);
      }).catch(error => {
        console.log(error);
      });
    getListTypes()
      .then((data) => {
        setDDLTypesItems(data.types);
      }).catch(error => {
        console.log(error);
      });
    getListBrands()
      .then((data) => {
        setDDLBrandsItems(data.brands);
      }).catch(error => {
        console.log(error);
      });

    getFavoritePosts()
      .then((data) => {
        setFavoritePosts(data);
      });

    // Checking Follow status
    let idFlw = 67;
    let tokenFlr = localStorage.getItem("sessionToken");
    isFollow(idFlw, tokenFlr)
      .then((data) => {
        if (data.error === false) {
          setTextBtnFollow(data.follow ? "Se désabonner" : "S'abonner");
        }
      }).catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onSelectDress = (item) => {
    setDDLDressValue(item);
  }

  const onSelectTypes = (item) => {
    setDDLTypesValue(item);
  }

  const onSelectBrands = (item) => {
    setDDLBrandsValue(item);
  }

  //slide
  function selectPrevious() {
    if (cursor - 1 >= 0) {
      const newStatusIcon = [...statusIcon];
      newStatusIcon[cursor] = false;
      newStatusIcon[cursor - 1] = true;
      setCursor(cursor - 1);
      setStatusIcon(newStatusIcon);
    }
  }

  function selectNext() {
    if (cursor + 1 < statusIcon.length) {
      const newStatusIcon = [...statusIcon];
      newStatusIcon[cursor] = false;
      newStatusIcon[cursor + 1] = true;
      setCursor(cursor + 1);
      setStatusIcon(newStatusIcon);
    }
  }

  function handleFollow() {
    let idFlw = 67;
    let tokenFlr = localStorage.getItem("sessionToken");

    if (textBtnFollow === "S'abonner") {
      addFollow(idFlw, tokenFlr)
        .then((data) => {
          return data;
        }).catch(error => {
          console.log(error);
        }).then(data => {
          setTextBtnFollow("Se désabonner");
        })
    } else {
      deleteFollow(idFlw, tokenFlr)
        .then((data) => {
          return data;
        }).catch(error => {
          console.log(error);
        }).then(data => {
          setTextBtnFollow("S'abonner");
        })
    }
  }

  function handleModalInfo() {
    setIsModalInfo(() => !isModalInfo)
  }

  return (

    <View style={styles.container}>
      <HeaderRouter navigation={navigation} />
      <View style={styles.containInput}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ marginRight: 6.2 }}>
            <DropDown data={DDLDressItems} value={DDLDressValue} onSelect={onSelectDress} style={{ width: widthPercentageToDP('30%') }} />
          </View>
          <View style={{ marginRight: 6.2 }}>
            <DropDown data={DDLTypesItems} value={DDLTypesValue} onSelect={onSelectTypes} style={{ width: widthPercentageToDP('30%') }} />
          </View>
          <View style={{ marginRight: 6.2 }}>
            <DropDown data={DDLBrandsItems} value={DDLBrandsValue} onSelect={onSelectBrands} style={{ width: widthPercentageToDP('30%') }} />
          </View>
        </View>
      </View>
      <View style={{ borderWidth: 2, borderColor: '#E4C0F0', borderRadius: 25, padding: 5, width: 355 }}>
        <View style={styles.containerProfile}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View>
              <Image style={styles.iconProfile} source={require('../assets/ex-profil.png')} />
            </View>
            <View style={styles.Letters}>
              <Text style={{ fontWeight: 'bold', color: 'purple' }}>H</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ marginLeft: '10px' }}>
                <Text>Liyan</Text>
              </View>
              <View style={{ marginLeft: "10px" }}>
                <TouchableOpacity style={{ width: '110px', height: 25, alignItems: "center", justifyContent: "center", backgroundColor: "#9002C1", borderRadius: 4 }} activeOpacity={0.5} onPress={handleFollow}>
                  <Text style={{ color: "white", fontWeight: "bold" }}>{textBtnFollow}</Text>
                </TouchableOpacity>
              </View>
              <View style={{ marginLeft: 100 }}>
                <Parameters />
              </View>
            </View>
          </View>
          <View>
            <Text style={{ fontWeight: '600', fontSize: 11 }}>11 février 2023</Text>
          </View>
        </View>
        <View style={styles.imageContainer}>
          <View style={styles.containerMorphologies}>
            <View style={{ zIndex: 2, position: 'absolute', top: '50%', marginLeft: '-20pc' }}>
              <TouchableOpacity activeOpacity={0.5} onPress={selectPrevious}>
                <Image style={{ width: 20, height: 25 }} />
              </TouchableOpacity>
            </View>
           
            <View style={{ zIndex: 2, position: 'absolute', top: '50%', marginRight: '-20pc' }}>
              <TouchableOpacity activeOpacity={0.5} onPress={selectNext}>
                <Image style={{ width: 20, height: 25 }} />
              </TouchableOpacity>
            </View>
          </View>
          {/* Infos morphologie user */}
          <TouchableOpacity activeOpacity={0.5} onPress={handleModalInfo}>
            <Image style={styles.iconLogo} source={require('../assets/i-letter.svg')} />
          </TouchableOpacity>
          <Modal isVisible={isModalInfo}
            onRequestClose={() => { setIsModalInfo(false); }}>
            <View style={styles.modalInfo}>
              <TouchableOpacity activeOpacity={0.5} onPress={handleModalInfo}>
                <Image style={{ width: 10, height: 10, position: "absolute", left: 127, top: -12 }} source={require('../assets/icon-close.svg')} />
                <Text>Doux [O] --------- Rèche</Text>
                <Text>Différent [O] --------- Fidèle</Text>
                <Text>Fin [O] --------- Epais</Text>
                <Text>Souple[O] --------- Droit</Text>
                <Text>+ [O] --------- ++</Text>
              </TouchableOpacity>
            </View>
          </Modal>
        </View>

        {/* Box interaction Utilisateur */}
        <View style={styles.iconsContainer}>
          <View style={styles.iconsback}>
            <View style={{ marginLeft: 7 }}>
              <Like />
            </View>
            <View style={{ marginLeft: 7 }}>
              <Message />
            </View>
            <View style={{ marginLeft: 7 }}>
              <Star />
            </View>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Text style={{ fontWeight: 'bold', marginLeft: 1, marginRight: 5, marginTop: '-4pc' }}>Noamodist</Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-3pc', marginLeft: 1, }}>
          {expanded ? (
            <Text style={{ width: 300 }}>{commentText}</Text>
          ) : (
            <Text>{commentText.substring(0, maxLength)}...</Text>
          )}
          {!expanded && commentText.length > maxLength && (
            <TouchableOpacity onPress={() => setExpanded(true)}>
              <Text style={{ color: '#C881E0', marginLeft: 5 }}>Plus</Text>
            </TouchableOpacity>)}
        </View>
        <View style={styles.descriptionContainer}>

          <View style={styles.descriptionBloc}>
            <Text style={{ color: '#9002C1' }}>Robe</Text>
          </View>
          <View style={styles.descriptionBloc}>
            <Text style={{ color: '#9002C1' }}>Habillez-moi</Text>
          </View>
          <View style={styles.descriptionBloc}>
            <Text style={{ color: '#9002C1' }}>42</Text>
          </View>
          <View style={styles.descriptionBloc}>
            <Text style={{ color: '#9002C1' }}>Ajusté</Text>
          </View>
        </View>
      </View>

      <BottomBar navigation={navigation} />
      <StatusBar style="auto" />
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containInput: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '8px',
  },
  containerProfile: {
    flex: 2,
    flexDirection: 'column',
    maxHeight: 40,
    padding: 2,
    width: 350,
  },
  Letters: {
    width: 18,
    height: 18,
    marginLeft: '-1pc',
    marginTop: '2pc',
    borderRadius: 15,
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProfile: {
    width: 50,
    height: 50,
  },
  imageContainer: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  containerMorphologies: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 450,
    flexDirection: 'row',
    marginTop: '6px',
  },

  crushImage: {
    width: 350,
    height: 400,
    resizeMode: 'contain',
    marginTop: '3pc',
    marginLeft: '-2pc',
    marginRight: '-2pc',
    borderRadius: '2pc',
  },
  iconsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderRadius: 5,
    marginLeft: 150,
  },
  iconsback: {
    backgroundColor: '#debee5',
    borderWidth: 2,
    borderColor: '#debee5',
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 5,
    padding: 5,
    marginRight: -5,
    width: 30,
    justifyContent: 'space-between',
    height: 80,
    borderRadius: 5,
    flexDirection: 'column',

  },
  iconLogo: {
    width: 15,
    height: 15,
    marginTop: -50,
    marginLeft: -130,
  },
  descriptionContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: '10px',
  },
  descriptionBloc: {
    height: 19,
    backgroundColor: '#D9E9E6',
    minWidth: 50,
    borderRadius: 10,
    marginLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#B3D2CC',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  descContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: '10px',
  },
  modalInfo: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width: 170,
    height: 250,
    left: 780,
  },
  dotsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },

  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
  },

  activeDot: {
    backgroundColor: '#9002C1',
  },

  inactiveDot: {
    backgroundColor: '#E4C0F0',
  },

});
