import React, { useState } from 'react';
import { StyleSheet, TextInput, View, Image, useWindowDimensions, ActivityIndicator } from 'react-native';
import { login } from '../src/UserManagement';
import { TouchableOpacity } from 'react-native-gesture-handler';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

// Responsive screen
import { widthPercentageToDP as wp, heightPercentageToDP as hp, heightPercentageToDP } from 'react-native-responsive-screen';

export default function LoginScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleSubmit() {
    let user = { email: email, password: password };
    setLoading(true);
    login(user)
      .then((data) => {
        if (data.error === false) {
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  }

  return (
    <View style={styles.container}>
      <Image style={{ width: 118, height: 90, marginTop: '3vh' }} source={require('../assets/logoSUITUSviolet.png')} />
      <View style={styles.formContainer}>
        <View style={styles.contain}>
          <TextInput
            style={styles.input}
            placeholderTextColor="gray"
            placeholder="Email"
            onChangeText={setEmail}
          />
        </View>
        <View style={styles.contain}>
          <TextInput
            style={styles.input}
            placeholder="Mot de passe"
            placeholderTextColor="gray"
            secureTextEntry={!showPassword}
            onChangeText={setPassword}
          />
          <View style={styles.toggleButton}>
            <TouchableOpacity onPress={togglePasswordVisibility}>
            {showPassword ? (
                <Image style={{ height: 10, width: 17, }} source={require('../assets/See.svg')} />
              ) : (
                <Image style={{ height: heightPercentageToDP('2%'), fontWeight: 'bold', width: 16, }} source={require('../assets/Hide.svg')} />
              )}
            </TouchableOpacity>
          </View>
        </View>
        <TouchableOpacity
          onPress={handleSubmit}
          style={styles.button}
          disabled={email === "" || password === "" || loading}
        >
          {loading ? (
            <ActivityIndicator size="small" color="#ffffff" />
          ) : (
            <BoldText style={styles.buttonText}>{"CONNEXION"}</BoldText>
          )}
        </TouchableOpacity>
        {errorMessage && (
          <CustomText style={{ color: 'red', fontSize: hp('2%'), fontWeight: 'bold', textAlign: 'center' }}>{errorMessage}</CustomText>
        )}
        <TouchableOpacity onPress={() => navigation.navigate('RequestEmail')}>
          <BoldText style={styles.forgotPasswordText}>
            Mot de passe oublié
          </BoldText>
        </TouchableOpacity>
      </View>
      <TouchableOpacity onPress={() => navigation.navigate('Register')}>
        <View style={styles.newUserContainer}>
          <Image style={styles.girlsImage} source={require('../assets/girls 1.png')} />
          <View style={styles.newUserTextContainer}>
            <CustomText style={styles.newUserTitle}>Nouvel utilisateur ?</CustomText>
            <CustomText style={{ fontSize: hp('3%') }}>Créer un compte</CustomText>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  contain: {
    width: wp('90%'),
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#FEFEFE',
  },
  input: {
    width: '85%',
    height: hp('7%'),
    borderRadius: 5,
    padding: wp('4%'),
    fontSize: hp('3%'),
    marginBottom: hp('1.5%'),
    marginTop: hp('2%'),
    backgroundColor: '#ECF4F2',
  },
  toggleButton: {
    marginLeft: wp('-6%'),
  },
  button: {
    backgroundColor: '#9002C1',
    padding: hp('2%'),
    borderRadius: 8,
    marginBottom: hp('1%'),
    marginTop: hp('2%'),
    width: wp('50%'),
    height: hp('7%'),
  },
  buttonText: {
    fontWeight: '600',
    fontSize: hp('3%'),
    textAlign: 'center',
    color: '#ffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
    padding: wp('4%'),
  },
  formContainer: {
    width: wp('90%'),
    backgroundColor: '#FEFEFE',
    marginBottom: hp('5%'),
    marginTop: hp('3%'),
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: wp('4%'),
  },
  forgotPasswordText: {
    color: '#9002C1',
    fontWeight: '500',
    justifyContent: 'center',
    marginTop: hp('2%'),
    fontSize: hp('3%')
  },
  newUserContainer: {
    width: wp('90%'),
    height: 62,
    backgroundColor: '#D9D9D9',
    marginTop: hp('2%'),
    borderRadius: 20,
    flexDirection: 'row',
  },
  girlsImage: {
    width: 130,
    height: 62,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  newUserTextContainer: {
    marginLeft: wp('5%'),
    marginTop: hp('2%'),
  },
  newUserTitle: {
    color: '#9002C1',
    fontWeight: 'bold',
    fontSize: hp('2.5%')
  },
});
