import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button, Image, TouchableOpacity } from 'react-native';
import { getMorphology } from '../src/MorphologyManagement';
import { getUser } from '../src/UserManagement';
// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function ResultMorphologyScreen({ route, navigation }) {
  const { morphology } = route.params;
  const [user, setUser] = useState([]);

  const fetchData = () => {
    getUser()
      .then((data) => {
        setUser(data.user);
        if (data.user.first_connection === 1) {
          setfirstPopupVisible(true);
        } else {
          //console.log('pas 1er connexion');
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handlePrevious = () => {
    navigation.navigate('YourInformationFour');
  };

  const handleNextFirst = () => {
    navigation.navigate('Welcome');
  };

  const handleNext = () => {
    navigation.navigate('Main');
  };

  const morphologyImages = {
    A: require('../assets/iconMorphoA.png').default,
    H: require('../assets/iconMorphoH.png').default,
    X: require('../assets/iconMorphoX.png').default,
    V: require('../assets/iconMorphoV.png').default,
    // Ajoutez d'autres images ici selon les besoins
  };

  const imageSource = morphologyImages[morphology];

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <CustomText style={{ fontSize: heightPercentageToDP('3%'), color: '#9002C1', textTransform: 'uppercase' }}>Ta morphologie est</CustomText>
      </View>
      <View style={{ marginTop: heightPercentageToDP('10%'), alignContent: 'center', justifyContent: 'center' }}>
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          {/* <Image style={{width: 130, height: 400}} source={require("../assets/morphologie" + morphology + ".png")}/> */}
          <Image style={{ width: 130, height: 400 }} source={
            morphology === 'A' ? require('../assets/morphologieA.png') :
              morphology === 'H' ? require('../assets/morphologieH.png') :
                morphology === 'X' ? require('../assets/morphologieX.png') :
                  morphology === 'V' ? require('../assets/morphologieV.png') :
                    null} />
          <CustomText style={{ color: '#9002C1' }}>Ta configuration est maintenant terminée.</CustomText>
        </View>
        <View style={styles.containerButtons}>
          <View style={{ marginRight: 40 }}>
            <View>
              <TouchableOpacity onPress={handlePrevious} style={styles.button1}>
                <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
              </TouchableOpacity>
            </View>
          </View>
          {user.first_connection === 1 ? (
            // Afficher ce contenu si user.first_connection est égal à 1
            <View>
              <TouchableOpacity onPress={handleNextFirst} style={styles.button}>
                <CustomText style={styles.buttonText}>{"TERMINER"}</CustomText>
              </TouchableOpacity>
            </View>
          ) : (
            <View>
              <TouchableOpacity onPress={handleNext} style={styles.button}>
                <CustomText style={styles.buttonText}>{"TERMINER"}</CustomText>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginBottom: heightPercentageToDP('10%')
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  button1: {
    backgroundColor: '#C7C7C6',
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: 150,
  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    padding: 5,
    color: '#ffff'
  },
});