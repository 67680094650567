// fontConfig.js
import { useFonts, Montserrat_400Regular,Montserrat_500Medium, Montserrat_500Medium_Italic } from '@expo-google-fonts/montserrat';

export function useCustomFonts() {
  const [fontsLoaded] = useFonts({
    MontserratRegular: Montserrat_400Regular,
    MontserratBold: Montserrat_500Medium,
    MonstserratItalic: Montserrat_500Medium_Italic,
    // Ajoutez d'autres polices si nécessaire
  });

  return { fontsLoaded };
}

export const customFontStyles = {
  myFont: {
    fontFamily: 'MontserratRegular',
  },
  myFontBold: {
    fontFamily: 'MontserratBold',
  },
  myFontItalic: {
    fontFamily: 'MonstserratItalic',
  },
};
