import AsyncStorage from '@react-native-async-storage/async-storage';

import Config from "./Api_Url";

export async function register(user) {
    const response = await fetch(`${Config.serverUrl}/api/auth/register`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        console.log(response);
        alert("L'inscription a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Register Request Failed");
    }
    const data = await response.json();
    localStorage.setItem("sessionToken", data.jwtToken)
    return data;
}

export async function VerifiedEmail(user) {
    const response = await fetch(`${Config.serverUrl}/api/auth/verifiedmail`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        console.log(response);
        alert("L'inscription a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Register Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getUser() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/user/` + token, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    /* if (!response.ok) {
        alert("La récupération des infos de l'utilisateur a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("GetUser Request Failed");
    } */
    if (response.statusText == "Problème avec le token") {
        localStorage.removeItem('sessionToken');
        window.location.reload()
    }
    const data = await response.json();
    // console.log(data.user);
    return data;
}
export async function getMyTwins(myId,myWeight, myHeight, myMorphology, myDress, myBraL) {
    //console.log(myWeight, myHeight, myMorphology, myDress, myBraL)
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/user/twins/${token}?myId=${myId}&myWeight=${myWeight}&myHeight=${myHeight}&myMorphology=${myMorphology}&myDress=${myDress}&myBraL=${myBraL}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("La récupération des jumelles de l'utilisateur a échoué. Certaines informations sont vides : " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("GetUser Request Failed");
    }
    const data = await response.json();
    // console.log(data.user);
    return data;
}
export async function getUserById(iduser) {
    const response = await fetch(`${Config.serverUrl}/api/user/id/pp/${iduser}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("La récupération des infos de l'utilisateur a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("GetUser Request Failed");
    }
    const data = await response.json();
    // console.log(data.user);
    return data;
}

export async function getListUsers() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/user/list/` + token, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("La récupération des utilisateurs a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("GetListUsers Request Failed");
    }
    const data = await response.json();
    // console.log(data.users);
    return data;
}

export async function checkEmail(email) {
    const response = await fetch(`${Config.serverUrl}/api/user/check/email`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email })
    });
    if (!response.ok) {
        throw new Error("L\'adresse e-mail n\'est pas enregistrée.");
    }
    const data = await response.json();
    return data;
}

export async function resetPassword(user) {
    const response = await fetch(`${Config.serverUrl}/api/user/reset/password`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        alert("L'envoie du reset password de l'utilisateur a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("ResetPassword Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function setParameters(user) {
    const response = await fetch(`${Config.serverUrl}/api/user/set/parameters`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        //   alert("L'envoie du set parameters de l'utilisateur a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("SetParameters Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function login(user) {
    const response = await fetch(`${Config.serverUrl}/api/auth/login`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        throw new Error("Le mot de passe ou l\'adresse e-mail est erroné");
    }
    const data = await response.json();
    //localStorage.setItem("sessionToken", data.jwtToken)
    AsyncStorage.setItem("sessionToken", data.jwtToken)
    return data;
}

export async function setSuitUserTypeScreen(status, suituser) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, status: status, suituser: suituser };
    const response = await fetch(`${Config.serverUrl}/api/user/set/suitusertypescreen`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        alert("L'envoie des données de usertypescreen a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("UserTypeScreen Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function setUserTypeScreen(method) {
    let token = localStorage.getItem("sessionToken");
    const user = { token: token, method: method };
    const response = await fetch(`${Config.serverUrl}/api/user/set/usertypescreen`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user })
    });
    if (!response.ok) {
        alert("L'envoie des données de usertypescreen a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("UserTypeScreen Request Failed");
    }
    const data = await response.json();
    return data;
}