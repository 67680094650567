import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import TermsAndConditionsScreen from '../src/components/TermsAndConditionsScreen';
// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function CGScreen({ navigation }) {
  return (
    <View style={styles.container}>
      <Image style={{ width: 118, height: 90, marginTop: '3vh' }} source={require('../assets/logoSUITUSviolet.png')} />
      <BoldText style={{ fontSize: heightPercentageToDP('3%'), marginTop: '4%', marginBottom: '2%', fontWeight: 'Bold', color: '#9002C1' }}>Conditions générales d'utilisation</BoldText>
      <View style={{ width: widthPercentageToDP('90%'), height: heightPercentageToDP('60%'), marginTop: '3%', marginBottom: heightPercentageToDP('3%'),
      alignItems: 'center', borderWidth: 1, borderColor: '#C881E0', borderRadius: 25, }}>
        <TermsAndConditionsScreen />
      </View>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <BoldText style={{ color: '#9002C1', position: 'relative', marginTop: heightPercentageToDP('3%'), 
        marginBottom: heightPercentageToDP('10%'), fontSize: heightPercentageToDP('3%') }}>RETOUR</BoldText>
      </TouchableOpacity>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  textinput: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});