import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

//Favoris :
import { iscrushing } from '../../src/FavoriteManagement';

export default function TestScreen({ CrushPost, NoCrushPost, postId, userId }) {
  const [isSelected, setSelection] = useState(false);
  const [filename, setFilename] = useState('icon-star-purple.svg');

  const handleLike = () => {
    setSelection(!isSelected);
    if (isSelected === false) {
      setFilename('icon-star-purple-pushed.png');
      CrushPost(postId);
    } else {
      setFilename('icon-star-purple.svg');
      NoCrushPost(postId);
    }
  };

  useFocusEffect( 
    useCallback(() => {
      // Reload data or perform any action
      iscrushing(postId, userId)
      .then((data) => {
        const crushes = data.crush;
        if (crushes.some((crush) => crush.post_id === postId && crush.user_id === userId)) {
          setSelection(true);
          setFilename('icon-star-purple-pushed.png');
          console.log('Statut de setSelection : true');
        } else {
          setSelection(false);
        }
      })
      .catch((error) => {
        console.log('Erreur lors de la récupération des likes :', error);
      });
      return () => {
        // Cleanup if needed
        console.log('Unfocused');
      };
    }, [postId, userId])
  );

  useEffect(() => {
    iscrushing(postId, userId)
      .then((data) => {
        const crushes = data.crush;
        if (crushes.some((crush) => crush.post_id === postId && crush.user_id === userId)) {
          setSelection(true);
          setFilename('icon-star-purple-pushed.png');
          console.log('Statut de setSelection : true');
        } else {
          setSelection(false);
        }
      })
      .catch((error) => {
        console.log('Erreur lors de la récupération des likes :', error);
      });
  }, [postId, userId]);

  // Créez un objet qui mappe les noms de fichier aux chemins d'image
  const imageMap = {
    'icon-star-purple.svg': require('../../assets/icon-star-purple.svg').default,
    'icon-star-purple-pushed.png': require('../../assets/icon-star-purple-pushed.png').default,
    // Ajoutez d'autres images ici selon les besoins
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity activeOpacity={0.5} onPress={handleLike}>
        {/* Utilisez la variable filename pour sélectionner le chemin de l'image */}
        <Image style={{ width: 20, height: 20 }} source={!isSelected ? require('../../assets/icon-star-purple.svg') : require('../../assets/icon-star-purple-pushed.png')}   /* source={imageMap[filename]} */  />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
