import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { FlatList, StyleSheet, View, Image, TouchableOpacity } from 'react-native';

// Retour :
import Head from '../src/components/Head';

// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

// Data :
import { getTrendsPosts } from '../src/PostManagement';

import { getuserId } from '../src/MessageManagements';
import { getUser } from '../src/UserManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function PrivateMessagesScreen({ navigation }) {

  // const [ListPosts, setListPosts] = useState([]);
  const [messages, setListMessages] = useState([]);
  const [user, setUser] = useState([]);
  const profileImage = 'ex-profil.png';


  const fetchData = () => {
    getUser()
      .then((data) => {
        setUser(data.user);
      })
      .catch(error => {
        console.log(error);
      });
    getuserId()
      .then((data) => {
        setListMessages(data.crush);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const groupMessagesById = (messages) => {
    const groupedMessages = {};

    messages.forEach((message) => {
      const id_user1 = message.id_user1;
      const id_user2 = message.id_user2;
      const otherUserId = user.id === id_user1 ? id_user2 : id_user1;

      if (!groupedMessages[otherUserId]) {
        groupedMessages[otherUserId] = message;
      }
    });

    return Object.values(groupedMessages);
  };
  const uniqueMessages = groupMessagesById(messages);

  useEffect(() => {
    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const maintenant = new Date();
    const difference = maintenant - date;
    if (difference < 60000) {
      // Moins d'une minute
      return "il y a quelques secondes";
    } else if (difference < 3600000) {
      // Moins d'une heure
      const minutes = Math.floor(difference / 60000);
      return `il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (difference < 86400000) {
      // Moins d'un jour
      const heures = Math.floor(difference / 3600000);
      return `il y a ${heures} heure${heures > 1 ? 's' : ''}`;
    } else if (difference < 2592000000) {
      // Moins de 30 jours
      const jours = Math.floor(difference / 86400000);
      return `il y a ${jours} jour${jours > 1 ? 's' : ''}`;
    } else {
      // Plus de 30 jours
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('fr-FR', options);
    }
  }

  return (
    <View style={styles.container}>
      <Head titre="Mes Suit'Messages" navigation={navigation} />

      {/* <CustomText style={{fontSize: '20px', color: '#9002C1', marginTop:'10px'}}>Pas de messages</CustomText> */}

      {uniqueMessages.length === 0 ? (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ TextAlign: 'center', fontSize: heightPercentageToDP('3%'), color: '#9002C1', marginTop: heightPercentageToDP('3%'), TextAlign: 'center' }}>
            Pas de message
          </CustomText>
        </View>
      ) : (
        <View style={{ alignSelf: 'flex-start' }}>
          <FlatList data={uniqueMessages.reverse()}
            renderItem={({ item }) => (
              <View style={{ width: widthPercentageToDP('94%'), marginTop: 10, marginLeft: widthPercentageToDP('3%')}}>
                <TouchableOpacity style={{ flexDirection: 'row', marginBottom: 5, marginTop: 5, marginLeft: 15 }}
                  onPress={() => {
                    navigation.navigate('Messages', { user1: item.id_user1, user2: item.id_user2 });
                  }}>
                  <View style={{ borderRadius: 30, width: 50, height: 50, marginRight: 12 }}>
                    <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                      {/* {user.id !== item.id_user1 ? item.user1_profil : item.user2_profil === profileImage ? (
                          <Image style={{width: 50,height: 51,}} source={require(`../assets/${user.id !== item.id_user1 ? item.user1_profil : item.user2_profil}`)} />
                      ) : (
                        <Image style={{width: 50, height: 51}} source={{ uri: user.id !== item.id_user1 ? item.user1_profil : item.user2_profil}} />)
                        } */}
                      {item.user1_profil === profileImage ? (
                        <Image style={{ width: 50, height: 51 }} source={require('../assets/' + profileImage)} />
                      ) : item.user2_profil === profileImage ? (
                        <Image style={{ width: 50, height: 51 }} source={require('../assets/' + profileImage)} />
                      ) : (
                        user.id !== item.id_user1 && item.user1_profil ? (
                          <Image style={{ width: 50, height: 51 }} source={{ uri: item.user1_profil }} />
                        ) : user.id !== item.id_user2 && item.user2_profil ? (
                          <Image style={{ width: 50, height: 51 }} source={{ uri: item.user2_profil }} />
                        ) : (
                          <Image style={{ width: 50, height: 51 }} source={require('../assets/' + profileImage)} />
                        )
                      )}
                    </View>
                  </View>
                  <View style={{ flexDirection: 'column' }}>
                    <CustomText style={{ fontSize: 12 }}>{formatDate(item.created_date)}</CustomText>
                    <BoldText>{user.id !== item.id_user1 ? item.user1_pseudo : item.user2_pseudo}</BoldText>
                    <CustomText style={{fontSize: heightPercentageToDP('2%'), lineHeight: heightPercentageToDP('3%'), TextAlign: 'justify'}}>{item.body}</CustomText>
                  </View>
                </TouchableOpacity>
              </View>
            )}
            //keyExtractor={(item) => item.id.toString()}
          />

        </View>
      )}
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  flatWrapper: {
    backgroundColor: 'gray',
    width: 400,
    height: 300
  }
});