// TermsAndConditionsScreen.js
import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';

import CustomText from './Font/CustomText';
import BoldText from './Font/BoldText';
import ItalicText from './Font/ItalicText'
import { heightPercentageToDP } from 'react-native-responsive-screen';

const TermsAndConditionsScreen = () => {
  return (
    <ScrollView style={styles.container}>
        
     <BoldText style={styles.title}>Qui sommes-nous ?</BoldText>

      <CustomText style={styles.termsText}> 
        L’application mobile SuitUs (« Plateforme ») est éditée par la société
        VYZION SAS au capital de 40 000,00 € et représentée par Caroline LEVY.
        Notre siège social est situé au 99 AVENUE ACHILLE PERETTI 92200
        NEUILLY-SUR-SEINE et nous sommes immatriculés au Registre du
        Commerce et des Sociétés de Nanterre sous le numéro 911459709.
        Vous pouvez nous contacter à tout moment à l’adresse contact@suitus.fr.
        La plateforme est hébergée par OVH SAS dont le siège est situé à l’adresse 2, rue Kellermann, 59100 Roubaix.</CustomText>

        <BoldText style={styles.title} >Quelle est l’utilité des conditions générales d’utilisation ?</BoldText>
        
        <CustomText style={styles.termsText}>
        La confiance que vous nous portez tous les jours est notre priorité. A ce
        titre, nous mettons tout en œuvre pour que vous puissiez maintenir cette
        confiance au quotidien, ce qui passe évidemment par des conditions
        générales d’utilisation (« CGU ») respectées et connues de tous.
        Les CGU, qui s’appliquent à l’intégralité de nos services, ont justement
        pour objectif de vous présenter les règles que vous devez respecter
        lorsque vous utilisez la Plateforme, sachant que toute utilisation de la
        plateforme implique l’adhésion sans réserve aux CGU.
        Vous attestez donc avoir pris connaissance des CGU préalablement à
        toute utilisation de notre Plateforme et avoir disposé de toutes les
        informations et compétences nécessaires pour vous assurer que les
        services fournis correspondent à vos besoins.
      </CustomText>

      <BoldText style={styles.title}>Quelles sont les conditions pour disposer d’un compte ?</BoldText>

      <CustomText style={styles.termsText}>Pour vous créerun comptesurnotre Plateforme, 
        vous devez être une personne physique, majeure, 
        jouissant de la pleine capacité juridique permettant de vous engager au titre des CGU.
        Votre compte est strictement personnel. Vous vous engagez donc à
        fournir des données d’identifications requises à la création du compte de
        façon personnelle, correcte, exacte et véridique. Vous devez également
        nous informer de tout changement concernant les informations à fournir
        pour l’utilisation normale de la Plateforme.
        Vous êtes responsable du maintien de la confidentialité de votre compte
        et de votre mot de passe ainsi que des restrictions d'accès à votre
        terminal (ex : tablette, mobile, etc.). En cas de doute sur la vulnérabilité
        de votre compte ou sur la confidentialité de vos accès, vous devez
        changer votre mot de passe et/ou contacter notre service support dans les
        plus brefs délais. A défaut, et dans la limite de ce qui est autorisé par la loi
        applicable, vous êtes responsable de toutes les activités qui ont été
        menées depuis votre compte.
        Nous recommandons d’utiliser un mot de passe complexe (constitué de
        chiffres, de lettres minuscules et majuscules et de signes de ponctuation)
        permettant d’augmenter la sécurité d’accès au compte et de changer
        régulièrement de mot de passe en accédant aux paramètres de compte.</CustomText>

        <BoldText style={styles.title}>Quels sont nos engagements ?</BoldText>

        <CustomText style={styles.termsText}>
        Nous faisons tout notre possible pour vous proposer un service de qualité,
        accessible 24/24h, 7/7j, proposant des contenus exacts et précis, dénué
        de tout risque de défaillance technique.
        Néanmoins, le “risque zéro” n’existant pas en matière informatique, notre
        responsabilité ne peut pas être engagée en cas de préjudice pouvant
        intervenir à cause de l’utilisation de la Plateforme car nous ne pouvons
        pas être responsable en cas d’éventuelles erreurs, d’indisponibilités, ou
        d’interruption pouvant intervenir sur notre Plateforme ou dans le cas où
        un virus serait accessible depuis notre Plateforme. Nous ne sommes pas
        non plus responsables des délais ou de votre impossibilité à respecter vos
        obligations en application des CGU si le délai ou l'impossibilité résulte
        d'une cause en dehors de notre contrôle raisonnable.
        Nous ne sommes pas non plus responsables des piratages, erreurs ou
        virus qui pourraient être installés sur votre terminal et affecter le service
        ou des démarches ou actions entreprises ou omises par un administrateur système.
        De plus, votre accès à la Plateforme ou aux services peut
        occasionnellement être suspendu ou limité pour permettre des
        réparations, la maintenance, ou ajouter une nouvelle fonctionnalité ou un
        nouveau service.
        Dans tous les cas, il est expressément convenu que nous ne sommes
        soumis qu’à une obligation de moyens pour la fourniture de la Plateforme.
        Vous acceptez d’ailleurs que notre responsabilité ne puisse être engagée
        qu’en cas de faute de notre part, sachant que cette responsabilité ne
        saurait être applicable que pour les dommages directs et prévisibles, les
        préjudices indirects étant exclus, et que nous ne sommes pas
        responsables des pertes commerciales (y compris les pertes de profit,
        bénéfice, contrats, économies espérées, données, clientèle ou dépenses
        superflues), qui n'étaient pas prévisibles quand vous avez commencé à
        utiliser la Plateforme.
        Sont considérés comme des dommages indirects notamment les pertes
        de données, de temps, de bénéfices, de chiffre d’affaires, de marges,
        pertes de commandes, de clients, d’exploitation, de revenus, d’actions
        commerciales ou encore l’atteinte à l’image de marque, les résultats
        escomptés et l’action de tiers et d’une manière générale tout trouble
        commercial quelconque. De même est considérée comme indirecte tout
        action d’un tiers à votre encontre.
        Par ailleurs, vous êtes informé que tout cas de force majeure suspend
        immédiatement et automatiquement l’exécution de la Plateforme. Si les
        cas de force majeure ont une durée d’existence supérieure à deux mois,
        nous nous réservons le droit de suspendre ou de résilier le service sans
        préavis ni indemnité.
        Sont notamment considérés comme cas de force majeure les évènements
        échappant à notre contrôle, qui ne pouvaient être raisonnablement prévus
        lors de la mise à disposition de la Plateforme et dont les effets ne peuvent
        être évités par des mesures appropriées, qui empêchent son utilisation.
        En particulier, il s’agit de ceux habituellement retenus par la
        jurisprudence des cours et tribunaux français comme la guerre, l’émeute,
        l’incendie, les grèves internes ou externes, lock out, occupation des
        locaux, intempéries, tremblement de terre, inondation, dégât des eaux,
        restrictions légales ou gouvernementales, modifications légales ou
        réglementaires, accidents de toutes natures, épidémie, pandémie,
        maladie touchant plus de 10% du personnel de notre société dans un
        période de deux mois consécutifs, l’absence de fourniture d’énergie,
        l’arrêt partiel ou total du réseau Internet et, de manière plus générale, des
        réseaux de télécommunications privés ou publics, les blocage de routes et
        les impossibilités d’approvisionnement en fournitures et tout autre cas
        indépendant de notre volonté empêchant l’exécution normale de notre Plateforme</CustomText>

        <BoldText style={styles.title}>Quelles sont vos obligations et devoirs ?</BoldText>

        <CustomText style={styles.termsText}>Vous vous engagez à ne pas arranger, modifier, décompiler,
            désassembler, procéder à des opérations de reverse engineering,
            traduire, adapter, reproduire, distribuer, diffuser ou transférer toute
            information, tout logiciel, tout produit et d'une manière générale, tout
            élément de la Plateforme. Le re-postage, le scrapping, ou l’utilisation
            automatisée ou l’utilisation de grand volume de la Plateforme sont
            expressément interdits.
            Vous vous engagez à vous conformer à tout moment lors de l’utilisation
            de notre Plateforme (i) aux lois et réglementations nationales et
            internationales en vigueur et (ii) aux CGU.</CustomText>

        <BoldText style={styles.title}>VOUS GARANTISSEZ QUE VOUS N'UTILISEREZ PAS LA
            PLATEFORME À DES FINS ILLICITES OU IMMORALES OU
            CONTRAIRES À L'UTILISATION PRÉVUE ET/OU À LEUR FINALITÉ.</BoldText>
        
        <CustomText style={styles.termsText}>De plus, en utilisant la Plateforme, vous reconnaissez que vous avez
                    uniquement un droit d’utilisation pour votre compte.
                    Dans tous les cas, vous vous engagez à ne pas, directement ou
                    indirectement : <br />
                    ● Utiliser la Plateforme de manière à porter atteinte aux droits de
                    propriété intellectuelle et industrielle, au droit à la vie privée et/ou
                    au droit à l’image et/ou à tout autre droit d’autrui.<br />
                    ● Créer, utiliser, partager et/ou publier par tout moyen tout matériel
                    (texte, propos, images, sons, vidéos, etc.) ou contenu qui aurait,
                    notre appréciation exclusive, un caractère agressif, menaçant,
                    malveillant, diffamatoire, mensonger, pornographique, pédophile,
                    obscène, vulgaire, raciste, xénophobe, incitant à la haine,
                    sexuellement explicite, violent, contraire aux bonnes mœurs ou de
                    toute autre façon contestable.<br />
                    ● Mener des activités visant à accéder à des éléments ou
                    fonctionnalités des services dont l’utilisation est effectuée sans
                    notre autorisation préalable et expresse.<br />
                    ● Arranger, modifier, traduire, adapter, reproduire, indexer, copier
                    et/ou extraire toute information, tout logiciel, tout produit ou tout
                    autre élément ou partie de la Plateforme, par tout moyen sans notre
                    autorisation préalable et expresse.
                    modifier, fausser, bloquer, alourdir de manière anormale, perturber,
                    ralentir, et/ou entraver le fonctionnement normal de tout ou partie
                    de la Plateforme, ou leur accessibilité aux autres utilisateurs, ou le
                    fonctionnement des réseaux partenaires, ou tenter de faire tout ce
                    qui précède.<br />
                    ● Transmettre ou propager tout virus, cheval de Troie, ver, bombe,
                    fichier altéré et/ou dispositif de destruction similaire ou donnée
                    corrompue, et/ou organiser, participer ou être impliqué de quelque
                    façon que ce soit dans une attaque contre nos serveurs et/ou la
                    Plateforme et/ou les serveurs et/ou services de nos prestataires et
                    partenaires.<br />
                    ● Harceler les autres utilisateurs de la Plateforme, effectuer de ventes
                    pyramidales ou opérations similaires, ou d'envoi d’emailing, de
                    spams ou de toute publicité ou élément promotionnel non sollicités,
                    et ce à des fins commerciales ou non.<br />
                    ● Utiliser le compte d’un autre utilisateur de la Plateforme, vous faire
                    passer pour une autre personne ou vous attribuer une fausse qualité
                    dans les rapports avec toute personne physique ou morale.<br />
                    ● Collecter ou intercepter par tout moyen qui ne serait pas
                    expressément permis des données échangées par les autres
                    utilisateurs, ou les noms/pseudonymes et/ou mots de passe de tout
                    autre utilisateur.<br />
                    ● Tenter d'obtenir un mot de passe, des informations relatives à un
                    compte ou d'autres informations d'ordre privé auprès de tout autre
                    utilisateur de la Plateforme, et/ou vendre, louer, partager, prêter
                    et/ou de toute autre façon transférer à tout tiers votre compte et/ou
                    les moyens d’accéder à celui-ci et/ou de toute autre façon faire
                    bénéficier tout tiers de votre compte.<br />
                    ● Accéder, utiliser, télécharger via la Plateforme ou autrement
                    reproduire ou fournir à quiconque (à titre gratuit ou onéreux) tout
                    annuaire des utilisateurs de la Plateforme ou toute autre information
                    relative aux utilisateurs ou à l'utilisation de la Plateforme.
                    Si vous constatez un comportement malveillant ou contraire à nos CGU et
                    aux intérêts des autres utilisateurs, vous pouvez nous contacter à
                    l’adresse contact@suitus.fr.</CustomText>

                    <BoldText style={styles.title}> Votre compte peut-il être suspendu ou désactivé ?</BoldText>

                    <CustomText style={styles.termsText}>Nous pouvons limiter, restreindre,suspendre ou interrompre
                    définitivement tout ou partie de votre compte, à tout moment, sans
                    préavis et sans formalité préalable, en cas de manquement à vos devoirs
                    et obligations aux termes des CGU ou à la demande expresse d'une
                    autorité administrative ou juridictionnelle, ou encore en cas d'atteinte
                    réelle ou supposée à un droit quelconque, le tout sans que cette mesure
                    ne puisse être considérée comme un manquement à nos obligations.
                    L’adresse IP peut être utilisée pour vous identifier dans le but de respecter
                    les CGU.
                    Toute fermeture de votre compte entraîne la suppression définitive des
                    informations quinous sont communiquées lors de l’ouverture ou de la
                    mise à jour de votre compte, de tous les documents et autres éléments
                    stockés via la plateforme, ainsi que, le cas échéant, de tout document ou
                    contenu que vous auriez publié, téléchargé ou mis à disposition sur la
                    Plateforme.
                    Il vous est donc fortement recommandé de sauvegarder, sur votre
                    ordinateur ou sur un disque dur externe, tous les documents, informations
                    et autres éléments stockés sur la Plateforme. Nonobstant ce qui précède,
                    nous nous réservons le droit de conserver les documents, informations et
                    autres éléments susmentionnés sur nos serveurs pendant un délai
                    raisonnable.
                    En cas de fermeture de votre compte à votre initiative, aucun
                    remboursement, ni total ni partiel, des sommes déjà versées ne sera
                    effectué. En cas de fermeture de votre compte à notre initiative pour non-
                    respect des présentes CGU aucun remboursement, ni total ni partiel, des
                    sommes déjà versées ne sera effectué. De plus, il vous est formellement
                    et strictement interdit d’ouvrir un nouveau compte, avec la même ou une
                    adresse e-mail différente ou par personne interposée sans notre
                    autorisation préalable et écrite. Dans ce cas, contactez-nous à l’adresse
                    mail suivante pour solliciter la création d’un nouveau compte:
                    contact@suitus.fr.</CustomText>

                    <BoldText style={styles.title}>Quelles sont les règles applicables en matière de propriété intellectuelle ?</BoldText>

                    <CustomText style={styles.termsText}>Nous sommes et restons les titulaires exclusifs de tous les droits de
                    propriété intellectuelle sur les marques (ex : SuitUs, VYZION SAS, etc.),
                    charte graphique ou logos, ou tout autre élément que vous pouvez
                    visualiser lorsque vous utilisez notre Plateforme.
                    Le fait que vous puissiez y avoir accès librement lors de votre utilisation
                    n’implique pas que nous vous accordions une quelconque licence ou un
                    droit d’utilisation sur les éléments de la Plateforme.
                    Vous n’avez donc pas le droit d’utiliser ces éléments, de quelque manière
                    que ce soit, entièrement ou partiellement, sans notre autorisation
                    préalable et écrite. Toute utilisation de votre part que nous n’aurions pas
                    autorisée peut constituer une contrefaçon sanctionnée par les articles
                    L335-2 et suivants du Code de la propriété intellectuelle et par les articles
                    L713-2 et suivants du même code.
                    Notre Plateforme est également susceptible de mentionner, pour diverses
                    raisons, d’autres logos, marques commerciales ou marques déposées
                    nous appartenant ou à des tiers et protégés par le droit de la propriété
                    industrielle. Dès lors, toute utilisation non expressément autorisée de ces
                    éléments est constitutive de contrefaçon.
                    Conformément aux dispositions de la loi n°98-536 du 1er juillet 1998
                    portant transposition dans le Code de la propriété intellectuelle de la
                    directive 96/9 CE du 11 mars 1996 concernant la protection juridique des
                    bases de données, nous vous informons que nous somme producteur et
                    propriétaire de tout ou partie des bases de données utilisées via la
                    Plateforme. Par conséquent, toute extraction et/ou réutilisation des bases
                    de données au sens des articles L342-1 et L342-2 du Code de la propriété
                    intellectuelle est interdite.</CustomText>

                    <BoldText>Comment utilisons-nous vos données personnelles ?</BoldText>

                    <CustomText style={styles.termsText}>Lorsque nous travaillons ensemble et que vous utilisez la Plateforme, nous
                    n’avons pas d’autre choix que de traiter vos données personnelles
                    notamment pour traiter vos demandes, gérer nos relations commerciales,
                    etc.
                    Pour faciliter votre compréhension sur la manière dont nous traitons vos
                    données, nous avons rédigé une Politique de confidentialité, accessible à
                    tout moment sur la Plateforme, qui a pour objectif de vous présenter les
                    raisons et la manière pour lesquelles nous traitons vos données dans le
                    cadre des services que nous fournissons.</CustomText>

                    <BoldText>Quelle est la langue “standard” applicable ?</BoldText>

                    <CustomText style={styles.termsText}>La plateformepeutêtre traduite en différentes langues. 
                    Néanmoins, le français demeure la langue “standard” de notre société et de notre
                    activité. La langue française prévaut donc toujours sur les autres versions
                    de notre Plateforme dans le cadre de notre relation de travail, notamment
                    en cas de mauvaise interprétation, contestation, litige, ou difficulté
                    d’interprétation sur nos services.</CustomText>

                    <BoldText>Quelles sont les règles relatives aux liens hypertextes ?</BoldText>

                    <CustomText style={styles.termsText}>La Plateforme peut contenir des liens hypertextes vers des sites internet
                    ou des sites tiers. Vous êtes informé que nous ne sommes ni responsables
                    ni engagés sur les offres, actes, contenus, produits, services ou tout autre
                    élément disponible via ces liens.
                    Par ailleurs, nous vous remercions par avance de nous signaler tout lien
                    hypertexte présent sur la Plateforme qui permettrait d’accéder à un site
                    de tiers proposant un contenu contraire aux lois ou aux bonnes mœurs.</CustomText>
                    
                    <BoldText>Convention de preuve</BoldText>

                    <CustomText style={styles.termsText}>Nous archivons les communications, fichiers, contrats, bons de commande
                    et factures sur un support fiable et durable de manière à constituer une
                    copie fidèle et durable. Ces communications, fichiers, contrats, bons de
                    commande et factures peuvent être produits à titre de preuve.
                    Sauf preuve contraire, les données enregistrées par nos équipes constituent la
                    preuve de l’ensemble des échanges passés entre vous et nous.</CustomText>

                    <BoldText style={styles.title}>Nullité</BoldText>

                    <CustomText style={styles.termsText}>La nullité d'une des clauses des présentes n'entraîne pas la nullité des
                    CGU et les autres clauses continueront à produire leurs effets.</CustomText>

                    <BoldText style={styles.title}>Renonciation</BoldText>

                    <CustomText style={styles.termsText}>Le fait de ne pas se prévaloir à titre temporaire ou permanent d’une ou
                    plusieurs clauses des CGU, n’emportera en aucun cas renonciation à s’en
                    prévaloir.</CustomText>

                    <BoldText style={styles.title}>Quel est le droit applicable ?</BoldText>

                    <CustomText style={styles.termsText}>Nous sommes une entreprise de droit français.   
                    En conséquence, l’utilisation de notre Plateforme est soumise au droit français
                    et tout litige sera porté exclusivement devant les tribunaux français compétents.
                    </CustomText>

                    <BoldText style={styles.title}>Les conditions générales peuvent-elles être modifiées ?</BoldText>

                    <CustomText style={styles.termsText}>Nous nous réservons la possibilité de modifier les CGU à tout moment et
                    sans préavis. Nous vous conseillons donc de les consulter le plus
                    régulièrement possible.</CustomText>

                    <ItalicText style={{fontSize: 12, marginTop:5,fontWeight:'bold'}}>Certifié conforme par Dipeeo ®</ItalicText>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 5
  },
  termsText: { 
    fontSize: heightPercentageToDP('2.5%'),
    color:'#1A1A1A',
    lineHeight: heightPercentageToDP('6%'),
    textAlign: 'justify'
  },
  title:{
    fontSize: heightPercentageToDP('3%'),
    fontWeight: 'bold',
    marginTop:10,
    marginBottom:10,
    color:'#9002C1',
  }
});

export default TermsAndConditionsScreen;
