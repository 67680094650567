import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { setUserTypeScreen } from '../src/UserManagement';

// Font :
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import BoldText from '../src/components/Font/BoldText';

export default function UserTypeScreen({ navigation }) {

  const handleMensurationsBloc = () => {
    setUserTypeScreen("Mensurations")
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('YourInformationOne', {
            previousPage: 'UserType',
          })
        }
      })
  };
  const handleMorphologiesBloc = () => {
    setUserTypeScreen("Morphologies")
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('Morphologies')
        }
      })
  };

  const handlePrevious = () => {
    navigation.navigate('SuitUserType')
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <CustomText style={{ fontSize: heightPercentageToDP('3.5%'), color: '#9002C1', textTransform: 'uppercase' }}>Compte Utilisateur</CustomText>
      </View>
      <View style={styles.containerSuitUserType}>
        <View style={styles.leftContainer}>
          <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center' }} activeOpacity={0.5} onPress={handleMensurationsBloc}>
            <BoldText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold', marginTop: heightPercentageToDP('2%'), color: '#ffff', textTransform: 'uppercase' }}>MENSURATIONS</BoldText>
            <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), fontWeight: 600, color: '#ffff', marginBottom: 3, textAlign: 'justify', padding: 20, lineHeight: heightPercentageToDP('5%') }}>
              Pour mieux trouver tes jumelles suitusers et te voir comme dans un miroir, nous te conseillons de compléter tes mensurations plutôt que la morphologie, cela ne te prendra que quelques minutes
            </CustomText>
          </TouchableOpacity>
        </View>
        <View style={styles.rightContainer}>
          <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center' }} activeOpacity={0.5} onPress={handleMorphologiesBloc}>
            <BoldText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold', marginTop: heightPercentageToDP('2%'), color: '#ffff', textTransform: 'uppercase' }}>MORPHOLOGIE</BoldText>
            <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), fontWeight: 600, color: '#ffff', marginBottom: 3, textAlign: 'justify', padding: 20, lineHeight: heightPercentageToDP('5%') }}>
              Tu n'as pas de mètre ruban sur toi ? Choisis ta morphologie pour accèder à l'application </CustomText>
          </TouchableOpacity>
        </View>
        <TouchableOpacity onPress={handlePrevious} style={styles.button}>
          <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
        </TouchableOpacity>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#C7C7C6',
    borderRadius: 4,
    marginTop: 20,
    width: 150,
    marginTop: heightPercentageToDP('3%'),
    padding: 10,
    marginBottom: heightPercentageToDP('10%')

  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff'
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerSuitUserType: {
    flex: 2,
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 20,
    borderColor: '#9002C1',
    backgroundColor: '#9002C1',
    marginBottom: 15,
    marginTop: heightPercentageToDP('5%'),
    width: widthPercentageToDP('90%'),
  },
  rightContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#9002C1',
    backgroundColor: '#9002C1',
    width: widthPercentageToDP('90%'),
  }
});