import React from 'react';
import { View, Image, Text, TouchableOpacity, Modal, TextInput, ScrollView, StyleSheet } from 'react-native';
import CustomText from './Font/CustomText';

function ModalComment(props) {
  const {
    showCommentPopup,
    setShowCommentPopup,
    isCommentListEmpty,
    commentsAndReplies,
    handleDeleteComment,
    profile,
    profileImage,
    commentaire,
    SetAddCommentaire,
    handleReplyToComment,
    addComment,
    currentUserId,
  } = props;

  return (
    <Modal visible={showCommentPopup} animationType="slide" transparent={true} onRequestClose={() => setShowCommentPopup(false)}>
      <View style={styles.commentPopupContainer}>
        <ScrollView style={styles.commentList}>

          <TouchableOpacity onPress={() => setShowCommentPopup(false)}>
            <Image style={{ width: 12, height: 21, marginLeft: 13 }} source={require('../../assets/icon_before.svg')} />
          </TouchableOpacity>

          {isCommentListEmpty && <Text style={styles.emptyCommentText}>Aucun commentaire pour le moment.</Text>}
          {commentsAndReplies.map((commentItem) => (

            <View key={commentItem.id} style={styles.commentItem}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ width: 40, height: 40 }}>
                  <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                    <Image style={{ width: 40, height: 40 }} source={commentItem.profile_image === profileImage && { uri: profileImage }} />
                  </View>
                </View>
                {commentItem.user_id === currentUserId && (
                  <TouchableOpacity onPress={() => handleDeleteComment(commentItem.id)}>
                    <CustomText style={{ color: '#C881E0' }}>Supprimer</CustomText>
                  </TouchableOpacity>
                )}

              </View>

              <CustomText style={styles.commentUsername}>{commentItem.pseudo}</CustomText>
              <CustomText style={styles.commentText}>{commentItem.body}</CustomText>
              <CustomText style={styles.commentDate}>{commentItem.created_date}</CustomText>

              <TouchableOpacity onPress={() => handleReplyToComment(commentItem.id)}>
                <CustomText>Répondre</CustomText>
              </TouchableOpacity>

              {commentItem.replies.map((reply) => (
                <View key={reply.id} style={styles.replyItem}>
                  <View style={{ width: 40, height: 40 }}>
                    <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={commentItem.profile_image === profileImage ? { uri: profileImage } : require('../../assets/ex-profil.png')}
                      />
                    </View>
                  </View>
                  {reply.user_id === currentUserId && (
                    <TouchableOpacity onPress={() => handleDeleteComment(reply.id)}>
                      <CustomText style={{ color: '#C881E0' }}>Supprimer</CustomText>
                    </TouchableOpacity>
                  )}
                  <CustomText style={styles.replyUsername}>{reply.pseudo}</CustomText>
                  <CustomText style={styles.replyText}>{reply.body}</CustomText>
                  <CustomText style={styles.replyDate}>{reply.created_date}</CustomText>
                  <TouchableOpacity onPress={() => handleReplyToComment(reply.id)}>
                    <CustomText>Répondre</CustomText>
                  </TouchableOpacity>

                </View>
              ))}
            </View>
          ))}
        </ScrollView>
        <View style={styles.commentInputContainer}>
          <View style={{ width: 40, height: 40 }}>
            <View style={{ borderRadius: 30, overflow: 'hidden' }}>
              <Image
                style={{ width: 40, height: 40 }}
                source={require('../../assets/ex-profil.png')}
              />
            </View>
          </View>

          <TextInput
            style={styles.commentInput}
            placeholder="Ajouter un commentaire..."
            value={commentaire}
            onChangeText={SetAddCommentaire}
          />
          {/* Bouton de publication pour le commentaire principal */}
          <TouchableOpacity style={styles.commentButton} onPress={addComment}>
            <CustomText style={styles.commentButtonText}>Publier</CustomText>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>

  );
}

export default ModalComment;
const styles = StyleSheet.create({
  commentList: {
    marginTop: 10,
    flexDirection: 'row',
    flex: 1,
  },
  commentPopupContainer: {
    flex: 1,
    backgroundColor: 'white',
    width: 360,
  },

  emptyCommentText: {
    textAlign: 'center',
    marginLeft: '3pc',
    color: '#9002C1',
    marginTop: 10,
    fontSize: 16,
  },
  commentItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 10,
  },
  commentUsername: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  commentText: {
    marginBottom: 5,
  },
  commentDate: {
    color: '#aaa',
  },

  replyItem: {
    marginLeft: 20,
    marginTop: 10,
    borderLeftWidth: 1,
    borderLeftColor: '#ccc',
    paddingLeft: 10,
  },
  replyUsername: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  replyText: {
    marginBottom: 5,
  },
  replyDate: {
    color: '#aaa',
  },
  commentInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    padding: 10,
  },
  commentInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 5,
  },
})
