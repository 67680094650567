import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View, Image, Text } from 'react-native';
import Modal from 'react-native-modal';
import { getUser } from '../../src/UserManagement';
// import { getnbrPostsByUserToken } from '../../src/PostManagement.js';

export default function Header(props) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [user, setUser] = useState([]);
  const [profile, setProfilUser] = useState('ex-profil.png');
  const [postuser, setUserPosts] = useState([]);

  const fetchData = () => {
    getUser()
      .then((data) => {
        setUser(data.user);
        setProfilUser(data.user.profile_image);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModalMenu = () => setIsMenuVisible(!isMenuVisible);

  const handleDisconnect = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveLogin();
  };

  const handleNotifications = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveNotifications();
  };

  const handleRecommendations = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveRecommendations();
  };

  const handlePrivateMessages = () => {
    setIsMenuVisible(!isMenuVisible);
    props.movePrivateMessages();
  };

  const handleFavorites = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveFavorites();
  };

  const handleProfile = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveProfile();
  };

  const handleParameters = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveParameters();
  };

  const handleFAQ = () => {
    setIsMenuVisible(!isMenuVisible);
    props.moveFAQ();
  };

  return (
    <View style={styles.container}>
      <View style={styles.colOne}>
        <Image
          style={styles.imageSuitUs}
          source={require('../../assets/logoSUITUSblanc.png')}
        />
      </View>
      <View style={styles.colFour}>
        <TouchableOpacity activeOpacity={0.5} onPress={handleModalMenu}>
          <Image
            style={styles.imageMenu}
            source={require('../../assets/icon-menu-white.png')}
          />
        </TouchableOpacity>
        <Modal isVisible={isMenuVisible} 
            onRequestClose={() => { setIsMenuVisible(false); }}>
          <View style={styles.menuModal}>
            <TouchableOpacity activeOpacity={0.5} onPress={handleModalMenu}>
              <Image
                style={styles.iconClose}
                source={require('../../assets/icon-close.svg')}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleProfile}>
              <Image
                style={styles.iconProfile}
                source={profile ? { uri: profile } : require('../../assets/ex-profil.png')}
              />
            </TouchableOpacity>
            <Text style={{ color: '#FEFEFE', fontWeight: 'bold', fontSize: 20 }}>
              {user.firstname} {user.lastname}
            </Text>
            <Text style={{ color: '#FEFEFE', fontWeight: 'bold' }}>
              {user.locate}
            </Text>
            <View style={styles.infoProfilContainer}>
              <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                <View>
                  <Text style={{ color: '#FEFEFE', fontWeight: 'bold' }}>0</Text>
                </View>
                <Text style={{ color: '#FEFEFE' }}>POSTS</Text>
              </View>
              <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontWeight: '700', color: '#FEFEFE' }}>
                  {user.nbr_followers}
                </Text>
                <Text style={{ color: '#FEFEFE' }}>FOLLOWERS</Text>
              </View>
              <View style={{ padding: 10, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontWeight: '700', color: '#FEFEFE' }}>
                  {user.nbr_follows}
                </Text>
                <Text style={{ color: '#FEFEFE' }}>FOLLOW</Text>
              </View>
            </View>
            <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start', left: -60 }}>
              <TouchableOpacity activeOpacity={0.5} onPress={handleFavorites}>
                <View style={styles.containerIconMenu}>
                  <View>
                    <Image
                      style={styles.iconMenu}
                      source={require('../../assets/icon-star-white.svg')}
                    />
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      MES SUIT'CRUSH
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.5} onPress={handleRecommendations}>
                <View style={styles.containerIconMenu}>
                  <View>
                    <Image
                      style={styles.iconMenu}
                      source={require('../../assets/icon-suit-cops.svg')}
                    />
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      MES SUIT'COPS
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.5} onPress={handlePrivateMessages}>
                <View style={styles.containerIconMenu}>
                  <View>
                    <Image
                      style={styles.iconMenu}
                      source={require('../../assets/icon-message-horizontale.svg')}
                    />
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      MES SUIT'MAILS
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.5} onPress={handleNotifications}>
                <View style={styles.containerIconMenu}>
                  <View>
                    <Image
                      style={styles.iconMenu}
                      source={require('../../assets/icon-glass-white.svg')}
                    />
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      MES SUIT'NOTIFS
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.5} onPress={handleParameters}>
                <View style={styles.containerIconMenu}>
                  <View>
                    <Image
                      style={styles.iconMenu}
                      source={require('../../assets/icon-settings1.svg')}
                    />
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      PARAMETRES
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.5} onPress={handleFAQ}>
                <View style={styles.containerIconMenu}>
                  <View>
                    <Image
                      style={styles.iconMenu}
                      source={require('../../assets/icon-faq.png')}
                    />
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      FAQ
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.5} onPress={handleDisconnect}>
                <View style={styles.containerIconMenu}>
                  <View>
                    {/* <Image
                    style={styles.iconMenu}
                    source={require('../../assets/icon-logout.svg')}
                    /> */}
                  </View>
                  <View>
                    <Text style={{ paddingLeft: 5, color: '#FEFEFE', fontWeight: 'bold' }}>
                      DECONNEXION
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 4,
    backgroundColor: '#9002C1',
    alignItems: 'center',
    justifyContent: 'center',
    width: 375,
    maxHeight: 52,
    paddingBottom: 5,
    flexDirection: 'row',
  },
  iconMenu: {
    width: 20,
    height: 20,
    zIndex: 1,
    marginRight: 10,
  },
  iconClose: {
    width: 15,
    height: 15,
    position: 'absolute',
    left: 120,
    top: -80,
  },
  iconProfile: {
    width: 100,
    height: 100,
    borderRadius: 60,
    overflow: 'hidden',
  },
  imageSuitUs: {
    width: 52,
    height: 40,
  },
  imageMenu: {
    width: 40,
    height: 30,
    marginLeft: 10,
  },
  colOne: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 10,
  },
  colFour: {
    flex: 2,
    flexDirection: 'row',
    marginLeft: 250,
    left: 7,
  },
  menuModal: {
    backgroundColor: '#9002C1',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
    height: 600,
  },
  infoProfilContainer: {
    flex: 3,
    flexDirection: 'row',
    maxHeight: 60,
    justifyContent: 'space-between',
  },
  containerIconMenu: {
    flex: 2,
    flexDirection: 'row',
    maxHeight: 40,
    justifyContent: 'flex-start',
    padding: 10,
    width: 100,
  },
});
