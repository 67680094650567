import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { FlatList, StyleSheet, View , Image, TouchableOpacity, TextInput, ScrollView} from 'react-native';

//data : 
import {getuserId, create, remove } from '../src/MessageManagements';
import {getUser} from '../src/UserManagement';

// Retour :
import Head from '../src/components/Head';

// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function MessageScreen({navigation, route}) {

const [messageContent, setMessageContent] = useState(''); // Renommez la variable
const [messages, setListMessages] = useState([]);
const [user, setUser]  = useState([]);

const profileImage = 'ex-profil.png';
const [profile, setProfilUser] = useState(null);

// navigation :
const user1 = route.params?.user1; 
const user2 = route.params?.user2; 

const fetchData = () => {
    getuserId()
    .then((data) => {
      setListMessages(data.crush);
    })
    .catch(error => {
      console.log(error);
    });
    getUser()
    .then((data) => {     
      setUser(data.user);
      setProfilUser(data.user.profile_image);
    })
    .catch(error => {
      console.log(error);
    });
}

useEffect(() => {
    fetchData();
  },[]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const maintenant = new Date();
    const difference = maintenant - date;
    if (difference < 60000) {
      // - d'une min
      return "il y a quelques secondes";
    } else if (difference < 3600000) {
      // - d'une h
      const minutes = Math.floor(difference / 60000);
      return `il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (difference < 86400000) {
      // - d'un j
      const heures = Math.floor(difference / 3600000);
      return `il y a ${heures} heure${heures > 1 ? 's' : ''}`;
    } else if (difference < 2592000000) {
      // - de 30 j
      const jours = Math.floor(difference / 86400000);
      return `il y a ${jours} jour${jours > 1 ? 's' : ''}`;
    } else {
      // + de 30 j
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('fr-FR', options);
    }
  }
  function handleDeleteMessage(messageId) {
    // Supprimez immédiatement le message de l'état local
    const updatedMessages = messages.filter(message => message.id !== messageId);
    setListMessages(updatedMessages);
  
    // Maintenant, effectuez la suppression côté serveur
    remove(messageId)
      .then(() => {
        //console.log('Message supprimé avec succès, ID :', messageId);
      })
      .catch((error) => {
        // Si la suppression côté serveur échoue, vous pouvez restaurer le message dans l'état local ici
        setListMessages(messages); // Remettez les messages dans l'état local s'ils ne sont pas supprimés côté serveur
        //console.error('Erreur lors de la suppression du message :', error);
      });
  }
    
  function sendMessage() {
    if (messageContent.trim() === '') {
      // Ne rien faire si le contenu du message est vide
      return;
    }
  
    let newMessage;

    if (user.id === user2) {
      newMessage = {
        id_user1: user.id ,
        id_user2: user1,
        body: messageContent,
      };
    } else {
      newMessage = {
        id_user1: user.id,
        id_user2: user2,
        body: messageContent,
      };
    }
    create(newMessage)
    .then((data) => {
     if (data.error) {
     console.error("Erreur lors création du commentaire :", data.message);
     } else {
     console.log("Commentaire ajouté avec succès !");
     setMessageContent(''); // R
     fetchData(user1);
    }
    })
    .catch((error) => {
      console.error("Erreur lors d'ajout de commentaire :", error);
    });
  }
  
  return ( 
    <View style={styles.container}>
      <Head titre="Suit'Messages" navigation={navigation}/>

      <View style={{alignSelf:'flex-start', flex: 1,backgroundColor: 'white'}}>
        <FlatList data={messages.filter(item => item.id_user1 == user1 && item.id_user2 == user2 || item.id_user1 == user2 && item.id_user2 == user1 ).reverse()}
          renderItem={({ item }) => (
            <ScrollView style={{ marginTop: 10, flexDirection: 'row', flex: 1}}>
              <View style={{ flexDirection: 'row', marginBottom: 5, marginTop: 5, marginLeft: 15}}>
                <View style={{ borderRadius: 30, width: 50, height: 50, marginRight: 12 }}>
                  <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                    {item.user1_profil === profileImage ? (
                      <Image style={{width:50, height:51}} source={require(`../assets/${profileImage}`)} />
                        ) : (
                      <Image style={{width: 50, height: 51}} source={{ uri: item.user1_profil}} />)
                    }
                  </View>
                </View>
                 {user.id === item.id_user1 ? (
                  <View style={styles.user}>
                    <CustomText style={{ fontSize: 11 }}>{formatDate(item.created_date)}</CustomText>
                    <BoldText>{item.user1_pseudo}</BoldText>
                    <CustomText style={{width:222}}>{item.body}</CustomText>
                    <TouchableOpacity onPress={() => handleDeleteMessage(item.id)}>
                      <CustomText style={{ fontSize: 12, color: '#9002C1',marginTop:4 }}>Delete</CustomText>
                    </TouchableOpacity>
                  </View>) : ( 
                  <View style={styles.user1}>
                    <CustomText style={{ fontSize: 12 }}>{formatDate(item.created_date)}</CustomText>
                    <BoldText>{item.user1_pseudo}</BoldText>
                    <CustomText style={{width:222}}>{item.body}</CustomText>
                  </View> )}
              </View>
            </ScrollView>)}
          //keyExtractor={(item) => item.id.toString()}
        />
    </View>
    <View style={styles.commentInputContainer}>
          <View style={{ width: 40, height: 40 }}>
            <View style={{ borderRadius: 30, overflow: 'hidden' }}>
              {profile === profileImage ? (
               <Image style={{width: 40, height: 40}} source={require('../assets/' + profileImage)} />
                ) : (
               <Image style={{width: 40, height: 40}} source={{ uri: profile }} />)
             }
            </View>
          </View>
          <TextInput style={styles.commentInput}
            placeholder="Démarrer un nouveau Suit'Message"
            value={messageContent} onChangeText={setMessageContent}
          />
          <TouchableOpacity style={styles.commentButton} onPress={sendMessage}>
           <CustomText style={styles.commentButtonText}>Publier</CustomText>
          </TouchableOpacity>
        </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  //moi
  user:{
  flexDirection:'column', 
  backgroundColor: '#E4C0F0',
  padding: 8,
  borderRadius: 10, 
  margin: 5,
  },
  //other
  user1:{
    flexDirection:'column',
    backgroundColor: '#D9E9E6',
    padding: 8,
    borderRadius: 10,
    margin: 5,
  },
  commentInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf:'flex-start',
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    padding: 10,
    width: '100%',
  },
  commentInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 5,
    height: heightPercentageToDP('5%')
  },
  commentButton:{
    backgroundColor:'#9002C1',
    height:29,
    borderRadius:3,
  },
  commentButtonText:{
    color:'#FEFEFE',
    marginTop:5,
  }
});