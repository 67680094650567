import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';

// Font :
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function InfluencerTypeScreen({ navigation }) {

  const handlePrevious = () => {
    navigation.navigate('SuitUserType');
  };
  const handleNext = () => {
    navigation.navigate('YourInformationOne', {
      previousPage: 'InfluencerType',
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <CustomText style={{ fontSize: heightPercentageToDP('3.5%'), color: '#9002C1', textTransform: 'uppercase' }}>Compte suituser Modèle</CustomText>
      </View>

      <View style={{ marginTop: heightPercentageToDP('5%'), alignContent: 'center', justifyContent: 'center' }}>
        <View style={styles.boxInfoModel}>
          <CustomText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold', marginBottom: 10, marginTop: 20, color: '#9002C1', textTransform: 'uppercase' }}>suituser MODèLE</CustomText>
          <CustomText style={{ fontSize: heightPercentageToDP('2.5'), fontWeight: 400, marginTop: heightPercentageToDP('2%'), color: '#9002C1', textTransform: 'uppercase' }}> MUNIS-TOI D'UN MèTRE RUBAN </CustomText>
          <CustomText style={{ fontSize: heightPercentageToDP('2.5'), fontWeight: 400, marginTop: heightPercentageToDP('2%'), color: '#9002C1' }}> POUR PRENDRE TES </CustomText>
          <CustomText style={{ fontSize: heightPercentageToDP('2.5'), fontWeight: 400, marginTop: heightPercentageToDP('2%'), color: '#9002C1' }}> MENSURATIONS</CustomText>
        </View>
        <View style={{ alignItems: 'center' }}>
          <Image
            style={{ width: 240, height: 64, marginTop: heightPercentageToDP('4%'), marginBottom: heightPercentageToDP('3%') }}
            source={require('../assets/metre-ruban.png')}
          />
        </View>
        <View style={{ alignItems: 'center', flexDirection: 'column' }}>
          <TouchableOpacity onPress={handleNext} style={styles.button}>
            <CustomText style={styles.buttonText}>{"RENTRE TES MENSURATIONS"}</CustomText>
          </TouchableOpacity>

          <TouchableOpacity onPress={handlePrevious} style={styles.button1}>
            <CustomText style={styles.buttonText1}>{"RETOUR"}</CustomText>
          </TouchableOpacity>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },

  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  boxInfoModel: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 20,
    borderColor: '#9002C1',
    marginBottom: 15,
    marginTop: heightPercentageToDP('3%'),
    width: widthPercentageToDP('90%'),
    alignItems: 'center',
    padding: 20
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 4,
    borderRadius: 5,
    marginTop: heightPercentageToDP('3%'),
    width: widthPercentageToDP('80%'),
    padding: 10
  },
  button1: {
    backgroundColor: '#C7C7C6',
    padding: 10,
    borderRadius: 5,
    marginTop: heightPercentageToDP('3%'),
    marginBottom: heightPercentageToDP('10%')
  },
  buttonText: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 4,
    color: '#ffff'
  },
  buttonText1: {
    fontWeight: 500,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    marginTop: 4,
    color: '#000'
  },
});