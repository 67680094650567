import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';

export default function TestScreen({ navigation }) {
  const [isSelected, setSelection] = useState(false);
  const [filename, setFilename] = useState('icône_commentaire_violet.svg');

  const handleLike = () => {
    setSelection(!isSelected);
    if (isSelected === false) {
      setFilename('icône_commentaire_violet.svg');
    } else {
      setFilename('icône_commentaire_bleu.svg'); // Changez le nom du fichier ici
    }
  };

  return (
    <View style={styles.container}>
        <Image style={{width: 20, height: 20}} source={require('../../assets/' + 'icône_commentaire_violet.svg')} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
