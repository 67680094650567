import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, ScrollView, Modal, FlatList, Image, Text, RefreshControl, ActivityIndicator, Platform, Dimensions } from 'react-native';
import SwiperFlatList from 'react-native-swiper-flatlist';
import { Video } from 'expo-av';

// Text :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

// Boutton :
import Parameters from '../src/components/Parameters/Parameters';
import Information from '../src/components/i';
import Like from '../src/components/Like'
import Message from '../src/components/Message';
import Star from '../src/components/Star.js';

// Follow :
import { addFollow, deleteFollow, isFollow, Ifollow } from '../src/FollowManagement';
// Like
import { like, dislike, getAllLikes } from '../src/LikeManagement';
//Favoris :
import { favorite, disfavored } from '../src/FavoriteManagement';
// Utilisateur : 
import { getUser } from '../src/UserManagement';
// Data utilisé : 
import { getHomePosts } from '../src/PostManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

import Config from '../src/Api_Url.js';

export default function HomeScreen({ navigation }) {
  // Posts Home :
  const [home, SetHome] = useState([]);
  // Date :
  const [date, SetDate] = useState([]);
  //likes :
  const [lik, SetAllLikes] = useState([]);
  // PostId
  const profileImage = 'ex-profil.png';
  const [postId, setPostId] = useState([]);
  const [token, setUserToken] = useState([]);
  const [user, setUser] = useState([]);
  const [tall, setUserTall] = useState([]);
  const [myfollow, SetmyFollows] = useState([]);
  const [f, setF] = useState([]);

  // Button Follow :
  const [followStatus, setFollowStatus] = useState({});
  // Description d'un poste : 
  const [showFullDescription, setShowFullDescription] = useState(false);
  const uniqueHome = home.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
  // Slide :
  const [statusIcon, setStatusIcon] = useState([true, false]);
  const [cursor, setCursor] = useState(0);
  // Le i de Info description :
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const followStatusArray = [];

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // call your api here
      fetchData();
    });

    return unsubscribe;
  }, [navigation, currentPage]);

  const fetchData = () => {
    // Data Accueil:
    if (loadingMore || loading) return; // Éviter les requêtes multiples

    /* if (currentPage === 1) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    } */
    //setLoading(true);
    setLoadingMore(true);
    getHomePosts(5000, currentPage, 3)
      .then((data) => {
        setLoading(false);
        setLoadingMore(false)
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
        if (data.posts.length > 0) {
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          const firstPost = data.posts[0];
          firstPost.created_date = new Date(firstPost.created_date).toLocaleDateString('fr-FR', options);
          //SetHome(data.posts.map((post) => ({ ...post, created_date: new Date(post.created_date).toLocaleDateString('fr-FR', options) })));
          SetHome(prevPosts => [...prevPosts, ...data.posts.map((post) => ({ ...post, created_date: new Date(post.created_date).toLocaleDateString('fr-FR', options) != "Invalid Date" ? new Date(post.created_date).toLocaleDateString('fr-FR', options) : post.created_date }))])
          //SetDate(firstPost.created_date);
          const postId = Array.from(new Set(data.posts.map((post) => post.id)));
          setPostId(postId);
          const userIds = data.posts.map((post) => post.user_id);
          const tokenFlr = localStorage.getItem("sessionToken");
          const followPromises = userIds.map((idFlw) => {
            return isFollow(idFlw, tokenFlr);
          });
          Promise.all(followPromises)
            .then((followData) => {
              const followStatusObj = {};
              followData.forEach((data, index) => {
                if (data.error === false) {
                  const userId = userIds[index];
                  followStatusObj[userId] = data.follow;
                  // console.log(`User ${userId} is followed: ${data.follow}`);
                  followStatusArray.push({ userId: userId, follow: data.follow });
                }
              });
              //console.log(followStatusArray)
              setF(prevF => [...prevF, ...followStatusArray])
              setFollowStatus(followStatusObj);
              setLoading(false);
            })
            .catch((error) => {
              setLoadingMore(false);
              setLoading(false);
              console.log(error);
            });
        }
      })
    getUser()
      .then((data) => {
        setUserToken(data.user.id);
        setUser(data.user.id);
        setUserTall(data.user);
      })
      .catch(error => {
        console.error('Erreur des données utilisateur :', error);
      });
    getAllLikes()
      .then((data) => {
        // console.log('likeposte =', data.Likesbyposts);
        SetAllLikes(data.Likesbyposts);
      })
      .catch(error => {
        console.log(error);
      });
    const tokenFlr = localStorage.getItem("sessionToken");
    Ifollow(tokenFlr)
      .then((data) => {
        //console.log('Ifollow =', data.follows);
        SetmyFollows(data.follows);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  // Like postes : 
  const Dolike = (postId) => {
    const userId = token;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    like(postData)
      .then((data) => {
        if (data.success) {
          fetchData(SetAllLikes);
        } else {
          fetchData(SetAllLikes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Nolike = (postId) => {
    const userId = token;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    dislike(postData)
      .then((data) => {
        if (data.success) {
          fetchData(SetAllLikes);
        } else {
          fetchData(SetAllLikes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Crush postes : 
  const Docrush = (postId) => {
    const userId = token;
    const postData = {
      user_id: userId,
      post_id: postId,
    };
    favorite(postData)
      .then((data) => {
        if (data.success) {
          //  console.log("Crush créé avec succès");
        } else {
          //  console.error("Échec de la création du crush");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Nocrush = (postId) => {
    const userId = token;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    disfavored(postData)
      .then((data) => {
        if (data.success) {
          //console.log("Unlike effectué avec succès");
        } else {
          //console.error("Échec de uncrush");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Fonction pour follow :
  function handleFollow(idFlw) {
    let tokenFlr = localStorage.getItem("sessionToken");
    const isFollowing = followStatus[idFlw];
    if (isFollowing) {
      deleteFollow(idFlw, tokenFlr)
        .then((data) => {
          if (data.success) {
            setFollowStatus((prevFollowStatus) => ({
              ...prevFollowStatus,
              [idFlw]: false,
            }));
            setF((prevFollowStatus) => {
              const updatedFollowStatus = [...prevFollowStatus];
              //console.log(updatedFollowStatus)
              const index = updatedFollowStatus.findIndex((item) => item.userId === idFlw);
              if (index !== -1) {
                updatedFollowStatus[index] = { ...updatedFollowStatus[index], follow: false };
              }
              return updatedFollowStatus;
            });
          } else {
            setFollowStatus((prevFollowStatus) => ({
              ...prevFollowStatus,
              [idFlw]: false,
            }));
            setF((prevFollowStatus) => {
              const updatedFollowStatus = [...prevFollowStatus];
              //console.log(updatedFollowStatus)
              const index = updatedFollowStatus.findIndex((item) => item.userId === idFlw);
              if (index !== -1) {
                updatedFollowStatus[index] = { ...updatedFollowStatus[index], follow: false };
              }
              return updatedFollowStatus;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      addFollow(idFlw, tokenFlr)
        .then((data) => {
          if (data.success) {
            setFollowStatus((prevFollowStatus) => ({
              ...prevFollowStatus,
              [idFlw]: true,
            }));
            setF((prevFollowStatus) => {
              const updatedFollowStatus = [...prevFollowStatus];
              //console.log(updatedFollowStatus)
              const index = updatedFollowStatus.findIndex((item) => item.userId === idFlw);
              if (index !== -1) {
                updatedFollowStatus[index] = { ...updatedFollowStatus[index], follow: true };
              }
              return updatedFollowStatus;
            });
          } else {
            setFollowStatus((prevFollowStatus) => ({
              ...prevFollowStatus,
              [idFlw]: true,
            }));
            setF((prevFollowStatus) => {
              const updatedFollowStatus = [...prevFollowStatus];
              //console.log(updatedFollowStatus)
              const index = updatedFollowStatus.findIndex((item) => item.userId === idFlw);
              if (index !== -1) {
                updatedFollowStatus[index] = { ...updatedFollowStatus[index], follow: true };
              }
              return updatedFollowStatus;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  // (i) info function avoir le bon index de l'item : 
  function handleModalInfo(index) {
    setSelectedItemIndex(index);
    setIsModalInfo(true);
  }

  // Isoler les images dans un tableau : 
  const differentMediaNameValuesDict = {};
  uniqueHome.forEach((uniqueItem) => {
    const id = uniqueItem.id;

    // Filtrer les éléments ayant le même ID que uniqueItem
    const sameIdItems = home.filter((item) => item.id === id);
    // Vérifier les différences pour le champ 'media_name'
    sameIdItems.forEach((item) => {
      if ('/uploads/' + uniqueItem.media_name !== item.body) {
        differentMediaNameValuesDict[id] = {
          ...differentMediaNameValuesDict[id],
          body: item.body,
          //format: item.format
        };
      }
    });
  });

  // Récupérer les images filtrés : 
  const postsWithDifferentMedia = uniqueHome.map((uniqueItem) => {
    const id = uniqueItem.id;
    const sameIdItems = home.filter((item) => item.id === id);
    const mediaNames = sameIdItems.map((item) => item.body);
    const associatedPostId = postId.find((postId) => postId === id);
    // Retournez élements :
    return {
      ...uniqueItem,
      mediaNames,
      postId: associatedPostId,
    };
  });

  // Slider des images (à refaire) :
  function selectPrevious() {
    if (cursor - 1 >= 0) {
      const newStatusIcon = [...statusIcon];
      newStatusIcon[cursor] = false;
      newStatusIcon[cursor - 1] = true;
      setCursor(cursor - 1);
      setStatusIcon(newStatusIcon);
    }
  }
  function selectNext() {
    if (cursor + 1 < statusIcon.length) {
      const newStatusIcon = [...statusIcon];
      newStatusIcon[cursor] = false;
      newStatusIcon[cursor + 1] = true;
      setCursor(cursor + 1);
      setStatusIcon(newStatusIcon);
    }
  }

  function getLikeCountByPostId(postId) {
    const likeData = lik.find((like) => like.post_id === postId);
    return likeData ? likeData.like_count : 0;
  }

  const handleLoadMore = () => {
    if (currentPage < totalPages && !loadingMore) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const renderFooter = () => {
    return loadingMore ? (
      <View style={{ paddingVertical: 20 }}>
        <ActivityIndicator size="large" color="#9002C1" />
      </View>
    ) : null;
  };

  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = () => {
    fetchData();
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 1000); // Délai en millisecondes
  };

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#9002C1" />
        <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
      </View>
    );
  }

  const handleShareProfileLink = async ({ userId }) => {
    /* try {
      await Share.share({
        message: window.location.href + '/' + userId
      });
    } catch (error) {
      console.error('Error sharing:', error);
    } */
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Lien du profil',
          text: 'Bien vouloir consulter ce profil :',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert(
        "Partage non pris en charge",
        "L'API Web Share n'est pas prise en charge dans votre navigateur. Veuillez copier et partager le lien manuellement.",
        [{ text: 'Copy Link', onPress: () => copyToClipboard(window.location.href) }]
      );
    }
  };

  const copyToClipboard = (text) => {
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(text).then(() => {
        Alert.alert('Link copied to clipboard');
      });
    } else {
      // Pour les environnements natifs, utiliser un module de copie comme expo-clipboard
    }
  };

  //console.log(f)
  return (
    <ScrollView contentContainerStyle={styles.scrollContent}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>

      <View style={styles.container}>
        <CustomText style={{ fontSize: heightPercentageToDP('3%'), color: '#9002C1', marginTop: heightPercentageToDP('2%'), textTransform: 'uppercase' }}>Suit'Home</CustomText>
        <View style={{ justifyContent: 'center', alignItems: 'center', position: 'relative', marginBottom: heightPercentageToDP('10%') }}>

          <FlatList
            data={postsWithDifferentMedia.filter((item) => {
              const userFollowFilter = item.user_id === myfollow.idFlw;
              const morphologyFilter = item.morphology_calculated === tall.morphology_calculated;
              const other = item.morphology_calculated !== tall.morphology_calculated;
              const heightFilter = item.user_height === tall.height;
              return userFollowFilter || morphologyFilter || heightFilter || other;
            })
              /* .sort((a, b) => {
                if (a.user_id === myfollow.idFlw && b.user_id !== myfollow.idFlw) {
                  return -1;
                } else if (a.user_id !== myfollow.idFlw && b.user_id === myfollow.idFlw) {
                  return 1;
                } else {
                  if (a.morphology_calculated === tall.morphology_calculated && b.morphology_calculated !== tall.morphology_calculated) {
                    return -1;
                  } else if (a.morphology_calculated !== tall.morphology_calculated && b.morphology_calculated == tall.morphology_calculated) {
                    return 1;
                  } else {
                    if (a.user_height === tall.height && b.user_height !== tall.height) {
                      return -1;
                    } else if (a.user_height !== tall.height && b.user_height === tall.height) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }
                }
              }) */
              .sort((a, b) => { return b.post_id - a.post_id }
              )}
            renderItem={({ item, index }) => (
              <View key={index}>
                <View style={styles.containerMorphologies}>
                  <View style={{ borderWidth: 1, borderColor: '#C881E0', borderRadius: 25, padding: 5, width: '100%' }}>
                    <View style={{ width: widthPercentageToDP('90%'), height: '100%' }}>
                      <View style={styles.containerProfile}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: '0.33pc', marginLeft: 5 }}>
                          <TouchableOpacity onPress={() => {
                            navigation.navigate('UserProfile', { iduser: item.user_id });
                          }}>
                            <View style={{ backgroundColor: '#9002C1', padding: 2, borderRadius: 30, width: 61, height: 60 }}>
                              <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                                {item.user_profile_image === profileImage ? (
                                  <Image style={{ width: 60, height: 56, }} source={require('../assets/' + profileImage)} />
                                ) : (
                                  <Image source={{ uri: item.user_profile_image }} style={{ width: 60, height: 56, }} />)
                                }
                              </View>
                            </View>
                          </TouchableOpacity>
                          <View style={styles.Letters}>
                            <CustomText style={{ fontWeight: 'bold', color: '#9002C1' }}>{item.morphology_calculated}</CustomText>
                          </View>
                          <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={{ marginLeft: 5 }}>
                              <CustomText style={{ fontWeight: 600, width: 86 }}>{item.user_pseudo} </CustomText>
                            </View>
                            <View style={{ marginLeft: 5 }}>
                              <TouchableOpacity style={{ height: 26, alignItems: "center", justifyContent: "center", backgroundColor: "#9002C1", borderRadius: 4 }} activeOpacity={0.5}
                                onPress={() => { handleFollow(item.user_id, index); }}>
                                <Text style={{ color: "white", fontWeight: "bold", padding: 2 }}>
                                  {/* {followStatus[item?.user_id] ? 'Se désabonner' : 'S\'abonner'} */}
                                  {f?.find(user => user.userId === item.user_id)?.follow ? 'Se désabonner' : 'S\'abonner'}
                                </Text>
                              </TouchableOpacity>
                            </View>
                            <View style={{ marginLeft: 5 }}>
                              <TouchableOpacity onPress={() => handleShareProfileLink({ userId: item.user_id })}>
                                <Parameters />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                      {/* date publication */}
                      <View style={{ marginTop: '2pc' }}>
                        <CustomText style={{ fontWeight: 200, opacity: '0.8', fontSize: heightPercentageToDP('2%'), marginTop: heightPercentageToDP('2%'), marginBottom: heightPercentageToDP('2%'), marginLeft: '0.8pc' }}>{item.created_date}</CustomText>
                      </View>

                      <View style={styles.imagecadre}>
                        {/* {item.mediaNames.map((mediaName, index) => (
                          (item.mediaNames.length === 1 || cursor === index) && (
                            <Image
                              key={index}
                              style={styles.postImage}
                              //source={{ uri: mediaName }}
                              source={`${Config.serverUrl}${mediaName}`}
                            />
                          )
                        ))} */}
                        <SwiperFlatList
                          //autoplay
                          autoplayDelay={2}
                          autoplayLoop
                          index={0}
                          showPagination
                          paginationDefaultColor="#E4C0F0"
                          paginationActiveColor="#9002C1"
                          data={Array.from(new Set(item.mediaNames))}
                          renderItem={({ item: mediaName }) => (
                            <View style={{ width: widthPercentageToDP('100%') }}>
                              {
                                item?.format?.includes("image") ?
                                  <Image style={styles.postImage} source={mediaName ? `${Config.serverUrl}${mediaName}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                                  : item?.format?.includes("video") ?
                                    <Video
                                      shouldPlay
                                      //usePoster
                                      //posterSource={require('../assets/loader.gif')}
                                      source={mediaName ? `${Config.serverUrl}${mediaName}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                                      //style={styles.postImage}
                                      style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").width }}
                                      useNativeControls
                                      resizeMode="cover"
                                    /*  onReadyForDisplay={videoData => {
                                       videoData.srcElement.style.position = "initial"
                                     }}  */
                                    />
                                    : <Image style={styles.postImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                              }
                            </View>
                          )}
                        />
                      </View>

                      {/*  <View style={styles.dotsContainer}>
                        {item.mediaNames.map((_, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => setCursor(index)}
                            style={[
                              styles.dot,
                              index === cursor ? styles.activeDot : styles.inactiveDot,
                            ]}
                          />
                        ))}
                      </View> */}
                      <TouchableOpacity activeOpacity={0.5} onPress={() => handleModalInfo(index)}>
                        <Image style={styles.iconLogo} source={require('../assets/i-letter.svg')} />
                      </TouchableOpacity>

                      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 2, position: 'absolute', top: '50%', }}>
                        <TouchableOpacity activeOpacity={0.5} onPress={() => selectPrevious(index)} style={{ marginLeft: 17 }}>
                          <Image style={{ width: 30, height: 25 }} />
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.5} onPress={() => selectNext(index)} style={{ marginRight: 0, marginLeft: 220 }}>
                          <Image style={{ width: 30, height: 25 }} />
                        </TouchableOpacity>
                      </View>
                      <View style={{ flexDirection: 'column', marginTop: '0.6pc', marginLeft: '0.6pc' }}>

                        <View style={{ flexDirection: 'row', marginLeft: 4, marginTop: heightPercentageToDP('2%') }}>
                          <BoldText style={{ marginRight: 4, fontSize: heightPercentageToDP('2%') }}>{getLikeCountByPostId(item.id)}</BoldText>
                          <BoldText style={{ fontSize: heightPercentageToDP('2%') }}>Suit'Likes</BoldText>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                          <View style={{ flexDirection: 'row' }}>
                            <CustomText >
                              <CustomText style={{ fontWeight: 'bold', marginRight: '0.8pc' }}> {item.user_pseudo}</CustomText>
                              {showFullDescription ? item.title : (item.description.length > 15 ? item.title.substring(0, 20) : item.title)}
                            </CustomText>
                            {!showFullDescription && item.title.length > 20 && (
                              <TouchableOpacity onPress={() => setShowFullDescription(true)}>
                                <CustomText style={{ color: '#C881E0' }}> ...Plus</CustomText>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      </View>

                      {/* Description bloc */}
                      <View style={styles.descriptionContainer}>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.type}</CustomText>
                        </View>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.brand}</CustomText>
                        </View>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.size}</CustomText>
                        </View>
                        <View style={styles.descriptionBloc}>
                          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('2%') }}>{item.adjustment}</CustomText>
                        </View>
                      </View>
                    </View>
                    {/* Information */}
                    <View>
                      <TouchableOpacity activeOpacity={0.5} onPress={() => setIsModalInfo(false)}>
                        <Modal visible={isModalInfo} animationType="fade" transparent={true} onRequestClose={() => setIsModalInfo(false)}>
                          <View style={styles.modalBackground}>
                            <View style={styles.modalContainer}>

                              {selectedItemIndex !== null && (
                                <Information
                                  initiaTexture={postsWithDifferentMedia[selectedItemIndex].texture}
                                  initialThickness={postsWithDifferentMedia[selectedItemIndex].thickness}
                                  initialFluidity={postsWithDifferentMedia[selectedItemIndex].fluidity}
                                  initialComfort={postsWithDifferentMedia[selectedItemIndex].comfort}
                                  initialColor={postsWithDifferentMedia[selectedItemIndex].color}
                                  valueOnChange={(newValue) => valueOnChange(selectedItemIndex, newValue)}
                                />
                              )}

                            </View>
                          </View>
                        </Modal>
                      </TouchableOpacity>
                    </View>
                    {/* Box interaction Utilisateur */}
                    <View style={styles.iconsContainer}>
                      <View style={styles.iconsback}>
                        <View >
                          <Like likePost={Dolike} nolikePost={Nolike} postId={item.id} userId={user} />
                        </View>
                        <View >
                          <TouchableOpacity onPress={() => {
                            navigation.navigate('CommentHome', { postId: item.id });
                          }}>
                            <Message />
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginBottom: 2 }}>
                          <Star CrushPost={Docrush} NoCrushPost={Nocrush} postId={item.id} userId={user} />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>)}
            keyExtractor={(item, index) => index.toString()}
            pagingEnabled
            ListFooterComponent={renderFooter}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.1}
          />

          {/* <View style={styles.paginationContainer}>
            <TouchableOpacity
              onPress={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={[styles.button, currentPage === 1 && styles.disabledButton]}
            >
              <Text style={styles.buttonText}>Précédent</Text>
            </TouchableOpacity>
            <Text style={styles.pageText}>Page {currentPage} sur {totalPages}</Text>
            <TouchableOpacity
              onPress={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={[styles.button, currentPage === totalPages && styles.disabledButton]}
            >
              <Text style={styles.buttonText}>Suivant</Text>
            </TouchableOpacity>
          </View> */}

        </View>
        {/* <BottomBar navigation={navigation}/> */}
        <StatusBar style="auto" />
      </View>
    </ScrollView >
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollContent: {
    flexGrow: 3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  containerMorphologies: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: 6,
  },
  containerProfile: {
    flex: 1,
    flexDirection: 'column',
    maxHeight: 40,
    padding: 2,
    //width: 350,
  },
  Letters: {
    width: 18,
    height: 18,
    marginLeft: '-1pc',
    marginTop: '2pc',
    borderRadius: 15,
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProfile: {
    width: 50,
    height: 50,
  },
  imagecadre: {
    borderRadius: 20,
  },
  postImage: {
    height: 360,
    width: '100%',
    resizeMode: 'cover',
    borderRadius: 2,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    width: 305,
    borderRadius: 10,
  },
  iconsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderRadius: 5,
    marginLeft: 150,
    marginBottom: -7,
    marginTop: -68,
  },
  iconsback: {
    backgroundColor: '#debee5',
    borderWidth: 2,
    borderColor: '#debee5',
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 22,
    padding: 5,
    marginRight: -5,
    width: 30,
    justifyContent: 'space-between',
    height: '117%',
    borderRadius: 5,
    flexDirection: 'column',
  },
  iconLogo: {
    width: 17,
    height: 17,
    marginTop: '-2pc',
    marginLeft: 30,
  },
  descriptionContainer: {
    flex: 1,
    //flexWrap: 'wrap',
    alignItems: 'center',
    //justifyContent: 'center',
    width: widthPercentageToDP('100%'),
    marginBottom: 11,
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: 10,
  },
  descriptionBloc: {
    height: 20,
    flexWrap: 'wrap',
    backgroundColor: '#ECF4F2',
    minWidth: 50,
    margin: 2,
    borderRadius: 10,
    marginLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#D9E9E6',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  modalInfo: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width: 170,
    height: 250,
    left: 780,
  },
  dotsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-2pc',
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
  },
  activeDot: {
    backgroundColor: '#9002C1',
  },
  inactiveDot: {
    backgroundColor: '#E4C0F0',
  },


  paginationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  button: {
    padding: 10,
    backgroundColor: '#9002C1',
    borderRadius: 5,
    marginHorizontal: 5,
  },
  disabledButton: {
    backgroundColor: '#E4C0F0',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
  },
  pageText: {
    fontSize: 16,
    marginHorizontal: 10,
  },

});