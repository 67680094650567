import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import Modal from "react-native-modal";
import { StyleSheet, View, TouchableOpacity, Image, Dimensions, ScrollView } from 'react-native';
import Parameters from '../src/components/Parameters/Parameters';
import CustomText from '../src/components/Font/CustomText';

import { getUser } from '../src/UserManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function TutoScreen({ navigation }) {

  const [user, setUser] = useState([]);
  const [first, SetUserFirstConnexion] = useState([]);

  // 1 Media Query : 
  const windowHeight = Dimensions.get('window').height;
  const windowWidth = Dimensions.get('window').width;

  const marginTop = windowHeight >= 380 ? widthPercentageToDP('45%') : widthPercentageToDP('30%');
  // 2 Media Query :
  const windowwHeight = Dimensions.get('window').height;
  const marginBottom = windowwHeight >= 700 ? 167 : 0;

  const [isModalInfo, setIsModalInfo] = useState(false); // Déclaration d'état
  //slide
  const [statusIcon, setStatusIcon] = useState([true, false]);
  const [cursor, setCursor] = useState(0);

  //commentaire : 
  const [expanded, setExpanded] = useState(false);
  const commentText = "Nouveau Habits testez le il est juste wow !";
  const maxLength = 20;
  //0 : 
  const [firstPopupVisible, setfirstPopupVisible] = useState(false);
  //1 :
  const [ModalFirstConnection, SetModalFirstConnection] = useState(false);
  //2 :
  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  //3 : 
  const [thirdPopupVisible, setThirdPopupVisible] = useState(false);
  //4 : 
  const [fifthPopupVisible, setFifthPopupVisible] = useState(false);

  const closePopup1 = () => {
    setfirstPopupVisible(false);
    SetModalFirstConnection(true);
  }
  const closePopup = () => {
    SetModalFirstConnection(false);
    setSecondPopupVisible(true);
  };
  const closePopup2 = () => {
    setSecondPopupVisible(false);
    setThirdPopupVisible(true);
  };
  const closePopup3 = () => {
    setThirdPopupVisible(false);
    setFifthPopupVisible(true);
  };
  const closePopup4 = () => {
    setFifthPopupVisible(false);
    navigation.navigate('Parameters', { first: first });
  }

  const fetchData = () => {
    getUser()
      .then((data) => {
        setUser(data.user);
        if (data.user.first_connection === 1) {
          setfirstPopupVisible(true);
        } else {
          setfirstPopupVisible(true);
        }
        SetUserFirstConnexion(data.user.first_connection);
      })
      .catch(error => {
        console.error('Erreur des données utilisateur :', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  //slide
  function selectPrevious() {
    if (cursor - 1 >= 0) {
      const newStatusIcon = [...statusIcon];
      newStatusIcon[cursor] = false;
      newStatusIcon[cursor - 1] = true;
      setCursor(cursor - 1);
      setStatusIcon(newStatusIcon);
    }
  }

  function selectNext() {
    if (cursor + 1 < statusIcon.length) {
      const newStatusIcon = [...statusIcon];
      newStatusIcon[cursor] = false;
      newStatusIcon[cursor + 1] = true;
      setCursor(cursor + 1);
      setStatusIcon(newStatusIcon);
    }
  }

  function handleModalInfo() {
    setIsModalInfo(() => !isModalInfo)
  }

  return (
    <View style={styles.container}>
      <View style={styles.bloctuto}>
        <CustomText style={{ fontSize: heightPercentageToDP('3%'), color: '#9002C1', textAlign: 'center', marginTop: 10 }}>TUTO</CustomText>
        {firstPopupVisible && (
          <View style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <TouchableOpacity onPress={closePopup1}>
              <Modal isVisible={firstPopupVisible} transparent={true} style={{ alignItems: 'flex-start', justifyContent: 'center' }}
                onRequestClose={() => { setfirstPopupVisible(false); }}>
                <Image
                  style={{
                    height: 173, width: 181,
                    marginTop:
                      windowWidth >= 410 ? widthPercentageToDP('50%') :
                        windowWidth >= 385 ? widthPercentageToDP('65%') :
                          windowWidth >= 350 ? widthPercentageToDP('70%') :
                            windowWidth >= 310 ? widthPercentageToDP('80%') : 60
                  }}
                  source={require('../assets/pop-up-tuto-home3.svg')} />
              </Modal>
            </TouchableOpacity>
          </View>
        )}
        {ModalFirstConnection && (
          <View style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <TouchableOpacity onPress={closePopup}>
              <Modal isVisible={ModalFirstConnection} transparent={true} style={{ alignItems: 'flex-end', justifyContent: 'center' }}
                onRequestClose={() => { SetModalFirstConnection(false); }}>
                <Image style={{
                  height: 173, width: 181,
                  marginRight:
                    windowWidth >= 410 ? widthPercentageToDP('10%') :
                      windowWidth >= 385 ? widthPercentageToDP('7%') :
                        windowWidth >= 350 ? widthPercentageToDP('5%') :
                          windowWidth >= 310 ? -widthPercentageToDP('4%') : 60,
                  marginTop:
                    windowWidth >= 410 ? widthPercentageToDP('50%') :
                      windowWidth >= 385 ? widthPercentageToDP('65%') :
                        windowWidth >= 350 ? widthPercentageToDP('70%') :
                          windowWidth >= 310 ? widthPercentageToDP('80%') : 60
                }} source={require('../assets/pop-up-tuto-home.svg')} />
              </Modal>
            </TouchableOpacity>
          </View>
        )}
        {secondPopupVisible && (
          <View style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <TouchableOpacity onPress={closePopup2}>
              <Modal isVisible={secondPopupVisible} transparent={true} style={{ alignItems: 'center', justifyContent: 'center' }}
                onRequestClose={() => { setSecondPopupVisible(false); }}>
                <Image style={{
                  height: 139, width: 181,
                  marginRight:
                    windowWidth >= 410 ? -widthPercentageToDP('40%') :
                      windowWidth >= 385 ? -widthPercentageToDP('40%') :
                        windowWidth >= 350 ? -widthPercentageToDP('50%') :
                          windowWidth >= 310 ? -widthPercentageToDP('50%') : 60,
                  marginTop:
                    windowWidth >= 420 ? widthPercentageToDP('60%') :
                      windowWidth >= 380 ? widthPercentageToDP('80%') :
                        windowWidth >= 350 ? widthPercentageToDP('75%') :
                          windowWidth >= 310 ? widthPercentageToDP('85%') : 60
                }} source={require('../assets/pop-up-tuto-home2.svg')} />
              </Modal>
            </TouchableOpacity>
          </View>
        )}
        {thirdPopupVisible && (
          <View style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <TouchableOpacity onPress={closePopup3}>
              <Modal isVisible={thirdPopupVisible} transparent={true} style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}
                onRequestClose={() => { setThirdPopupVisible(false); }}>
                <Image style={{
                  height: 115, width: 197,
                  marginLeft:
                    windowWidth >= 410 ? widthPercentageToDP('5%') :
                      windowWidth >= 385 ? widthPercentageToDP('7%') :
                        windowWidth >= 350 ? widthPercentageToDP('10%') :
                          windowWidth >= 310 ? widthPercentageToDP('10%') : 60,
                  marginTop:
                    windowWidth >= 420 ? -widthPercentageToDP('2%') :
                      windowWidth >= 380 ? -widthPercentageToDP('5%') :
                        windowWidth >= 350 ? -widthPercentageToDP('5%') :
                          windowWidth >= 310 ? -widthPercentageToDP('5%') : 60
                }} source={require('../assets/pop-up-tuto-home4.svg')} />
              </Modal>
            </TouchableOpacity>
          </View>
        )}
        {fifthPopupVisible && (
          <View style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <TouchableOpacity onPress={closePopup4}>
              <Modal isVisible={fifthPopupVisible} transparent={true} style={{ alignItems: 'flex-start', justifyContent: 'center' }}
                onRequestClose={() => { setFifthPopupVisible(false); }}>
                <Image style={{
                  height: 154, width: 170,
                  marginLeft:
                    windowWidth >= 410 ? -widthPercentageToDP('12%') :
                      windowWidth >= 385 ? -widthPercentageToDP('12%') :
                        windowWidth >= 350 ? -widthPercentageToDP('12%') :
                          windowWidth >= 310 ? -widthPercentageToDP('12%') : 60,
                  marginTop:
                    windowWidth >= 420 ? -widthPercentageToDP('40%') :
                      windowWidth >= 380 ? -widthPercentageToDP('45%') :
                        windowWidth >= 350 ? -widthPercentageToDP('45%') :
                          windowWidth >= 310 ? -widthPercentageToDP('45%') : 60
                }} source={require('../assets/pop-up-tuto-home5.svg')} />
              </Modal>
            </TouchableOpacity>
          </View>
        )}
        <View style={{ borderWidth: 2, borderColor: '#E4C0F0', borderRadius: 25, padding: 5, width: '100%' }}>
          <View style={styles.containerProfile}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View>
                <Image style={styles.iconProfile} source={require('../assets/ex-profil.png')} />
              </View>
              <View style={styles.Letters}>
                <CustomText style={{ color: '#9002C1' }}>{user.morphology_calculated}</CustomText>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ marginLeft: 10 }}>
                  <CustomText>Liyan</CustomText>
                </View>
                <View style={{ marginLeft: 10 }}>
                  <TouchableOpacity style={{ width: 110, height: 25, alignItems: "center", justifyContent: "center", backgroundColor: "#9002C1", borderRadius: 4 }} activeOpacity={0.5}>
                    <CustomText style={{ color: "white", fontWeight: "bold" }}>S'abonner</CustomText>
                  </TouchableOpacity>
                </View>
                <View style={{ marginLeft: 100 }}>
                  <Parameters />
                </View>
              </View>
            </View>
            <View>
              <CustomText style={{ fontWeight: 500, fontSize: 11 }}>11 février XXXX</CustomText>
            </View>
          </View>
          <View style={styles.imageContainer}>
            <View>
              {cursor === 0 && <Image style={styles.crushImage} source={require('../assets/tuto-pic.jpg')} />}
              <View style={styles.dotsContainer}> {statusIcon.map((isActive, index) => (
                <View key={index} style={[styles.dot, isActive ? styles.activeDot : styles.inactiveDot,]} />))}
              </View>
            </View>
            <View style={{ zIndex: 2, position: 'absolute', top: '50%', marginRight: '-20pc' }}>
              <TouchableOpacity activeOpacity={0.5} onPress={selectNext}>
                <Image style={{ width: 20, height: 25 }} />
              </TouchableOpacity>
            </View>
            {/* Infos morphologie user */}
            <TouchableOpacity activeOpacity={0.5} onPress={handleModalInfo}>
              <Image style={styles.iconLogo} source={require('../assets/i-letter.svg')} />
            </TouchableOpacity>
            <Modal isVisible={isModalInfo}
              onRequestClose={() => { setIsModalInfo(false); }}>
              <View style={styles.modalInfo}>
                <TouchableOpacity activeOpacity={0.5} onPress={handleModalInfo}>
                  <Image style={{ width: 10, height: 10, position: "absolute", left: 127, top: -12 }} source={require('../assets/icon-close.svg')} />
                  <CustomText>Doux [O] --------- Rèche</CustomText>
                  <CustomText>Différent [O] --------- Fidèle</CustomText>
                  <CustomText>Fin [O] --------- Epais</CustomText>
                  <CustomText>Souple[O] --------- Droit</CustomText>
                  <CustomText>+ [O] --------- ++</CustomText>
                </TouchableOpacity>
              </View>
            </Modal>
          </View>

          {/* Box interaction Utilisateur */}
          <View style={styles.iconsContainer}>
            <View style={styles.iconsback}>
              <View>
                <Image style={{ width: 20, height: 20 }} source={require('../assets/icon-like.png')} />
              </View>
              <View>
                <Image style={{ width: 20, height: 20 }} source={require('../assets/icône_commentaire_violet.svg')} />
              </View>
              <View>
                <Image style={{ width: 20, height: 20 }} source={require('../assets/icon-star-purple-pushed.png')} />
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <CustomText style={{ fontWeight: 'bold', marginLeft: 1, marginRight: 5, marginTop: '-4pc' }}>Liyan</CustomText>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-3pc', marginLeft: 1, }}>
            {expanded ? (
              <CustomText style={{ width: 300 }}>{commentText}</CustomText>
            ) : (
              <CustomText>{commentText.substring(0, maxLength)}...</CustomText>
            )}
            {!expanded && commentText.length > maxLength && (
              <TouchableOpacity onPress={() => setExpanded(true)}>
                <CustomText style={{ color: '#C881E0', marginLeft: 5 }}>Plus</CustomText>
              </TouchableOpacity>)}
          </View>
          <View style={styles.descriptionContainer}>

            <View style={styles.descriptionBloc}>
              <CustomText style={{ color: '#9002C1' }}>Robe</CustomText>
            </View>
            <View style={styles.descriptionBloc}>
              <CustomText style={{ color: '#9002C1' }}>Habillez-moi</CustomText>
            </View>
            <View style={styles.descriptionBloc}>
              <CustomText style={{ color: '#9002C1' }}>42</CustomText>
            </View>
            <View style={styles.descriptionBloc}>
              <CustomText style={{ color: '#9002C1' }}>Ajusté</CustomText>
            </View>
          </View>
        </View>
        <View style={styles.containerMenu}>
          <TouchableOpacity style={styles.btnPlusCircle} activeOpacity={0.5}>
            <Image style={styles.plusCircle} source={require('../assets/icon-plus.png')} />
          </TouchableOpacity>
          <View style={styles.containerBottom}>
            <View style={styles.colOne}>
              <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../assets/icon-home-heart2.svg')} />
              </TouchableOpacity>
            </View>
            <View style={styles.colTwo}>
              <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../assets/icon-glass-white1.png')} />
              </TouchableOpacity>
            </View>
            <View style={styles.colThree}>
              <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../assets/icon-suit-cops.png')} //icon-globe-white.png
                />
              </TouchableOpacity>
            </View>
            <View style={styles.colFour}>
              <TouchableOpacity activeOpacity={0.5}>
                <Image style={styles.iconMenu} source={require('../assets/ex-profil.png')} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: '#E4C0F0',
    borderRadius: 25
  },
  bloctuto: {
    //maxHeight: 900,
  },
  containerProfile: {
    flex: 2,
    flexDirection: 'column',
    maxHeight: 40,
    padding: 2,
    //width: 350,
  },
  Letters: {
    width: 18,
    height: 18,
    marginLeft: '-1pc',
    marginTop: '3pc',
    borderRadius: 15,
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    marginTop: heightPercentageToDP('10%')
  },
  containerMorphologies: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 450,
    flexDirection: 'row',
    marginTop: '6px',
  },
  iconProfile: {
    width: 50,
    height: 50,
  },
  crushImage: {
    width: widthPercentageToDP('100%'),
    height: heightPercentageToDP('30%'),
    resizeMode: 'cover',
    marginTop: 5,
    borderRadius: 5,
  },
  iconsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderRadius: 5,
    marginLeft: 150,
    marginTop: 38,
    marginBottom: -25,
  },
  iconsback: {
    backgroundColor: '#debee5',
    borderWidth: 2,
    borderColor: '#debee5',
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 22,
    padding: 5,
    marginRight: -5,
    width: 30,
    justifyContent: 'space-between',
    height: '117%',
    borderRadius: 5,
    flexDirection: 'column',
  },
  iconLogo: {
    width: 15,
    height: 15,
    marginTop: -50,
    marginLeft: 20,
  },
  descriptionContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: '10px',
  },
  descriptionBloc: {
    height: 19,
    backgroundColor: '#D9E9E6',
    minWidth: 50,
    borderRadius: 10,
    marginLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#B3D2CC',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  descContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: '10px',
  },
  modalInfo: {
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250,
    borderWidth: 2,
    borderColor: '#E4C0F0',
    borderRadius: 25
  },
  dotsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },

  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
  },

  activeDot: {
    backgroundColor: '#9002C1',
  },

  inactiveDot: {
    backgroundColor: '#E4C0F0',
  },

  containerMenu: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'transparency',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%', // Utilisation de pourcentage pour la largeur
  },
  containerBottom: {
    backgroundColor: '#9002C1',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%', // Utilisation de pourcentage pour la largeur
    height: 50,
    maxHeight: 50,
    flexDirection: 'row',
  },
  plusCircle: {
    height: 40, // il était en 50
    width: 40, // il était en 50
    top: 45, // il était en 23
  },
  btnPlusCircle: {
    zIndex: 1,
  },
  iconMenu: {
    width: 30,
    height: 30,
    zIndex: 1,
  },
  colOne: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 30,
  },
  colTwo: {
    flex: 2,
    flexDirection: 'row',
    marginLeft: 20,
  },
  colThree: {
    flex: 2,
    flexDirection: 'row',
    left: 50,
  },
  colFour: {
    flex: 2,
    flexDirection: 'row',
    left: 25,
  },
});