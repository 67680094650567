import Config from "./Api_Url";

export async function getListFAQ() {
    const response = await fetch(`${Config.serverUrl}/api/faq/list`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
      alert("L'obtention de la FAQ a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
      throw new Error("FAQ List request Failed");
    }
    const data = await response.json();
    return data;
}