import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import { getUser } from '../src/UserManagement';
import { setMorphologyOpinion } from '../src/MorphologyManagement';
import Header from '../src/components/Header';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function MorphologiesScreen({ navigation }) {
  const [statusIcon, setStatusIcon] = useState([true, false, false, false]);
  const morpho = ['A', 'H', 'V', 'X'];
  const [cursor, setCursor] = useState(0);
  const [user, setUser] = useState([]);

  const fetchData = () => {
    getUser()
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        setUser(data.user);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  function selectPrevious() {
    if (cursor - 1 < 0) {
      statusIcon[cursor] = false;
      statusIcon[statusIcon.length - 1] = true;
      setCursor(statusIcon.length - 1);
      setStatusIcon(statusIcon);
    } else {
      statusIcon[cursor] = false;
      statusIcon[cursor - 1] = true;
      setCursor(cursor - 1);
      setStatusIcon(statusIcon);
    }
  }
  function selectNext() {
    if (cursor + 1 > statusIcon.length - 1) {
      statusIcon[cursor] = false;
      statusIcon[0] = true;
      setCursor(0);
      setStatusIcon(statusIcon);
    } else {
      statusIcon[cursor] = false;
      statusIcon[cursor + 1] = true;
      setCursor(cursor + 1);
      setStatusIcon(statusIcon);
    }
  }

  function handlePrevious() {
    navigation.navigate('UserType');

  }
  const handleNextFirst = () => {
    setMorphologyOpinion(morpho[cursor])
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('Welcome')
        }
      })
  };

  function handleSubmit() {
    setMorphologyOpinion(morpho[cursor])
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('Main')
        }
      })
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <CustomText style={{ fontSize: heightPercentageToDP('3%'), textTransform: 'uppercase', color: '#9002C1' }}>Morphologie</CustomText>
      </View>
      <View style={{ display: 'flex', justifyContent: 'center', marginTop: heightPercentageToDP('5%') }}>
        <View style={styles.containerMorphologies}>
          <View>
            <TouchableOpacity activeOpacity={0.5} onPress={selectPrevious}>
              <Image style={{ width: 13, height: 19, marginRight: 60 }}
                source={require('../assets/icon _arrow down_left.png')} />
            </TouchableOpacity>
          </View>
          <View>
            <View style={{ display: statusIcon[0] ? 'flex' : 'none' }}>
              <Image style={{ width: 100, height: 290 }}
                source={require('../assets/morphologieA.png')} />
            </View>
            <View style={{ display: statusIcon[1] ? 'flex' : 'none' }}>
              <Image style={{ width: 100, height: 290 }}
                source={require('../assets/morphologieH.png')} />
            </View>
            <View style={{ display: statusIcon[2] ? 'flex' : 'none' }}>
              <Image style={{ width: 100, height: 310 }}
                source={require('../assets/morphologieV.png')} />
            </View>
            <View style={{ display: statusIcon[3] ? 'flex' : 'none' }}>
              <Image style={{ width: 100, height: 290 }}
                source={require('../assets/morphologieX.png')} />
            </View>
          </View>
          <View>
            <TouchableOpacity activeOpacity={0.5} onPress={selectNext}>
              <Image style={{ width: 13, height: 19, marginLeft: 60 }}
                source={require('../assets/icon _arrow down_.png')} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.containerButtons}>
          <View style={{ display: statusIcon[0] ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', }}>
            <CustomText style={{ marginBottom: 10, fontSize: heightPercentageToDP('3%'), textAlign: 'center' }}>
              Tes hanches sont plus larges que tes épaules.
            </CustomText>
          </View>
          <View style={{ display: statusIcon[1] ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', }}>
            <CustomText style={{ marginBottom: 10, fontSize: heightPercentageToDP('3%'), textAlign: 'center' }}>
              Tes hanches et tes épaules sont alignées et ta taille est peu marquée.
            </CustomText>
          </View>
          <View style={{ display: statusIcon[2] ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', }}>
            <CustomText style={{ marginBottom: 10, fontSize: heightPercentageToDP('3%'), textAlign: 'center' }}>
              Tes épaules sont plus larges que tes hanches.
            </CustomText>
          </View>
          <View style={{ display: statusIcon[3] ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', }}>
            <CustomText style={{ marginBottom: 10, fontSize: heightPercentageToDP('3%'), textAlign: 'center' }}>
              Tes épaules et tes hanches sont alignées, avec une taille marquée voire très marquée.
            </CustomText>
          </View>
          {user.first_connection === 1 ? (
            <View style={{ borderRadius: '10%', marginLeft: -0 }}>
              <TouchableOpacity onPress={handleNextFirst} style={styles.button}>
                <CustomText style={styles.buttonText}>{"TERMINER"}</CustomText>
              </TouchableOpacity>
            </View>
          ) : (
            <View >
              <TouchableOpacity onPress={handleSubmit} style={styles.button}>
                <CustomText style={styles.buttonText}>{"Sélectionner"}</CustomText>
              </TouchableOpacity>
            </View>
          )}
          <TouchableOpacity onPress={handlePrevious} style={styles.buttonBack}>
            <CustomText style={styles.buttonText}>{"RETOUR"}</CustomText>
          </TouchableOpacity>
        </View>
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    width: '100vw',
    height: 'auto'
  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerMorphologies: {
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 300,
    flexDirection: 'row',
    marginTop: 10,
  },
  containerButtons: {
    marginTop: heightPercentageToDP('3%'),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 4,
    borderRadius: 5,
    marginTop: heightPercentageToDP('3%'),
    width: widthPercentageToDP('80%'),
    padding: 10
  },
  buttonBack: {
    backgroundColor: '#C7C7C6',
    padding: 4,
    borderRadius: 5,
    marginTop: heightPercentageToDP('3%'),
    width: widthPercentageToDP('80%'),
    padding: 10,
    marginBottom: heightPercentageToDP('10%')
  },
  buttonText: {
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'center',
    color: '#ffff',
    textTransform: 'uppercase',
  },
});