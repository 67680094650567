import React, { Component } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import CustomText from './Font/CustomText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

class ResearchDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: '',
    };
  }

  handleNavigation = () => {
    this.props.navigation.navigate('SearchPost');
  };

  render() {
    return (
      <View style={styles.button}>
        <TouchableOpacity onPress={this.handleNavigation}>
          <CustomText style={{ color: 'white', fontSize: heightPercentageToDP('3%') }}>Posts</CustomText>
        </TouchableOpacity>
        <CustomText>{this.state.searchResults}</CustomText>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#9002C1',
    padding: 8,
    borderRadius: 7,
    height: heightPercentageToDP('7%')
  },
});

export default ResearchDrop;
