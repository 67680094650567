import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity,TextInput } from 'react-native';

import { VerifiedEmail, getUser } from '../src/UserManagement';

import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function VerifiedMail({ navigation }) {

  const [email, SetUserMail] = useState([]);
  const [verification, SetVerifiedCode] = useState('');

    const fetchData = () => {
      getUser()
      .then((data) => {
        SetUserMail(data.user.email);    
      })
      .catch(error => {
        console.error('Erreur des données utilisateur :', error);
      });
    }

    function handleSubmit() {
      let user = {
        email: email,
        verification: verification,
      };
      VerifiedEmail(user)
        .then((data) => {
          if (data.error === false) {
            navigation.navigate('SuitUserType');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  
    useEffect(() => {
        fetchData();
    },[]);

return (
  <View style={styles.container}>
    <Image style={{ width: 118, height: 90 }} source={require('../assets/logoSUITUSviolet.png')} />
    <CustomText style={{fontSize: heightPercentageToDP('3%'),fontWeight: 500,color:'#9002C1',marginBottom: heightPercentageToDP('5%'), marginTop: heightPercentageToDP('5%')}}>Tape le code reçu par mail</CustomText>

      <View style={styles.sortContainer}>
        <View style={styles.contain}>
            <TextInput style={styles.textinput}
            placeholderTextColor="gray"
            placeholder="Tape le code"
            onChangeText={SetVerifiedCode} 
            />
            <TouchableOpacity onPress={handleSubmit} style={styles.button}>
              <CustomText style={styles.buttonText}>{"INSCRIPTION"}</CustomText>
            </TouchableOpacity>
        </View>
      </View>
  </View>

);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: widthPercentageToDP('4%'),
    marginTop: heightPercentageToDP('10%')
  },
  sortContainer: {
    flexDirection: 'row',
    maxHeight: 60,
    justifyContent: 'space-between',
  },
  contain: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column', 
  },
  textinput: {
    width: widthPercentageToDP('80%'),
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: widthPercentageToDP('4%'),
    fontSize: heightPercentageToDP('3%'),
    marginBottom: heightPercentageToDP('1.5%'),
    marginTop: heightPercentageToDP('2%'),
    backgroundColor: '#fff',
  },
  button: {
    backgroundColor: '#9002C1',
    padding: heightPercentageToDP('2%'),
    borderRadius: 8,
    marginBottom: heightPercentageToDP('1%'),
    marginTop: heightPercentageToDP('2%'),
    width: widthPercentageToDP('50%'),
    height: heightPercentageToDP('7%'),
  },
  buttonText: {
    fontWeight: '600',
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff',
  },
});