import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import CustomText from './Font/CustomText';
import BoldText from './Font/BoldText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function BoxFAQ(props) {
  const [status, setStatus] = useState(false)

  function changeStatus() {
    setStatus(!status)
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={changeStatus} activeOpacity={0.5}>
        <BoldText style={{ fontSize: heightPercentageToDP('3%'), textAlign: 'justify', lineHeight: heightPercentageToDP('5%') }}>{props.question}</BoldText>
      </TouchableOpacity>
      <CustomText style={{ display: status ? 'flex' : 'none', marginTop: 5, fontSize: heightPercentageToDP('3%'), textAlign: 'justify', lineHeight: heightPercentageToDP('5%') }}>{props.answer}</CustomText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f5e7f9',
    width: 350,
    padding: 15,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#9002C1',
    marginTop: 10,
  },
});