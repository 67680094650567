import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, View, FlatList, Image, TouchableOpacity, ActivityIndicator, Text } from 'react-native';

// Retour :
import Head from '../src/components/Head';

// Data :
import { getNotifToken, supprimer } from '../src/NotificationsManagement';
import { getListUsers } from '../src/UserManagement';

// Font :
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

import { getUser } from '../src/UserManagement';

export default function NotificationsScreen({ navigation }) {

  const profileImage = 'ex-profil.png';
  const [notif, setUserNotif] = useState([]);
  const [tall, setUserTall] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    getNotifToken()
      .then((data) => {
        setLoading(false);
        setUserNotif(data.notifications);
      })
      .catch(error => {
        console.log(error);
      });
    getUser()
      .then((data) => {
        setUserTall(data.user);
      })
      .catch(error => {
        console.error('Erreur des données utilisateur :', error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const maintenant = new Date();
    const difference = maintenant - date;
    if (difference < 60000) {
      // - d'une min
      return "il y a quelques secondes";
    } else if (difference < 3600000) {
      // - d'une h
      const minutes = Math.floor(difference / 60000);
      return `il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (difference < 86400000) {
      // - d'un j
      const heures = Math.floor(difference / 3600000);
      return `il y a ${heures} heure${heures > 1 ? 's' : ''}`;
    } else if (difference < 2592000000) {
      // - de 30 j
      const jours = Math.floor(difference / 86400000);
      return `il y a ${jours} jour${jours > 1 ? 's' : ''}`;
    } else {
      // + de 30 j
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('fr-FR', options);
    }
  }


  return (
    <View style={styles.container}>
      <Head titre="Mes Suit'Notifs" navigation={navigation} />
      {
        loading && <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#9002C1" />
          <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
        </View>
      }
      {notif.length === 0 ? (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <CustomText style={{ TextAlign: 'center', fontSize: heightPercentageToDP('3%'), color: '#9002C1', marginTop: heightPercentageToDP('3%'), textAlign: 'center' }}>
            Pas de notification
          </CustomText>
        </View>
      ) : (
        <FlatList
          data={notif.reverse()}
          renderItem={({ item, index }) => {
            return (
              <View key={index}>
                <View style={{ flexDirection: 'column', marginTop: heightPercentageToDP('3%') }}>
                  {item.user_id ? (
                    <TouchableOpacity onPress={() => {
                      navigation.navigate('UserProfile', { iduser: item.user_id });
                    }}>
                      <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                        {item.id_sender && (<>
                          <View style={{ flexDirection: 'column', marginLeft: 10, marginRight: 20, border: "1px solid rgb(144, 2, 193)", borderRadius: 20, padding: 10, backgroundColor: 'white' }}>
                            <View style={{ flexDirection: 'row' }}>
                              <View style={{ borderRadius: 20 }}>
                                {tall.profile_image === profileImage ?
                                  (<Image style={styles.ppfollow} source={require('../assets/' + profileImage)} />)
                                  :
                                  (<Image style={styles.ppfollow} source={{ uri: tall.profile_image }} />)
                                }
                              </View>
                              <View style={{ marginLeft: 10 }}>
                                <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), fontWeight: 'bold' }}>{tall.pseudo}</CustomText>
                                <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('1.5%'), marginBottom: heightPercentageToDP('1.5%') }}>{formatDate(item.created_date)}</CustomText>
                              </View>
                            </View>
                            <CustomText style={{marginLeft: 64, fontSize: heightPercentageToDP('2.5%'), lineHeight: heightPercentageToDP('4%'), textAlign: 'justify' }}>{item.body}</CustomText>
                          </View>
                        </>
                        )}
                      </View>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={() => {
                      navigation.navigate('Post', { postId: item.post_id });
                    }}>
                      <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                        {item.id_sender && (<>
                          <View style={{ flexDirection: 'column', marginLeft: 10, marginRight: 20, border: "1px solid rgb(144, 2, 193)", borderRadius: 20, padding: 10, backgroundColor: 'white' }}>
                            <View style={{ flexDirection: 'row' }}>
                              <View style={{ borderRadius: 20 }}>
                                {tall.profile_image === profileImage ?
                                  (<Image style={styles.ppfollow} source={require('../assets/' + profileImage)} />)
                                  :
                                  (<Image style={styles.ppfollow} source={{ uri: tall.profile_image }} />)
                                }
                              </View>
                              <View style={{ marginLeft: 10 }}>
                                <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), fontWeight: 'bold' }}>{tall.pseudo}</CustomText>
                                <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), marginTop: heightPercentageToDP('1.5%'), marginBottom: heightPercentageToDP('1.5%') }}>{formatDate(item.created_date)}</CustomText>
                              </View>
                            </View>
                            <CustomText style={{ marginLeft: 64, fontSize: heightPercentageToDP('2.5%'), lineHeight: heightPercentageToDP('4%'), textAlign: 'justify' }}>{item.body}</CustomText>
                          </View>
                        </>
                        )}
                      </View>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            );
          }}
          keyExtractor={(item, index) => index.toString()}
        />
      )}

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
  },
  ppfollow: {
    height: 55,
    width: 55,
    borderRadius: 60,
  },
});