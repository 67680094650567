import Config from "./Api_Url";

export async function favorite(postId, userId) {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/favorite/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ crush: { user_id: userId, postId: postId } })
    });
    if (!response.ok) {
      console.log(response);
      alert("La création du favoris de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Like creation Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function iscrushing(postId) {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/favorite/iscrushing/list`, {
      method: "POST",
      headers: { 'Accept': 'application/json',
                 'Content-Type': 'application/json' },
      body: JSON.stringify({ postId: postId, token: token })
  });

  if (!response.ok) {
    console.log(response);
    alert("La récupération du like de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Like deletion Request Failed");
  }
  const data = await response.json();
  return data;

}

export async function disfavored(postId, userId) {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/favorite/delete`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ crush: {user_id: userId, postId: postId} })
    });
    if (!response.ok) {
      console.log(response);
      alert("La suppression du favoris de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Like deletion Request Failed");
    }
    const data = await response.json();
    return data;
}