import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';

export default function ParametersProfil({ onMenuToggle }) {
  const [isSelected, setSelection] = useState(false);
  const [filename, setFilename] = useState('parameters_post.svg');

  const handleParameters = () => {
    setSelection(!isSelected);
    if (isSelected === false) {
      setFilename('parameters_post.svg');
    } else {
      setFilename('parameters_post.svg');
    }
    onMenuToggle();
  };

  // Créez un objet qui mappe les noms de fichier aux chemins d'image
  const imageMap = {
    parameters_post: require('../../../assets/parameters_post.svg').default,
    // Ajoutez d'autres images ici selon les besoins
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity activeOpacity={0.5} onPress={handleParameters}>
        {/* Utilisez la variable filename pour sélectionner le chemin de l'image */}
        <Image
          style={{ width: 7, height: 37 }}
          source={require('../../../assets/parameters_post.svg')}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
