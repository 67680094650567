import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import { setSuitUserTypeScreen } from '../src/UserManagement';
import Modal from "react-native-modal";

// Font :
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function SuitUserTypeScreen({ navigation }) {
  const [status, setStatus] = useState('User');

  const handleModelBloc = () => {
    setStatus("Model");
    setSuitUserTypeScreen(status, 1)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('InfluencerType')
        }
      })
  };

  const handleUserBlocs = () => {
    setStatus("User");
    setSuitUserTypeScreen(status, 0)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('UserType')
        }
      })
  };
  const [isModalwarning, setIsModalwarning] = useState(false);

  function handleModalwarning() {
    setIsModalwarning(() => !isModalwarning)
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <CustomText style={{ fontSize: heightPercentageToDP('3.5%'), color: '#9002C1', textTransform: 'uppercase' }}>Choisis ton statut</CustomText>
      </View>

      <View style={styles.containerSuitUserType}>
        <View style={{ marginTop: heightPercentageToDP('4%'), zIndex: 3, position: 'absolute', marginLeft: widthPercentageToDP('85%') }}>
          <TouchableOpacity onPress={handleModalwarning} activeOpacity={0.5} style={{ width: 20, HeightfontWeight: 450, height: 20, borderRadius: 40, backgroundColor: '#C881E0', justifyContent: 'center', alignItems: 'center' }}>
            <CustomText style={{ fontSize: 16, fontWeight: 500, color: '#FEFEFE' }}>{"!"}</CustomText>
          </TouchableOpacity>
          <Modal isVisible={isModalwarning} style={{ justifyContent: 'center', alignItems: 'center' }} 
            onRequestClose={() => { setIsModalwarning(false); }}>
            <View style={styles.Modalwarning}>
              <TouchableOpacity activeOpacity={0.5} onPress={handleModalwarning} style={{ alignItems: 'center' }}>
                <CustomText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 600, color: '#000', marginBottom: 4, lineHeight: heightPercentageToDP('5%') }}>
                Munis-toi de ton mètre ruban pour cette étape </CustomText>
                <Image style={{ width: 173, height: 39, marginTop: 40, marginBottom: 40 }}
                  source={require('../assets/metre-ruban.png')}
                />
              </TouchableOpacity>
            </View>
          </Modal>
        </View>
        <View style={styles.leftContainer}>
          <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', marginTop: 16 }} activeOpacity={0.5} onPress={handleModelBloc}>
            <BoldText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold', marginTop: heightPercentageToDP('2%'), color: '#ffff', textTransform: 'uppercase' }}>SuitUser modèle</BoldText>
            <CustomText style={{ fontSize: heightPercentageToDP('2.5%'), fontWeight: 600, color: '#ffff', marginBottom: 3, textAlign: 'justify', padding: 20, lineHeight: heightPercentageToDP('5%') }}>
            Tu es un membre actif de la communauté. Tu souhaites partager des photos et échanger avec la communauté ?</CustomText>
          </TouchableOpacity>
        </View>
        <View style={styles.rightContainer}>
          <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', marginTop: 22 }} activeOpacity={0.5} onPress={handleUserBlocs}>
            <BoldText style={{ fontSize: heightPercentageToDP('3%'), fontWeight: 'bold', marginTop: heightPercentageToDP('2%'), color: '#ffff', textTransform: 'uppercase' }}>Suituser</BoldText>
            <CustomText style={{ fontSize: heightPercentageToDP('2.5'), fontWeight: 600, color: '#ffff', marginBottom: 3, textAlign: 'justify', padding: 20, lineHeight: heightPercentageToDP('5%') }}>
            Tu souhaites accéder aux informations partagées par la communauté ? </CustomText>
          </TouchableOpacity>
        </View>
        <View style={{ alignItems: 'center', marginTop: 8, width: widthPercentageToDP('90%') }}>
          <CustomText style={{ textAlign: 'justify', fontSize: heightPercentageToDP('2.5'), color: 'red',
          fontWeight: 'bold', lineHeight: heightPercentageToDP('5%'), marginBottom: heightPercentageToDP('10%') }}>*Tu peux changer ton statut à tout moment en allant dans tes paramètres</CustomText>
        </View>
      </View>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',

  },
  headerSuitUserType: {
    backgroundColor: '#d9e9e6',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 20
  },
  containerSuitUserType: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 20,
    borderColor: '#9002C1',
    backgroundColor: '#9002C1',
    marginBottom: 15,
    marginTop: heightPercentageToDP('5%'),
    width: widthPercentageToDP('90%'),
  },
  rightContainer: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#9002C1',
    backgroundColor: '#9002C1',
    width: widthPercentageToDP('90%'),

  },
  Modalwarning: {
    backgroundColor: 'white',
    borderWidth: 2,
    borderRadius: 15,
    borderColor: '#E4C0F0',
    justifyContent: 'center',
    alignContent: 'center',
    width: widthPercentageToDP('80%'),
    padding: 20
  },
});