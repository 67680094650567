import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, TextInput } from 'react-native';
import { checkEmail } from '../src/UserManagement';
import { TouchableOpacity } from 'react-native-gesture-handler';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function RequestEmailScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function handleSubmit() {
    checkEmail(email)
      .then((data) => {
        return data;
      }).catch(error => {
        setErrorMessage(error.message);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('ForgotPassword', {
            email: email,
          })
        }
      })
  }
  return (
    <View style={styles.container}>
      <Image style={{ width: 118, height: 90, marginTop: '3vh' }} source={require('../assets/logoSUITUSviolet.png')} />
      <CustomText style={{ fontSize: 19, fontWeight: 500, color: '#9002C1', marginTop: heightPercentageToDP('3%') }}>Nouveau mot de passe</CustomText>
      <View style={styles.formContainer}>
        <View style={{ marginBottom: heightPercentageToDP('3%'), alignItems: 'center' }}>
          <CustomText style={{ color: '#9002C1', fontSize: heightPercentageToDP('3%'), textAlign: 'justify', lineHeight: heightPercentageToDP('5%') }}>Saisis l’adresse électronique associée au compte</CustomText>
        </View>
        <View style={styles.contain}>
          <TextInput style={styles.input}
            placeholder="Email"
            onChangeText={setEmail}
            placeholderTextColor="gray"
          />
          <TouchableOpacity onPress={handleSubmit} style={styles.button}>
            <CustomText style={styles.buttonText}>{"VALIDATION"}</CustomText>
          </TouchableOpacity>
        </View>
        {errorMessage && (
          <CustomText style={{ color: 'red', fontSize: heightPercentageToDP('2%'), fontWeight: 'bold', textAlign: 'center' }}>{errorMessage}</CustomText>
        )}
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({

  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contain: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: widthPercentageToDP('80%'),
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: widthPercentageToDP('4%'),
    fontSize: heightPercentageToDP('3%'),
    marginBottom: heightPercentageToDP('1.5%'),
    marginTop: heightPercentageToDP('2%'),
    backgroundColor: '#ECF4F2',

  },
  button: {
    backgroundColor: '#9002C1',
    padding: heightPercentageToDP('2%'),
    borderRadius: 8,
    marginBottom: heightPercentageToDP('1%'),
    marginTop: heightPercentageToDP('2%'),
    width: widthPercentageToDP('50%'),
    height: heightPercentageToDP('7%'),

  },
  buttonText: {
    fontWeight: 600,
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  formContainer: {
    width: widthPercentageToDP('90%'),
    backgroundColor: '#FEFEFE',
    marginBottom: 20,
    marginTop: 15,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
});