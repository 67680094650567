import Config from "../Api_Url";

export async function getListDressSizes() {
    const response = await fetch(Config.serverUrl+'/api/dropdowns/dresssizes/list', {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': `${Config.serverUrl}`,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
      alert("L'obtention des listes de tailles des vêtements a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
      throw new Error("ListDressSizes request Failed");
    }
    const data = await response.json();
    return data;
}