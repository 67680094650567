import React from 'react';
import Header from '../src/components/Header';
import { StyleSheet, Text, View} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';


export default function BeginConfigurationScreen({ navigation }) {
  let token = localStorage.getItem("sessionToken");
  //console.log(token);

  const handleNext = () => {
    navigation.navigate('SuitUserType')
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerSuitUserType}>
        <Text style={{fontSize: '20px'}}>Information:</Text>
      </View>
      <View style={styles.containerSuitUserType}>
        <Text style={{fontSize: '16px',fontWeight: 500}}>CLIQUE SUR LE BOUTON CI-DESSOUS </Text>
        <Text style={{fontSize: '16px',fontWeight: 500}}>POUR DEBUTER LA CONFIGURATION </Text>
        <Text style={{fontSize: '16px', marginBottom: '60px',fontWeight: 500}}>DE TON PROFIL</Text>

        <TouchableOpacity onPress={handleNext} style={styles.button}>
          <Text style={styles.buttonText}>{"COMMENCER"}</Text>
      </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    width:'100vw',
    height:'auto'
    
  },
  button: {
    backgroundColor: '#9002C1',
    padding: 10,
    borderRadius: 8,
    marginBottom: 5,
    marginTop: 5,
    width:'160px',
    height:'40px',
    
  },
  buttonText: {
    fontWeight:600,
    fontSize: 16,
    textAlign: 'center',
    color:'#ffff'
  },
  headerSuitUserType: {
    backgroundColor: '#B3D2CC',
    alignItems: 'center',
    justifyContent: 'center',
    width: '375px',
    height:'42px'
  },
  containerSuitUserType: {
    flex: 1,
    height: 400,
    maxHeight: 400,
    alignItems: 'center',
    justifyContent: 'center',
  },
});