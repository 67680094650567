import React, { useState, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View, Image} from 'react-native';

import CustomText from './Font/CustomText';
import BoldText from './Font/BoldText';
import { heightPercentageToDP } from 'react-native-responsive-screen';

export default function Head (props) {

  const { navigation } = props;

  return (

    <View style={styles.container}>
        <View style={styles.colOne}>
          <TouchableOpacity onPress={() => { navigation.goBack();}}>
            <Image style={styles.imageSuitUs} source={require('../../assets/icon-retour.svg')}/>
          </TouchableOpacity>
           <CustomText style={{fontSize: '20px', color: '#FEFEFE',textTransform:'uppercase',marginLeft:19}}>{props.titre}</CustomText>
        </View>
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#9002C1',
    justifyContent:'center',
    width: '100%',
    padding: 5
  },
  imageSuitUs: {
    width:12,
    height:21,
    marginLeft:19,
  },
  colOne:  {
    flexDirection: 'row',
    paddingTop: heightPercentageToDP('2%'),
    paddingBottom: heightPercentageToDP('2%')
  },
  /////////second menu///////////
});