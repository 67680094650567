import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TextInput, Image, TouchableOpacity, ScrollView, FlatList, ActivityIndicator } from 'react-native';
import Head from '../src/components/Head';
import { getFavoritePosts } from '../src/PostManagement';
import Config from '../src/Api_Url.js';
import { Video } from 'expo-av';

// Text :
import BoldText from '../src/components/Font/BoldText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function FavoritesScreen({ navigation }) {

  const [favorites, setFavorites] = useState([]);
  const [rien, setNoFavorites] = useState(false);
  const [loading, setLoading] = useState(false);

  const filterMediaByUniquePostId = (favorites) => {
    const uniqueMediaDict = {};
    favorites.forEach(item => {
      const postId = item.post_id;
      if (!uniqueMediaDict[postId]) {
        uniqueMediaDict[postId] = item;
      }
    });
    return Object.values(uniqueMediaDict);
  };

  const fetchData = () => {
    setLoading(true)
    getFavoritePosts()
      .then((data) => {
        const filteredMedia = filterMediaByUniquePostId(data.favorites);
        setFavorites(filteredMedia);
        if (filteredMedia.length === 0) {
          setNoFavorites(true);
        } else {
          setNoFavorites(false);
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error);
        setLoading(false)
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (

    <View style={styles.container}>
      <Head titre="Mes Suit'Crush" navigation={navigation} />

      {
        loading && <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#9002C1" />
          <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
        </View>
      }
      {rien ? (<BoldText style={{ color: '#9002C1', marginBottom: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('3%'), fontSize: heightPercentageToDP('3%') }}>Vous n'avez pas de Suit'Crush</BoldText>) : (
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <FlatList
            data={favorites.sort((a, b) => { return b.post_id - a.post_id })}
            numColumns={3}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <View key={index} style={{ margin: 1, width: widthPercentageToDP('30%') }}>
                <TouchableOpacity onPress={() => { navigation.navigate('PostUserScreen', { postId: item.post_id, iduser: item.user_id }); }}>
                  {
                    item?.format?.includes("image") ?
                      <Image style={styles.postImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                      : item?.format?.includes("video") ?
                        <Video
                          shouldPlay
                          source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                          style={styles.postImage}
                          useNativeControls
                          resizeMode="cover"
                        /* onReadyForDisplay={videoData => {
                          videoData.srcElement.style.position = "initial"
                        }} */
                        />
                        : <Image style={styles.postImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                  }
                </TouchableOpacity>
              </View>
            )}
          />
        </ScrollView>
      )}

      {/* <BottomBar navigation={navigation}/> */}
      <StatusBar style="auto" />
    </View>


  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  scrollContainer: {
    flexGrow: 1, // Permet à ScrollView de s'étirer en fonction du contenu
    alignItems: 'flex-start',
  },
  containcrush: { //Ce qui contient les images 
    flex: 1,
    backgroundColor: '#EDEDED',
    width: 360,
    borderRadius: '1pc',
    marginTop: heightPercentageToDP('3%'),
  },
  imageWrapper: {
    width: '32%', // Les images prennent chacune la moitié de l'espace  
    paddingHorizontal: 2, // Ajout de marges horizontales
  },
  postImage: {
    height: 200,
    width: '100%',
    resizeMode: 'cover',
    borderRadius: 2,
  },
  image: {
    width: '100%',
    height: 220,
    aspectRatio: 0.51,
    resizeMode: 'cover',
    borderRadius: '0.4pc',
  },
});