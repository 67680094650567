import Config from "./Api_Url";

export async function getNotifToken() {
  let token = localStorage.getItem("sessionToken");
  const response = await fetch(`${Config.serverUrl}/api/notification/list/`+ token, {
      method: "GET",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }
  });
  if (!response.ok) {
    //console.log(response);
    throw new Error("Like creation Request Failed");
  }
  const data = await response.json();
  return data;
}

export async function modifyNotif(newNotif) {
    const response = await fetch(`${Config.serverUrl}/api/notification/modify`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ newNotif: newNotif })
    });
  
    if (!response.ok) {
      console.log(response);
      throw new Error("Like creation Request Failed");
    }
  
    const data = await response.json();
    return data;
}

export async function supprimer() {
    const users = { id: id };
    const response = await fetch(`${Config.serverUrl}/api/notification/delete`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({users: users})
    });
    if (!response.ok) {
      alert("L'envoi du unfollow a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Unfollow Request Failed");
    }
    const data = await response.json();
    return data;
  }


  