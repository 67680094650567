import Config from "./Api_Url";

export async function like(postId, userId) {
  let token = localStorage.getItem("sessionToken");
  const response = await fetch(`${Config.serverUrl}/api/like/create`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ like: { user_id: userId, postId: postId } })
  });

  if (!response.ok) {
    console.log(response);
    alert("La création du like de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Like deletion Request Failed");
  }
  const data = await response.json();
  return data;
}

export async function dislike(postId, userId) {
  let token = localStorage.getItem("sessionToken");
  const response = await fetch(`${Config.serverUrl}/api/like/delete`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ like: { user_id: userId, postId: postId } })
  });
  if (!response.ok) {
    console.log(response);
    alert("La suppression du like de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Like deletion Request Failed");
  }
  const data = await response.json();
  return data;
}

export async function isliking(postId, userId) {
  let token = localStorage.getItem("sessionToken");
  const response = await fetch(`${Config.serverUrl}/api/like/isliking/list`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ postId: postId, token: token, userId: userId })
  });

  if (!response.ok) {
    console.log(response);
    alert("La récupération du like de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
    throw new Error("Like deletion Request Failed");
  }
  const data = await response.json();
  return data;

}

export async function getAllLikes() {
  const response = await fetch(`${Config.serverUrl}/api/like/liked/list`, {
    method: "GET",
    headers: {
      'Access-Control-Allow-Origin': Config.serverUrl,
      'Content-Type': 'application/json'
    },
  });
  if (!response.ok) {
    alert("Tout les likes selon les postes : " + JSON.stringify(response.status) + '\n' + response.statusText);
    throw new Error("HomePosts request Failed");
  }
  const data = await response.json();
  return data;
}