import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Image, View } from 'react-native';

import CustomText from '../src/components/Font/CustomText';

export default function NotFoundScreen() {
  return (
    <View style={styles.container}>

      <View style={{  borderWidth: 2, borderRadius: 15,  borderColor:'#B3D2CC',alignItems:'center'}}>
        <Image style={{height:192, width:293}} source={require('../assets/undraw-page-not-found.svg')} />
      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
    justifyContent: 'center',
  },
});