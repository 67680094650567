import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TextInput, Image, FlatList, TouchableOpacity, Modal, Text, ScrollView, ActivityIndicator } from 'react-native';
import { getListUsers, getUser } from '../src/UserManagement';
import CustomText from '../src/components/Font/CustomText';
import ResearchDrop from '../src/components/ResearchDropdown';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function SearchScreen({ navigation, route }) {

  const second = route.params?.second;
  // console.log(second)
  const profileImage = 'ex-profil.png';

  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);

  // 1 :
  const [ModalFirstConnection, SetModalFirstConnection] = useState(false);
  //2 :
  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const closePopup = () => {
    SetModalFirstConnection(false);
    setSecondPopupVisible(true);
  };
  const closePopup2 = () => {
    setSecondPopupVisible(false);
    navigation.navigate('Main');
  };

  const fetchData = () => {
    setLoading(true);
    getListUsers()
      .then((data) => {
        setLoading(false);
        setUsers(data.users);
        setOriginalUsers(data.users);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    getUser()
      .then((data) => {
        setUser(data.user);
        // console.log('pas 1er connexion',data.user.first_connection);
        if (data.user.first_connection === 1) {
          SetModalFirstConnection(true);
        } else {
          //console.log('pas 1er connexion');
        }
      })
      .catch(error => {
        console.error('Erreur des données utilisateur :', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterUsers = (query) => {
    if (query == '') {
      // Si la recherche est vide, affichez les données d'origine
      setUsers(originalUsers);
    } else {
      const filteredUsers = originalUsers.filter((user) =>
        user.firstname.toLowerCase().includes(query.toLowerCase()) ||
        user.pseudo.toLowerCase().includes(query.toLowerCase()) ||
        user.lastname.toLowerCase().includes(query.toLowerCase())
      );
      setUsers(filteredUsers);
    }
  };

  useEffect(() => {
    filterUsers(search);
  }, [search]);

  return (
    <ScrollView style={styles.container} contentContainerStyle={{alignItems: 'center'}}>
      {ModalFirstConnection && (
        <Modal isVisible={ModalFirstConnection} transparent={true} style={{ alignItems: 'flex-start', justifyContent: 'flex-start', }}
          onRequestClose={() => { SetModalFirstConnection(false); }}>
          <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.2)', justifyContent: 'flex-start', alignItems: 'flex-start', }}>
            <TouchableOpacity onPress={closePopup}>
              <Image style={{ height: 120, width: 148, marginRight: '1pc' }} source={require('../assets/pop-up-tuto-search1.svg')} />
            </TouchableOpacity>
          </View>
        </Modal>
      )}
      {secondPopupVisible && (
        <Modal isVisible={secondPopupVisible} transparent={true} style={{ alignItems: 'flex-end', justifyContent: 'flex-start' }}
          onRequestClose={() => { setSecondPopupVisible(false); }}>
          <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.2)', justifyContent: 'flex-start', alignItems: 'flex-end', }}>
            <TouchableOpacity onPress={closePopup2}>
              <Image style={{ height: 121, width: 208, marginTop: '6pc', marginRight: '1pc' }} source={require('../assets/pop-up-tuto-search2.svg')} />
            </TouchableOpacity>
          </View>
        </Modal>
      )}
      <CustomText style={{
        fontSize: heightPercentageToDP('3%'), color: '#9002C1', marginTop: heightPercentageToDP('3%'),
        marginBottom: heightPercentageToDP('3%'), textTransform: 'uppercase'
      }}>Suit'Search</CustomText>
      <View style={{ flexDirection: 'row', justifyContent: 'center', width: widthPercentageToDP('90%') }}>
        <View style={styles.contain}>
          <View style={styles.searchContainer}>
            <TouchableOpacity activeOpacity={0.5}>
              <Image source={require('../assets/icon-glass-white.png')} style={styles.searchIcon} />
            </TouchableOpacity>
            <TextInput
              style={styles.textinput}
              placeholderTextColor="black"
              placeholder="Recherche un SuitUser"
              onChangeText={setSearch} // Effectuer la recherche à chaque changement de texte
            />
          </View>
        </View>
        <View style={{ marginLeft: 9, marginTop: 9 }}>
          <ResearchDrop navigation={navigation} />
        </View>
      </View>
      {
        loading && <View style={styles.loaderContainer}>
          <ActivityIndicator size="large" color="#9002C1" />
          <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
        </View>
      }
      {users.length > 0 ? (
        <View style={{ width: widthPercentageToDP('90%') }}>
          <FlatList
            data={users}
            keyExtractor={item => item.id}
            numColumns={2}
            renderItem={({ item }) => (
              <View style={styles.item}>
                <TouchableOpacity onPress={() => {
                  navigation.navigate('UserProfile', { iduser: item.id });
                }}>
                  {item.profile_image === profileImage ? (
                    <Image style={styles.image} source={require(`../assets/${profileImage}`)} />
                  ) : (
                    <Image source={{ uri: item.profile_image }} style={styles.image} />
                  )}
                  <Text style={styles.title}>{item.pseudo}</Text>
                  <Text style={styles.subtitle}>{item.lastname}</Text>
                </TouchableOpacity>
              </View>
            )}
          />
          </View>
      ) :
        <Text style={{
          marginTop: heightPercentageToDP('3%'),
          fontSize: heightPercentageToDP('3%'),
          fontWeight: 'bold',
          textAlign: 'center',
        }}>Aucun utilisateur trouvé</Text>
      }
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    //alignItems: 'center',
    padding: widthPercentageToDP('3%'),
  },
  contain: {
    alignItems: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    opacity: 0.2,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 15,
    width: widthPercentageToDP('70%'),
  },
  searchIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  textinput: {
    width: '85%',
    height: heightPercentageToDP('7%'),
    borderRadius: 5,
    padding: widthPercentageToDP('4%'),
    fontSize: heightPercentageToDP('3%'),
    marginBottom: heightPercentageToDP('1.5%'),
    marginTop: heightPercentageToDP('2%'),
    backgroundColor: '#ECF4F2',
    border: "1px solid black",
    color: 'black'
  },
  item: {
    flex: 1,
    margin: 5,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    borderRadius: 5,
  },
  image: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  title: {
    fontSize: heightPercentageToDP('2.5%'),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: heightPercentageToDP('3%'),
    color: '#555',
    textAlign: 'center',
  },
});
