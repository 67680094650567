import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, Text, TextInput, View, Button, Image } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { resetPassword } from '../src/UserManagement';

// Montserrat : 
import CustomText from '../src/components/Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function ForgotPasswordScreen({ route, navigation }) {
  const { email } = route.params;

  const [message, setMessage] = useState(''); // État pour le message

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to validate the password
  const validatePassword = (text) => {
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(text);
    const hasNumber = /\d/.test(text);
    const hasUpperCase = /[A-Z]/.test(text);

    const isValid = hasSpecialChar && hasNumber && hasUpperCase && text.length >= 8;
    setPasswordIsValid(isValid);
  };

  function checkPassword() {
    if (password === confirmPassword) {
      return (true);
    } else {
      return (false);
    }
  }

  function handleSubmit() {
    if (checkPassword() === false) {
      setMessage("Les mots de passe ne sont pas les mêmes");
      return;
    }

    let user = { email: email, password: password };

    resetPassword(user)
      .then((data) => {
        return data;
      }).catch(error => {
        console.log(error);
      }).then(data => {
        if (data.error === false) {
          navigation.navigate('Login');
        }
      })
  }

  return (
    <View style={styles.container}>
     <Image style={{ width: 118, height: 90, marginTop: '3vh' }} source={require('../assets/logoSUITUSviolet.png')} />
      <CustomText style={{ color: '#9002C1', marginTop: heightPercentageToDP('5%'), fontSize: heightPercentageToDP('3%'), fontWeight: '500', textTransform: 'uppercase', textAlign: 'center' }}>Réinitialiser mon mot de passe</CustomText>
      <View style={styles.formContainer}>

        <View style={styles.contain}>
          <TextInput
            style={{
              width: widthPercentageToDP('90%'), height: heightPercentageToDP('7%'), borderRadius: 5, padding: 10, fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('3%'),
              backgroundColor: '#ECF4F2'
            }}
            placeholderTextColor="gray"
            placeholder="Mot de passe"
            secureTextEntry={!showPassword}
            onChangeText={(text) => {
              setPassword(text);
              validatePassword(text);
            }}
          />
          <TouchableOpacity onPress={togglePasswordVisibility} style={styles.toggleButton}>
            {showPassword ? (
              <Image style={{ height: 10, width: 17, }} source={require('../assets/See.svg')} />
            ) : (
              <Image style={{ height: 11, width: 16, }} source={require('../assets/Hide.svg')} />
            )}
          </TouchableOpacity>

        </View>
        <View style={styles.contain}>
          <TextInput
            style={styles.input}
            placeholder="Confirmer mot de passe"
            placeholderTextColor="gray"
            secureTextEntry={true}
            onChangeText={setConfirmPassword}
          />
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', }}>
          {password.length > 0 && !passwordIsValid && (
            <CustomText style={{ color: 'red', fontSize: heightPercentageToDP('2%'), width: widthPercentageToDP('90%') }}>
              Le mot de passe doit contenir au moins 8 caractères, incluant un caractère spécial, un chiffre et une lettre majuscule.
            </CustomText>
          )}
        </View>
        <TouchableOpacity style={styles.button} onPress={handleSubmit}>
          <Text style={styles.buttonText}>{"VALIDATION"}</Text>
        </TouchableOpacity>

        <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: heightPercentageToDP('3%') }}>
          <CustomText style={{ textTransform: 'uppercase', color: "#9002C1", fontSize: heightPercentageToDP('2%') }}>{message}</CustomText>
        </View>

      </View>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#FEFEFE',
    flex: 1,
  },
  contain: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textinput: {
    height: '20%',
    width: '75%',
    marginBottom: '15px',
    borderRadius: '5px',
    backgroundColor: '#ECF4F2',
  },

  input: {
    width: '100%',
    height: 40,
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    marginBottom: '10px',
    marginTop: '10px',
    backgroundColor: '#ECF4F2'
  },
  toggleButton: {
    marginLeft: -26,
    marginTop: 14,
  },
  button: {
    backgroundColor: '#9002C1',
    padding: heightPercentageToDP('2%'),
    borderRadius: 8,
    marginBottom: heightPercentageToDP('1%'),
    width: widthPercentageToDP('50%'),
    height: heightPercentageToDP('7%'),
  },
  buttonText: {
    fontWeight: '600',
    fontSize: heightPercentageToDP('3%'),
    textAlign: 'center',
    color: '#ffff',
  },
  container: {
    flex: 1,
    backgroundColor: '#F8F6F6',
    alignItems: 'center',
  },
  formContainer: {
    width: '250px',
    height: '40%',
    backgroundColor: '#FEFEFE',
    marginBottom: 20,
    marginTop: 15,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});