import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Modal, FlatList, ScrollView, ActivityIndicator, Platform } from 'react-native';
import Head from '../src/components/Head';
import Information from '../src/components/i';
import Config from '../src/Api_Url.js';
import { Video } from 'expo-av';
import SwiperFlatList from 'react-native-swiper-flatlist';

//Font : 
import CustomText from '../src/components/Font/CustomText';
import BoldText from '../src/components/Font/BoldText';

//Supprimer postes : 
import { Supprimer } from '../src/PostManagement';

// Like
import { like, dislike, getAllLikes } from '../src/LikeManagement';

//Favoris :
import { favorite, disfavored } from '../src/FavoriteManagement';

//Commentaire
import ModalComment from '../src/components/Comments';

//bouton composant :
import Parameters from '../src/components/Parameters/ParametersProfil';
import Like from '../src/components/Like'
import Message from '../src/components/Message';
import Star from '../src/components/Star.js';
//Manage :
import { create, remove } from '../src/CommentManagement';
import { getUser } from '../src/UserManagement';
import { getMediaPostsByUserToken, getListComments } from '../src/PostManagement';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

export default function PostScreen({ route, navigation }) {

  // utilisateur :
  const [user, setUser] = useState([]);
  const [profile, setProfilUser] = useState(null);
  const profileImage = 'ex-profil.png';
  const [currentUserId, setCurrentUserId] = useState([]);

  //Description d'un poste : 
  const [showFullDescription, setShowFullDescription] = useState(false);

  //commentaire : 
  const [comment, setComment] = useState([]);
  const [commentaire, SetAddCommentaire] = useState([]);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [isCommentListEmpty, setIsCommentListEmpty] = useState(false);
  const [commentsAndReplies, setCommentsAndReplies] = useState([]);
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  //Info description : 
  const [isModalInfo, setIsModalInfo] = useState(false);
  const valueOnChange = (newValue) => {
    //console.log("Nouvelle valeur du curseur :", newValue);
  };

  //slide
  const [statusIcon, setStatusIcon] = useState([]);
  const [slideImageIndices, setSlideImageIndices] = useState([]);
  const [filteredMediaData, setFilteredMediaData] = useState([]);
  const [cursor, setCursor] = useState(0);

  //Menu dépliant paramètre : 
  const [isMenuParameters, setMenuParameters] = useState(false);

  const [loading, setLoading] = useState(false);

  // Total likes : 
  const [lik, SetAllLikes] = useState([]);

  // navigation :
  const postId = route.params?.postId;

  // data :
  const fetchData = (postId) => {
    setLoading(true);
    getMediaPostsByUserToken()
      .then((data) => {
        const formattedPosts = data.map((post) => {
          const createdAt = post.created_date;
          const dateObj = new Date(createdAt);
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          post.created_date = dateObj.toLocaleDateString('fr-FR', options);
          return post;
        });
        const filteredData = formattedPosts.filter(item => item.post_id === postId);
        setFilteredMediaData(filteredData);
        setStatusIcon(Array(filteredData.length).fill(false));
        setSlideImageIndices(Array(filteredData.length).fill(0));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log("Error fetching media:", error);
      });

    getUser()
      .then((data) => {
        setUser(data.user);
        setCurrentUserId(data.user.id);
        setProfilUser(data.user.profile_image);
      })
      .catch(error => {
        console.log(error);
      });
    getAllLikes()
      .then((data) => {
        // console.log('likeposte =', data.Likesbyposts);
        SetAllLikes(data.Likesbyposts);
      })
      .catch(error => {
        console.log(error);
      });
    getListComments(postId)
      .then((data) => {
        const formattedComments = data.comments.map((comment) => {
          const createdAt = comment.created_date;
          const dateObj = new Date(createdAt);
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          comment.created_date = dateObj.toLocaleDateString('fr-FR', options);
          return comment;
        });

        setComment(formattedComments);
        setIsCommentListEmpty(formattedComments.length === 0);
      })
      .catch((error) => {
        console.log("Erreur lors de la récupération des commentaires:", error);
      });
  };

  useEffect(() => {
    fetchData(postId);
  }, []);

  useEffect(() => {
    const combinedCommentsAndReplies = combineCommentsAndReplies(comment);
    setCommentsAndReplies(combinedCommentsAndReplies);
  }, [comment]);

  // Variable :
  const handleParameters = () => {
    setMenuParameters(!isMenuParameters);
  };

  const postDelete = () => {
    const postData = {
      post_id: postId,
    };

    Supprimer(postData)
      .then((data) => {
        if (data.success) {
          navigation.navigate('Profile');
          fetchData(postData);
          window.location.reload()
        } else {
          navigation.navigate('Profile');
          fetchData(postData);
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Dolike = (postId) => {
    const userId = currentUserId;
    const postData = {
      user_id: userId,
      post_id: postId,
    };
    like(postData)
      .then((data) => {
        if (data.success) {
          // console.log("Like créé avec succès");
        } else {
          // console.error("Échec de la création du like");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Nolike = (postId) => {
    const userId = currentUserId;

    const postData = {
      user_id: userId,
      post_id: postId,
    };

    // Appelez la fonction 'unlike' pour supprimer le like du post
    dislike(postData)
      .then((data) => {
        if (data.success) {
          //console.log("Unlike effectué avec succès");
        } else {
          //console.error("Échec de l'unlike");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Crush postes : 
  const Docrush = (postId) => {

    const userId = currentUserId;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    favorite(postData)
      .then((data) => {
        if (data.success) {
          // console.log("Crush créé avec succès");
        } else {
          // console.error("Échec de la création du crush");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Nocrush = (postId) => {
    const userId = currentUserId;
    const postData = {
      user_id: userId,
      post_id: postId,
    };

    disfavored(postData)
      .then((data) => {
        if (data.success) {
          // console.log("Unlike effectué avec succès");
        } else {
          // console.error("Échec de uncrush");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // Fonction pour combiner les commentaires et les réponses :
  function combineCommentsAndReplies(comments) {
    const commentsAndReplies = {};
    comments.forEach((commentItem) => {
      const parentId = commentItem.answer_id || commentItem.id;
      const existingComment = commentsAndReplies[parentId];
      if (!existingComment) {
        commentsAndReplies[parentId] = {
          ...commentItem,
          replies: [],
        };
      } else {

        if (!existingComment.replies) {
          existingComment.replies = [];
        }
        existingComment.replies.push(commentItem);
      }
    });
    const result = Object.values(commentsAndReplies);
    // console.log('Comments and Replies :', result);
    return result;
  }

  // Supprimez le commentaire: 
  function handleDeleteComment(commentId) {
    try {
      remove(commentId);
      const updatedComments = comment.filter(commentItem => commentItem.id !== commentId);
      setComment(updatedComments);
    } catch (error) {
      console.error("Erreur lors de la suppression du commentaire :", error);
    }
  }

  // Ajouter un  commentaire :
  function addComment() {
    const newComment = {
      body: commentaire,
      user_id: user.id,
      id_post: postId,
    };

    if (replyToCommentId !== null) {
      newComment.answer_id = replyToCommentId;
    }

    create(newComment, postId, replyToCommentId)
      .then((data) => {
        if (data.error) {
          console.error("Erreur lors de la création du commentaire :", data.message);
        } else {
          //console.log("Commentaire ajouté avec succès !");
          SetAddCommentaire('');
          setReplyToCommentId(null);
          fetchData(postId);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête d'ajout de commentaire :", error);
      });
  }

  //slide
  const selectPrevious = (index) => {
    if (cursor > 0 && slideImageIndices[cursor] > 0) {
      const newIndices = [...slideImageIndices];
      newIndices[cursor] -= 1;
      setSlideImageIndices(newIndices);
    } else if (cursor > 0) {
      setCursor(cursor - 1);
      const newIndices = [...slideImageIndices];
      newIndices[cursor - 1] = 0;
      setSlideImageIndices(newIndices);
    }
  };
  const selectNext = (index) => {
    if (cursor < filteredMediaData.length - 1) {
      setCursor(cursor + 1);
      const newIndices = [...slideImageIndices];
      newIndices[cursor + 1] = 0;
      setSlideImageIndices(newIndices);
    }
  };
  function handleReplyToComment(commentId) {
    setReplyToCommentId(commentId);

    // find commentaire = à son ID
    const commentToReply = commentsAndReplies.find(comment => comment.id === commentId);
    // Màj le champ de texte pour le commentaire principal
    if (commentToReply) {
      SetAddCommentaire(`@${commentToReply.pseudo}`);
    }
  }
  //ouverture info slide
  function handleModalInfo() {
    setIsModalInfo(() => !isModalInfo)
  }

  function getLikeCountByPostId(postId) {
    const likeData = lik.find((like) => like.post_id === postId);
    return likeData ? likeData.like_count : 0;
  }

  const handleShareProfileLink = async ({ userId }) => {
    /* try {
      await Share.share({
        message: window.location.href + '/' + userId
      });
    } catch (error) {
      console.error('Error sharing:', error);
    } */
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Lien du profil',
          text: 'Bien vouloir consulter ce profil :',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert(
        "Partage non pris en charge",
        "L'API Web Share n'est pas prise en charge dans votre navigateur. Veuillez copier et partager le lien manuellement.",
        [{ text: 'Copy Link', onPress: () => copyToClipboard(window.location.href) }]
      );
    }
  };

  const copyToClipboard = (text) => {
    if (Platform.OS === 'web') {
      navigator.clipboard.writeText(text).then(() => {
        Alert.alert('Link copied to clipboard');
      });
    } else {
      // Pour les environnements natifs, utiliser un module de copie comme expo-clipboard
    }
  };

  //console.log(filteredMediaData)

  return (
    <View style={styles.container}>
      <Head titre="Mes Suit'Posts" navigation={navigation} />

      {loading && <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#9002C1" />
        <Text style={{ fontSize: heightPercentageToDP('3%'), marginTop: heightPercentageToDP('2%') }}>Chargement en cours...</Text>
      </View>
      }

      <FlatList
        data={filteredMediaData}
        renderItem={({ item, index }) => (
          <View key={index}>
            {index === cursor && (
              <View style={{ borderRadius: 25, borderWidth: 1, borderColor: '#C881E0', padding: 5, width: widthPercentageToDP('90%'), marginTop: 43, marginBottom: 39 }}>
                <View style={styles.postconteneur}>
                  <View style={styles.containerProfile}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: '0.33pc', marginLeft: 5 }}>
                      <View style={{ backgroundColor: '#9002C1', padding: 2, borderRadius: 30, width: 60, height: 60, }}>
                        <View style={{ borderRadius: 30, overflow: 'hidden' }}>
                          {profile === profileImage ? (
                            <Image style={{ width: 60, height: 56, }} source={require('../assets/' + profileImage)} />
                          ) : (
                            <Image style={{ width: 60, height: 56 }} source={{ uri: profile }} />)
                          }
                        </View>
                      </View>
                      <View style={styles.Letters}>
                        <CustomText style={{ fontWeight: 'bold', color: 'purple' }}>{user.morphology_calculated}</CustomText>
                      </View>
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ marginLeft: '10px' }}>
                          <CustomText style={{ fontWeight: '600', width: 122 }}>{user.pseudo}</CustomText>
                        </View>
                        <View style={{ marginLeft: 50 }}>
                          <Parameters onMenuToggle={handleParameters} />
                        </View>
                        <View style={{ marginLeft: -112 }}>
                          {isMenuParameters && (
                            <View style={styles.menu}>
                              <TouchableOpacity style={{ marginBottom: 1, }}
                                onPress={() => postDelete(postId)}>
                                <BoldText>Supprimer</BoldText>
                              </TouchableOpacity>
                              <TouchableOpacity onPress={() => handleShareProfileLink({ userId: user.id })} style={{ marginBottom: 1, }} >
                                <BoldText>Partager</BoldText>
                              </TouchableOpacity>
                            </View>)}
                        </View>
                      </View>
                    </View>
                    {/*  {filteredMediaData.map((data, index) => (
                      index === 1 && (
                        <View key={index}>
                          <CustomText style={{ fontWeight: '600', fontSize: 11, marginLeft: 17 }}>{data.media_created_dates}</CustomText>
                        </View>)))
                    } */}
                  </View>
                  <View style={styles.containerMorphologies}>
                    <View style={{ width: 333, height: '100%', alignItems: 'center' }}>
                      <View style={{ marginTop: 25, marginLeft: -192 }}>
                        <CustomText style={{ fontWeight: '200', opacity: '0.8', fontSize: 11, marginTop: 3, marginBottom: 3, marginLeft: '0pc' }}>{item.created_date}</CustomText>
                      </View>
                      {/* {filteredMediaData.map((item, index) => (
                        (filteredMediaData.length === 1 || cursor === index) && (
                          <View key={index} style={{ width: widthPercentageToDP('100%') }}>
                            {
                              item?.media_format?.includes("image") ?
                                <Image style={styles.postImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                                : item?.media_format?.includes("video") ?
                                  <Video
                                    source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                                    style={styles.postImage}
                                    useNativeControls
                                    resizeMode="contain"
                                  />
                                  : <Image style={styles.postImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                            }
                          </View>
                        )
                      ))} */}

                      <View>
                        <ScrollView
                          horizontal
                          pagingEnabled
                          showsHorizontalScrollIndicator={true}
                          style={{ width: widthPercentageToDP('100%') }}
                        >
                          {filteredMediaData.map((item, index) => (
                            <View key={index} style={{ border: "1px solid black", width: widthPercentageToDP('100%') }}>
                              {
                                item?.media_format?.includes("image") ?
                                  <Image style={styles.postImage} source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`} />
                                  : item?.media_format?.includes("video") ?
                                    <Video
                                    shouldPlay
                                      source={item.body ? `${Config.serverUrl}${item.body}` : `${Config.serverUrl}/uploads/noImage.jpg`}
                                      style={styles.postImage}
                                      useNativeControls
                                      resizeMode="cover"
                                    /* onReadyForDisplay={videoData => {
                                      videoData.srcElement.style.position = "initial"
                                    }} */
                                    />
                                    : <Image style={styles.postImage} source={`${Config.serverUrl}/uploads/noImage.jpg`} />
                              }
                            </View>
                          ))}
                        </ScrollView>
                      </View>
                      <View style={styles.dotsContainer}>
                        {filteredMediaData.map((_, index) => (
                          <TouchableOpacity
                            key={index}
                            onPress={() => setCursor(index)}
                            style={[
                              styles.dot,
                              index === cursor ? styles.activeDot : styles.inactiveDot,
                            ]}
                          />
                        ))}
                      </View>
                      <TouchableOpacity activeOpacity={0.5} onPress={handleModalInfo}>
                        <Image style={styles.iconLogo} source={require('../assets/i-letter.svg')} />
                      </TouchableOpacity>
                      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 2, position: 'absolute', top: '50%', }}>
                        <TouchableOpacity activeOpacity={0.5} onPress={() => selectPrevious(index)} style={{ marginLeft: 17 }}>
                          <Image style={{ width: 30, height: 25 }} />
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.5} onPress={() => selectNext(index)} style={{ marginRight: 0, marginLeft: 220 }}>
                          <Image style={{ width: 30, height: 25 }} />
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginTop: '0.3pc' }}>
                        <View style={{ flexDirection: 'row', marginLeft: 4 }}>
                          <BoldText style={{ marginRight: 4, fontSize: 12 }}>{getLikeCountByPostId(item.id)}</BoldText>
                          <BoldText style={{ fontSize: 12 }}>Suit'Likes</BoldText>
                        </View>
                        <CustomText style={{ width: 290 }}>
                          <CustomText style={{ fontWeight: 'bold' }}> {user.pseudo}</CustomText>  {showFullDescription ? item.description : (item.description.length > 15 ? item.description.substring(0, 22) : item.description)}
                        </CustomText>
                        {!showFullDescription && item.description.length > 22 && (
                          <TouchableOpacity onPress={() => setShowFullDescription(true)}>
                            <CustomText style={{ color: '#C881E0' }}> ...Plus</CustomText>
                          </TouchableOpacity>)
                        }
                      </View>
                      <View>
                        <View style={styles.descriptionContainer}>
                          <View style={styles.descriptionBloc}>
                            <CustomText style={{ color: '#9002C1' }}>{item.type}</CustomText>
                          </View>
                          <View style={styles.descriptionBloc}>
                            <CustomText style={{ color: '#9002C1' }}>{item.brand}</CustomText>
                          </View>
                          <View style={styles.descriptionBloc}>
                            <CustomText style={{ color: '#9002C1' }}>{item.size}</CustomText>
                          </View>
                          <View style={styles.descriptionBloc}>
                            <CustomText style={{ color: '#9002C1' }}>{item.adjustment}</CustomText>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View>
                    <Modal visible={isModalInfo} animationType="fade" transparent={true} onRequestClose={() => setIsModalInfo(false)}>
                      <View style={styles.modalBackground}>
                        <View style={styles.modalContainer}>
                          <TouchableOpacity activeOpacity={0.5} onPress={handleModalInfo}>
                            <Information
                              initiaTexture={item.texture}
                              initialThickness={item.thickness}
                              initialFluidity={item.fluidity}
                              initialComfort={item.comfort}
                              initialColor={item.color}
                              valueOnChange={valueOnChange}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </Modal>
                  </View>
                </View>
                <View style={styles.iconsContainer}>
                  <View style={styles.iconsback}>
                    <View>
                      <Like likePost={Dolike} nolikePost={Nolike} postId={item.id} userId={currentUserId} />
                    </View>
                    <View>
                      <TouchableOpacity onPress={() => setShowCommentPopup(true)}>
                        <Message />
                      </TouchableOpacity>
                    </View>
                    <View style={{ marginBottom: 2 }}>
                      <Star CrushPost={Docrush} NoCrushPost={Nocrush} postId={item.id} userId={currentUserId} />
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
        pagingEnabled
      />

      <ModalComment
        showCommentPopup={showCommentPopup}
        setShowCommentPopup={setShowCommentPopup}
        isCommentListEmpty={isCommentListEmpty}
        commentsAndReplies={commentsAndReplies}
        handleDeleteComment={handleDeleteComment}
        addComment={addComment}
        profile={profile}
        profileImage={profileImage}
        commentaire={commentaire}
        SetAddCommentaire={SetAddCommentaire}
        handleReplyToComment={handleReplyToComment}
        currentUserId={currentUserId}
        postId={postId}
      />

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: '#FEFEFE',
    alignItems: 'center',
  },
  containerProfile: {
    flex: 2,
    flexDirection: 'column',
    maxHeight: 40,
    padding: 2,
    width: widthPercentageToDP('90%'),
  },
  Letters: {
    width: 18,
    height: 18,
    marginLeft: '-1pc',
    marginTop: '2pc',
    borderRadius: 15,
    backgroundColor: '#E2CBEA',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconProfile: {
    width: 50,
    height: 50,
  },
  postconteneur: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  containerMorphologies: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 6,
  },
  iconLogo: {
    width: 17,
    height: 17,
    marginTop: -51,
    marginLeft: -139,
  },
  postImage: {
    height: 360,
    width: '100%',
    resizeMode: 'cover',
    borderRadius: 2,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    justifyContent: 'center',
    alignItems: 'center',
    width: 305,
    borderRadius: 10,
  },
  dotsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
  },
  activeDot: {
    backgroundColor: '#9002C1',
  },
  inactiveDot: {
    backgroundColor: '#E4C0F0',
  },
  descriptionContainer: {
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    //justifyContent: 'center',
    width: widthPercentageToDP('90%'),
    marginBottom: 11,
    maxHeight: 40,
    flexDirection: 'row',
    marginTop: '10px',
  },
  descriptionBloc: {
    height: 19,
    flexWrap: 'wrap',
    backgroundColor: '#ECF4F2',
    minWidth: 50,
    margin: 2,
    borderRadius: 10,
    marginLeft: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#D9E9E6',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  menu: {
    alignItems: 'center',
    position: 'absolute',
    top: -17,
    left: 0,
    backgroundColor: '#E4C0F0',
    padding: 10,
    borderRadius: 5,
    zIndex: 100,
  },
  //Box intéraction :
  iconsContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    borderRadius: 5,
    marginLeft: 150,
    marginBottom: -7,
    marginTop: -68,
  },
  iconsback: {
    backgroundColor: '#debee5',
    borderWidth: 2,
    borderColor: '#debee5',
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 22,
    padding: 5,
    marginRight: -5,
    width: 30,
    justifyContent: 'space-between',
    height: '117%',
    borderRadius: 5,
    flexDirection: 'column',
  },
});
