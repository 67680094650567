import Config from "./Api_Url";

export async function create(post, files) {
    const token = localStorage.getItem("sessionToken");
    console.log(files[0])
    const response = await fetch(`${Config.serverUrl}/api/post/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ post: post, token: token })
    });
    if (!response.ok) {
        console.log(response);
        alert("La création du post a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Creation Request Failed");
    }
    const data = await response.json();
    const id = data.id; 

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append('images', files[i][0]);
    }
    formData.append('idPost', id);
    formData.append('token', token);

    try {
        const response = await fetch(`${Config.serverUrl}/api/media/create`, {
            method: "POST",
            body: formData
        });

        if (!response.ok) {
            console.log(response);
            alert("La création du media a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
            throw new Error("Creation Media Request Failed");
        }
        const data = await response.json();
        console.log(data);
        alert('Files uploaded successfully');
    } catch (err) {
        console.error(err);
        alert('Failed to upload files');
    }
    return data
}

export async function getHomePosts(timeout = 5000, currentPage, limit) { // Default timeout of 30 seconds
    let token = localStorage.getItem("sessionToken");

    const response = await fetch(`${Config.serverUrl}/api/post/home/list/${token}?page=${currentPage}&limit=${limit}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        alert("L'obtention de la liste des posts home a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("HomePosts request Failed");
    }

    const data = await response.json();
    return data;
}

export async function getHomePostsWithoutMe(timeout = 5000, currentPage, limit) { // Default timeout of 30 seconds
    let token = localStorage.getItem("sessionToken");

    const response = await fetch(`${Config.serverUrl}/api/post/explorer/listWithoutMe/${token}?page=${currentPage}&limit=${limit}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        alert("L'obtention de la liste des posts home a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("HomePosts request Failed");
    }

    const data = await response.json();
    return data;
}


export async function getExplorerPosts(timeout = 5000, currentPage, limit, search) {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/post/explorer/list/${token}?search=${search}&page=${currentPage}&limit=${limit}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("L'obtention de la liste des posts explorer a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("ExplorerPosts request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getExplorerPostsByKey(timeout = 5000, currentPage, limit, search) {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/post/explorerByKey/list/${token}?search=${search}&page=${currentPage}&limit=${limit}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("L'obtention de la liste des posts explorer a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("ExplorerPosts request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getTrendsPosts() {
    const response = await fetch(`${Config.serverUrl}/api/post/trends/list`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("L'obtention de la liste des posts trends a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("TrendsPosts request Failed");
    }
    const data = await response.json();
    return data;
}
export async function getRecommendationsPosts() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/post/recommendations/list/` + token, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("L'obtention de la liste des posts recommendations a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("RecommendationsPosts request Failed");
    }
    const data = await response.json();
    return data;

}

export async function getUserPosts() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/post/user/list/` + token, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    console.log(response);
    if (!response.ok) {
        alert("L'obtention de la liste des posts user a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("UserPosts request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getFavoritePosts() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/post/user/favorite/list/` + token, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("L'obtention de la liste des posts favoris user a échoué: " + JSON.stringify(response.status) + '\n' + response.statusText);
        throw new Error("GetFavoritePosts request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getMediaPostsByUserToken() {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(`${Config.serverUrl}/api/media/list/` + token, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("La récupération des infos de l'utilisateur a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("GetUser Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getMediaPostsByUserId(iduser) {
    const response = await fetch(`${Config.serverUrl}/api/media/list/pp/${iduser}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("La récupération des infos de l'utilisateur a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("GetUser Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function getListComments(postId) {
    const response = await fetch(`${Config.serverUrl}/api/post/comment/list/${postId}`, {
        method: "GET",
        headers: {
            'Access-Control-Allow-Origin': Config.serverUrl,
            'Content-Type': 'application/json'
        },
    });
    if (!response.ok) {
        alert("La récupération des commentaires a échoué: " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function Supprimer(postId) {
    const response = await fetch(`${Config.serverUrl}/api/media/delete/${postId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ postId: postId })
    }
    );
    if (!response.ok) {
        console.log(response);
        alert("La suppression du commentaire a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Comment deletion Request Failed");
    }

    const data = await response.json();
    return data;
}