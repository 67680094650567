import React, { useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView } from 'react-native'
import CustomText from './Font/CustomText';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

const DropDown = ({
    data = [],
    value = 0,
    onSelect = () => { },
    question = "",
    questionStyle, 
    style
}) => {
    const [showOption, setShowOption] = useState(false);

    const onSelectedItem = (val) => {
        setShowOption(false)
        onSelect(val.value)
    }

    return (
        <View style={[style, {marginBottom: heightPercentageToDP('3%')}]}>
            <CustomText style={[styles.question, questionStyle]}>{question}</CustomText>
            <View>
                <TouchableOpacity
                    style={styles.dropDownStyle}
                    activeOpacity={0.8}
                    onPress={() => setShowOption(!showOption)}
                >
                    <CustomText>{!!value ? value : value}</CustomText>
                    <Image style={{
                        transform: [{ rotate: showOption ? '180deg' : '0deg' }]
                    }} source={require('../../assets/icon-private-message.png')} />
                </TouchableOpacity>
            </View>
            {showOption && (<View style={{
                border: "solid 1px",
                color: "#9002C1",
                borderRadius: 6,
                zIndex: 20,
                maxHeight: 200,
            }}>
                <ScrollView
                    keyboardShouldPersistTaps="handled"
                >
                    {data.map((val, i) => {
                        return (
                            <TouchableOpacity
                                key={String(i)}
                                onPress={() => onSelectedItem(val)}
                                style={{
                                    backgroundColor: value === val.value ? '#E4C0F0' : 'transparent',
                                    color: value === val.value ? 'white' : 'red',
                                    paddingVertical: 8,
                                    borderRadius: 4,
                                    paddingHorizontal: 6,
                                    zIndex: 20,
                                }}
                            >
                                <CustomText key={String(i)} style={{ fontSize: heightPercentageToDP('2.5%') }}>{val.value}</CustomText>
                            </TouchableOpacity>
                        )
                    })}
                </ScrollView>
            </View>)}
        </View>
    );
}

const styles = StyleSheet.create({
    question: {
        fontSize: heightPercentageToDP('3%'), // Default font size, can be overridden by questionStyle
        fontWeight: '500',
        marginBottom: 3,
    },
    dropDownStyle: {
        border: "solid 1px",
        color: '#9002C1',
        padding: 8,
        paddingHorizontal: 8,
        borderRadius: 6,
        minHeight: heightPercentageToDP('7%'),
        justifyContent: 'center'
    }
});

export default DropDown;