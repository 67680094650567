import Config from "./Api_Url";

export async function create(comment, post) {
    let token = localStorage.getItem("sessionToken");
    const response = await fetch(Config.serverUrl+'/api/comment/create', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({comment: comment, token: token, post: post})
    });
    if (!response.ok) {
        console.log(response);
        alert("La création du commentaire de l'utilisateur a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Comment creation Request Failed");
    }
    const data = await response.json();
    console.log(data);
    return data;
}

export async function answer(comment, user, target) {
    const response = await fetch(`${Config.serverUrl}/api/comment/answer`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({comment: comment, user: 'XXX', target: 'XXX'})
    });
    if (!response.ok) {
      console.log(response);
      alert("La réponse du commentaire a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
      throw new Error("Comment answering Request Failed");
    }
    const data = await response.json();
    return data;
}

export async function remove(commentId) {
    const response = await fetch(`${Config.serverUrl}/api/comment/delete/${commentId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        console.log(response);
        alert("La suppression du commentaire a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
        throw new Error("Comment deletion Request Failed");
    }

    const data = await response.json();
    return data;
}

// export async function modify(comment, user, post) {
//     const response = await fetch("http://localhost:8080/api/comment/modify", {
//         method: "POST",
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({comment: comment, user: 'XXX', post: 'XXX'})
//     });
//     if (!response.ok) {
//       console.log(response);
//       alert("La modification du commentaire a échoué: erreur " + JSON.stringify(response.status) + "\n" + response.statusText);
//       throw new Error("Comment modifying Request Failed");
//     }
//     const data = await response.json();
//     console.log(data);
//     return data;
// }